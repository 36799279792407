const modulePages = [
	{
		id: 'm1-count-to-20',
		isFacilitatorOnly: true,
		title: 'Tæl 20',
		menuTitle: 'Tæl 20',
		facilitatorInstructions: `Følg guiden, som beskriver, hvordan du laver øvelsen "Tæl til 20" med dine elever.`,
		facilitatorText: null,
		groupText: null,
		icons: [],
		slides: [
			{
				id: 'the-bad-circle',
				type: 'image', 
				text: 'Den svære cirkel'
			},
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskassen'
			}
		],
		minigames: []
	},
	{
		id: 'm1-intro',
		isFacilitatorOnly: true,
		title: 'Intro til forløbet',
		menuTitle: 'Intro til forløbet',
		facilitatorInstructions: `Følg guiden. Giv dine elever en kort introduktion til Basal.`,
		facilitatorText: null,
		groupText: null,
		icons: [],
		slides: [
			{
				id: 'the-bad-circle',
				type: 'image', 
				text: 'Den svære cirkel'
			},
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskassen'
			}
		],
		minigames: []
	},
	{
		id: 'm1-intro-to-module',
		isFacilitatorOnly: true,
		title: 'Intro til modulet',
		menuTitle: 'Intro til modulet',
		facilitatorInstructions: `Følg guiden. Giv eleverne en kort introduktion til modulet.`,
		facilitatorText: null,
		groupText: null,
		icons: [],
		slides: [
			{
				id: 'the-bad-circle',
				type: 'image', 
				text: 'Den svære cirkel'
			},
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskassen'
			}
		],
		minigames: []
	},
	{
		id: 'm1-bad-circle',
		isFacilitatorOnly: true,
		title: 'Den svære cirkel og værktøjskassen',
		menuTitle: 'Den svære cirkel og værktøjskassen',
		facilitatorInstructions: `Følg guiden.`,
		facilitatorText: null,
		groupText: null,
		icons: [],
		slides: [
			{
				id: 'the-bad-circle',
				type: 'image', 
				text: 'Den svære cirkel'
			},
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskassen'
			}
		],
		minigames: []
	},
	{
		id: 'm1-active-break',
		isFacilitatorOnly: true,
		title: 'Aktiv pause',
		menuTitle: 'Aktiv pause',
		facilitatorInstructions: `Her kan du lægge en aktiv pause ind. Vælg én fra appendiks bagerst i Guide til facilitering eller find din egen. `,
		facilitatorText: null,
		groupText: null,
		icons: [],
		slides: [
			{
				id: 'the-bad-circle',
				type: 'image', 
				text: 'Den svære cirkel'
			},
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskassen'
			}
		],
		minigames: []
	},
	{
		id: 'm1-the-two-brains',
		isFacilitatorOnly: false,
		title: 'Alarmhjernen og tænkehjernen',
		facilitatorInstructions: `Følg guiden.`,
		facilitatorText: ``,
		groupText: ``,
		icons: ['alarm-brain', 'thinking-brain'],
		minigames: [],
		// minigames: [
		// 	require('data/minigames/m2-the-two-brains').minigameData
		// ],
		slides: [
			{
			id: 'the-bad-circle',
			type: 'image', 
			text: 'Den svære cirkel'
		},
		{
			id: 'toolbox',
			type: 'image',
			text: 'Værktøjskasse'
		},
		{
			id: 'stroop-1',
			type: 'image', 
			text: 'Stroop 1'
		},
		{
			id: 'stroop-2',
			type: 'image',
			text: 'Stroop 2'
		},
	]	
	},

	{
		id: 'm1-calm-with-breathing',
		isFacilitatorOnly: true,
		title: 'Ro på med vejrtrækning',
		menuTitle: 'Ro på med vejrtrækning',
		facilitatorInstructions: `Følg guiden. Gennemfør vejrtrækningsøvelsen "Firkanten" sammen med eleverne.
			<br /><br />Efter vejrtrækningsøvelsen gennemfører du en idérunde i klassen som beskrevet i guiden.`,
		facilitatorText: null,
		facilitatorDownloads: [{
			title: 'Firkanten',
			file: 'Firkanten.pdf'
		}],		
		groupText: null,
		icons: ['calm'],
		illustrations: ['firkanten'],
		slides: [
			{
				id: 'the-square',
				type: 'audio', 
				text: 'Firkanten',
				image: 'firkanten',
				audioFile: 'firkanten.mp3'
			},
			{
				id: 'idea-round',
				type: 'image',
				text: 'Idérunde'
			},
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskassen'
			},
			{
				id: 'the-bad-circle',
				type: 'image',
				text: 'Den svære cirkel'
			},
		]
	},

	{
		id: 'm1-finish',
		isFacilitatorOnly: true,
		title: 'Afrunding',
		menuTitle: 'Afrunding',
		facilitatorInstructions: `Følg guiden.`,
		facilitatorText: `<strong>Hvad har vi arbejdet med i dag?</strong> <br/><br/>
		<strong>Hvad gjorde størst indtryk?</strong><br/><br/>
		<strong>Er der noget, I kan tage med jer - måske et værktøj?</strong>`,
		groupText: null,
		icons: ['toolbox'],
		slides: [
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskassen'
			},
			{
				id: 'the-bad-circle',
				type: 'image',
				text: 'Den svære cirkel'
			},
		],
		minigames: []
	},
];


export {
	modulePages
};