import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {gameUiTexts} from 'data/ui-texts';
import {scrollTo} from 'helpers/scroll-helper';
import Popup from 'components/ui/popup/popup';
import Button from 'components/ui/button/button';
import './dialogue.scss';

const Dialogue = ({partIndex, gameStatus, minigameData, setGameStatus}) => {
	/* Timeout */
	let timeout = null;	
	let timeout2 = null;

	/* Track step id, dialogue array, show / hide options */
	const [showIntroPopup, setShowIntroPopup] = useState(true);
	const [showInfoPopup, setShowInfoPopup] = useState(false);
	const [dialogueStepId, setDialogueStepId] = useState(null);
	const [dialogueArr, setDialogueArr] = useState([]);
	const [showOptions, setShowOptions] = useState(true);
	


	/**
	 * Start game
	 * @param {bool} isFirstGame 
	 */
	const handleStartGamePart = (isFirstGame = true) => {
		if (minigameData && minigameData.parts[partIndex].steps && minigameData.parts[partIndex].steps.length > 0) {
			/* First step id */
			setDialogueStepId(minigameData.parts[partIndex].steps[0].id);

			/* Dialogue array */
			let initialDialogueArr = [];
			if (
				minigameData.parts[partIndex].steps[0].events && 
				minigameData.parts[partIndex].steps[0].events.length > 0
			) {
				minigameData.parts[partIndex].steps[0].events.forEach((event) => {
					initialDialogueArr.push(event);
				});
			}
			setDialogueArr(initialDialogueArr);

			/* Update game status */
			setGameStatus('playing');
			
			/* Options */
			setShowOptions(true);

			/* If first game, show intro */
			if (isFirstGame) setShowIntroPopup(true);
		} else {
			console.error('No dialogue data');
		}
	};


	/**
	 * Select an option (answer or action)
	 * @param {number} optionId 
	 */
	 const selectOption = (optionId) => {
		const dialogueStepData = minigameData.parts[partIndex].steps.find((s) => {return s.id === dialogueStepId;});
		const dialogueOptionData = (dialogueStepData && dialogueStepData.options
			? dialogueStepData.options.find((option) => {return option.id === optionId;})
			: null
		);
		if (dialogueOptionData) {			
			/* Update dialogue */
			let newDialogueArr = JSON.parse(JSON.stringify(dialogueArr));
			if (dialogueOptionData.dialogueEffects && dialogueOptionData.dialogueEffects.length > 0) {
				/* Use effects (dialogue and / or action) */
				dialogueOptionData.effects.forEach((dialogueEffect) => {
					newDialogueArr.push({dialogueEffect});
				});
			} else {
				/* Use default option text (dialogue or action) */
				if (dialogueStepData.optionType && dialogueOptionData.text) {
					newDialogueArr.push({
						personId: 'person-2', 
						type: dialogueStepData.optionType, 
						text: dialogueOptionData.text
					});
				}
			}
			setDialogueArr(newDialogueArr);

			/* Go to next step or stay at current */
			if (dialogueOptionData.nextStepId) {
				// const nextDialogueStepData = minigameData.steps.find((step) => {
				// 	return step.id === dialogueOptionData.nextStepId;
				// });
				goToNextDialogueStep(dialogueOptionData.nextStepId, newDialogueArr);
				// timeout2 = setTimeout(() => {
				// 	goToNextDialogueStep(dialogueOptionData.nextStepId, newDialogueArr);
				// }, 500);
				
			} else {
				/* Stay at this step (selected option not approved) */
			}
		}
	};


	/**
	 * Go to next dialogue step
	 * @param {number} nextDialogueStepId 
	 * @param {array} newDialogueArr 
	 */
	 const goToNextDialogueStep = (nextDialogueStepId, newDialogueArr) => {
		const nextDialogueStepData = minigameData.parts[partIndex].steps.find((step) => {
			return step.id === nextDialogueStepId;
		});

		if (nextDialogueStepData) {
			/* Hide options */
			setShowOptions(false);

			/* Update dialogue */
			if (nextDialogueStepData && nextDialogueStepData.events && nextDialogueStepData.events.length > 0) {
				nextDialogueStepData.events.forEach((event) => {newDialogueArr.push(event);});
				setDialogueArr(newDialogueArr);
			}

			/* Update dialogue step id */
			setDialogueStepId(nextDialogueStepId);

			/* Check if task is completed */
			if (nextDialogueStepData && nextDialogueStepData.isFinalStep === true) {
				completeTask();
			}

			/* Show options */
			timeout = setTimeout(() => {setShowOptions(true);}, 100);

			/* Auto-nav to next dialogue step */
			if (
				nextDialogueStepData && 
					nextDialogueStepData.nextStepId // && 
					// nextDialogueStepData.autoContinue === true
			) {
				goToNextDialogueStep(nextDialogueStepData.nextStepId, newDialogueArr);
			}
		}
	};

	/**
	 * Smooth scroll to dialogue bottom
	 */
	 const scrollToBottom = () => {
		var div = document.getElementById('dialogueLines');
		if (div) {
			scrollTo(div, (div.scrollHeight - div.clientHeight), 0.1, null);
		}
	};

	/**
	 * Complete task
	 */
	 const completeTask = () => {
		setGameStatus('done');
	};

	/**
	 * Handle scroll
	 * @param {string} direction 
	 */
	const handleScroll = (direction) => {
		const itemsRef = document.getElementById('options');
		if (itemsRef) {
			const scrollDiff = itemsRef.scrollWidth - itemsRef.clientWidth;
			if (direction === 'left' && itemsRef.scrollLeft > 0) {
				itemsRef.scrollLeft = Math.max(0, itemsRef.scrollLeft - 300);
			}
			if (direction === 'right' && itemsRef.scrollLeft < scrollDiff) {
				itemsRef.scrollLeft = Math.min(scrollDiff, itemsRef.scrollLeft + 300);
			}
		}
	};




	useEffect(() => {
		scrollToBottom();
	}, [dialogueArr]);

	/**
	 * Component mounted / will unmount
	 */
	 useEffect(() => {
		/* Component mounted */
		handleStartGamePart(true);

		return () => {
			/* Component will unmount */
			if (timeout) clearTimeout(timeout);
			if (timeout2) clearTimeout(timeout2);
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		handleStartGamePart();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [partIndex]);

	
	/* Get current dialogue step data */
	const dialogueStepData = minigameData.parts[partIndex].steps.find((s) => {return s.id === dialogueStepId;});	
	// const isLastPage = (dialogueStepData && dialogueStepData.isFinalStep === true);
	const canScrollLeft = true; 
	const canScrollRight = true;
	return (
		<div className="Dialogue">
			{/* Dialogue (speech / action bubbles) */}
			<div className="Dialogue-dialogue">
				<div id="dialogueLines" className="Dialogue-lines">
					<div className="Dialogue-line placeholder" />
					{dialogueArr.map((line, index) => {
						const name = (
							line.personId && minigameData.parts[partIndex].people.hasOwnProperty(line.personId)
								? minigameData.parts[partIndex].people[line.personId] : '???'
						);
						return (
							<div key={index} className={'Dialogue-line ' + line.type + ' ' + line.personId}>
								{line.personId && <div className={'Dialogue-personName ' + line.personId}>
									<span>{name}</span>
								</div>}
								<div className="Dialogue-bubble">
									<span>{line.text}</span>
								</div>
											
							</div>
						);
					})}
				</div>
			</div>

			{/* Bottom panel */}
			{(
				showOptions && 
				dialogueStepData && 
				dialogueStepData.options && 
				dialogueStepData.options.length > 0
			) && 
				<div className="Dialogue-panel">
					{dialogueStepData.options.length > 3 && <>
						<div 
							className={'Dialogue-scrollLeftBtn' + (canScrollLeft ? ' active' : '')} 
							onClick={() => {handleScroll('left');}} 
						/>
						<div 
							className={'Dialogue-scrollRightBtn' + (canScrollRight ? ' active' : '')}
							onClick={() => {handleScroll('right');}} 
						/>
					</>}
					{/* Options */}

					<div className={'Dialogue-options'}>
						<div className="Dialogue-optionsText"><span>
							{dialogueStepData.optionPrompt 
								? dialogueStepData.optionPrompt 
								: minigameData.parts[partIndex].defaultOptionPrompt}
						</span></div>
						<div className="Dialogue-optionsCarouselWrap">
							<div id="options" className="Dialogue-optionsCarousel">
								{dialogueStepData.options.map((option) => {
									return (
										<div 
											key={option.id}
											className="Dialogue-option"
											onClick={() => {selectOption(option.id);}}
										><span>{option.text}</span></div>
									);
								})}

							</div>
						</div>
					</div>	
				</div>}
			
			{gameStatus === 'done' && <div className="Dialogue-playAgainBtn">
				<Button 
					text={gameUiTexts.playAgain} 
					classes={['red']} 
					onClick={() => {handleStartGamePart(false);}} />
			</div>}
		

			
			{/* Info popup button */}
			{minigameData.parts[partIndex].infoPopupText && <div className="Dialogue-infoBtn">
				<Button classes={['info']} onClick={() => {setShowInfoPopup(true);}} />
			</div>}


			{/* Info popup */}
			{showInfoPopup &&
				<Popup
					type="intro-small"
					text={minigameData.parts[partIndex].infoPopupText} 
					buttons={[
						{text: gameUiTexts.ok, action: setShowInfoPopup, parameters: [false]}
					]}
				/>
			}
					
			{/* Intro popup */}
			{showIntroPopup && 
				<Popup 
					type="intro"
					text={minigameData.parts[partIndex].introPopupText} 
					buttons={[
						{text: gameUiTexts.ok, action: setShowIntroPopup, parameters: [false]}
					]}
				/>
			}
		</div>

	);
	
};

Dialogue.propTypes = {
	partIndex: PropTypes.number.isRequired,
	gameStatus: PropTypes.string.isRequired,
	minigameData: PropTypes.object.isRequired,
	setGameStatus: PropTypes.func.isRequired
};

export default Dialogue;
