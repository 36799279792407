import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {modulesData} from 'data/modules-data';
import GameOverview from 'components/game-overview/game-overview';
import GamePage from 'components/game-page/game-page';
import GroupLobby from 'components/group-lobby/group-lobby';
import './game.scss';

const Game = (props) => {
	const {
		role, 
		gameData, 
		handleGoToGame, 
		toggleMinigameStatus, 
		updateMinigame, 
		updateGame,
		handleLogout
	} = props;

	/* Navigation, default is game overview page */
	const currentModuleId = gameData.currentModuleId ? gameData.currentModleId : modulesData[0].id;
	const currentModuleData = modulesData.find((m) => {return m.id === currentModuleId;});

	const [moduleId, setModuleId] = useState(() => {
		if (role === 'facilitator') return null;
		return currentModuleId;
	});
	const [pageId, setPageId] = useState(() => {
		if (role === 'facilitator') return null;
		const availablePages = (currentModuleData && currentModuleData.pages
			? currentModuleData.pages.filter((p) => {
				return (role === 'facilitator' || p.isFacilitatorOnly !== true);
			})
			: []
		);
		return (availablePages.length > 0 ? availablePages[0].id : null);
	});

	/**
	 * Navigate to page within module (or back to game overview)
	 * @param {number} newPageId 
	 */
	const handleGoToPage = (newPageId = null) => {
		if (!newPageId || !moduleId) {
			setModuleId(null);
			setPageId(null);
		} else {
			setPageId(newPageId);
		}
	};

	/**
	 * Navigate to first page of module (or back to game overview)
	 * @param {string} newModuleId 
	 */
	const handleGoToModule = (newModuleId = null) => {
		if (!newModuleId) {
			setModuleId(null);
			setPageId(null);
		} else {
			const moduleData = modulesData.find((m) => {return m.id === newModuleId;});
			if (moduleData && moduleData.pages && moduleData.pages.length > 0) {
				setModuleId(newModuleId);
				setPageId(moduleData.pages[0].id);
				if (role === 'facilitator') updateGame({currentModuleId: newModuleId});
			}
			
			
		}
	};

	useEffect(() => {
		if (role === 'group') {
			const newCurrentModuleId = gameData.currentModuleId;
			if (newCurrentModuleId !== moduleId) {
				const currentModuleData = modulesData.find((m) => {return m.id === newCurrentModuleId;});
				const availablePages = (currentModuleData && currentModuleData.pages
					? currentModuleData.pages.filter((p) => {
						return (role === 'facilitator' || p.isFacilitatorOnly !== true);
					})
					: []
				);
				const currentPageId = (availablePages.length > 0 ? availablePages[0].id : null);
				setModuleId(null);
				setPageId(currentPageId);
				setModuleId(newCurrentModuleId);
			}
		}
		
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [gameData]);

	/* Game is finished (group only) */
	if (role === 'group' && (!moduleId || !pageId)) {
		return (
			<GroupLobby handleLogout={handleLogout} />
		);
	}

	/* Game overview (facilitator only) */
	if (role === 'facilitator' && (!moduleId || !pageId)) {
		return (
			<GameOverview 
				gameData={gameData}
				handleGoToModule={handleGoToModule}
				handleGoToGame={handleGoToGame}
			/>
		);
	}

	/* Game module page */
	return (
		<GamePage 
			role={role}
			moduleId={moduleId}
			pageId={pageId}
			gameData={gameData}
			toggleMinigameStatus={toggleMinigameStatus}
			updateMinigame={updateMinigame}
			handleGoToPage={handleGoToPage}			
			handleLogout={handleLogout}
		/>

	);
};

Game.propTypes = {
	role: PropTypes.string.isRequired,
	gameData: PropTypes.object.isRequired,
	handleGoToGame: PropTypes.func,
	toggleMinigameStatus: PropTypes.func,
	updateMinigame: PropTypes.func,
	updateGame: PropTypes.func,
	handleLogout: PropTypes.func.isRequired
};

export default Game;
