const minigameData = {
	id: 'm2-the-two-brains',
	title: 'Alarmhjernen og tænkehjernen',
	playBtnText: 'Start øvelse',
	viewBtnText: 'Prøv øvelse',
	parts: [
		{
			id: 1,
			type: 'sort',
			title: 'Del 1',
			introPopupText: `Tal i gruppen om, hvilke udsagn der passer til henholdsvis alarmhjernen og tænkehjernen. Vælg et udsagn, og træk det hen til den hjerne, I mener det passer til.
				<br /><br />Når I færdige med at placere udsagnene, så tryk "OK".`,
			infoPopupText: `Tal i gruppen om, hvilke udsagn der passer til henholdsvis alarmhjernen og tænkehjernen. Vælg et udsagn, og træk det hen til den hjerne, I mener det passer til.
				<br /><br />Når I færdige med at placere udsagnene, så tryk "OK".`,
			categories: [
				{id: 'alarm',	title: 'Alarmhjerne'},
				{id: 'think', title: 'Tænkehjerne'}
			],
			items: [
				{id: 1, title: 'Aktiveres af utryghed og går i "kamp-, flugt-, eller spil død"- tilstand.', correctCategoryId: 'alarm'},
				{id: 2, title: 'Aktiveres af fx edderkopper, eksamen, sociale sammenhænge eller af at prøve noget nyt.', correctCategoryId: 'alarm'},
				{id: 3, title: 'Er placeret midt i hjernen.', correctCategoryId: 'alarm'},
				{id: 4, title: 'Hjælper os med at træffe automatiske valg på baggrund af vores intuition og følelser.', correctCategoryId: 'alarm'},
				{id: 5, title: 'Holder øje med alt, der er farligt og ubehageligt.', correctCategoryId: 'alarm'},
				{id: 6, title: 'Den del af hjernen, hvor vi planlægger, overvejer og træffer beslutninger.', correctCategoryId: 'think'},
				{id: 7, title: 'Bruges, når vi planlægger vores dag.', correctCategoryId: 'think'},
				{id: 8, title: 'Bruges, når vi skal fordybe os.', correctCategoryId: 'think'},
				{id: 9, title: 'Kan vægte valg på en systematisk måde.', correctCategoryId: 'think'},
				{id: 10, title: 'Sidder forrest i hjernen.', correctCategoryId: 'think'},
				{id: 11, title: 'Kaldes også det langsomme system.', correctCategoryId: 'think'},
			]
		},
		{
			id: 2,
			type: 'sort',
			title: 'Del 2',
			introPopupText: `Tal i gruppen om hvilke udsagn der passer til henholdsvis alarmhjernen og tænkehjernen. Vælg et udsagn og træk det hen til den hjerne, I mener det passer til.
				<br /><br />Når I færdige med at placere udsagnene, så tryk "OK".`,
			infoPopupText: `Tal i gruppen om hvilke udsagn der passer til henholdsvis alarmhjernen og tænkehjernen. Vælg et udsagn og træk det hen til den hjerne, I mener det passer til.
				<br /><br />Når I færdige med at placere udsagnene, så tryk "OK".`,
			categories: [
				{id: 'alarm',	title: 'Alarmhjerne'},
				{id: 'think', title: 'Tænkehjerne'}
			],
			items: [
				{id: 1, title: 'Bruges til at tænke rationelt og logisk.', correctCategoryId: 'think'},
				{id: 2, title: 'Bruges til at skabe overblik.', correctCategoryId: 'think'},
				{id: 3, title: 'Er den bevidste og velovervejede del af hjernen.', correctCategoryId: 'think'},
				{id: 4, title: 'Bruges til at afkode og forstå andre mennesker.', correctCategoryId: 'think'},
				{id: 5, title: 'Kaldes også præfontal/cortex/pandelappen/direktøren for det hele.', correctCategoryId: 'think'},
				{id: 6, title: 'Aktiveres af situationer, som vi oplever som truende.', correctCategoryId: 'alarm'},
				{id: 7, title: 'Er den ubevidste, hurtigt tænkende og intuitive del af hjernen.', correctCategoryId: 'alarm'},
				{id: 8, title: 'Kaldes også det hurtige system.', correctCategoryId: 'alarm'},
				{id: 9, title: 'Kaldes også amygdala/mandelhjernen/krybdyrhjernen.', correctCategoryId: 'alarm'},
			]
		}
	],
	
};

export {
	minigameData
}