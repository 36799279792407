import React, { Component } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import appConfig from 'config/app.config';
import apiHelper from 'helpers/api-helper';
import Loading from 'components/loading/loading';
import SettingsController from 'components/settings/settings-controller';
import Admin from 'components/admin/admin';
import Game from 'components/game/game';

class FacilitatorController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			page: 'settings',
			userData: null,
			games: [],
			groups: [],
			users: [],
			allowedUsers: [],
			gameId: null,
			feedback: null
		};
		this.timeout = null;
		this.unsubscribeUser = null;
		this.unsubscribeGames = null;
		this.unsubscribeGroups = null;
		this.unsubscribeUsers = null;
		this.unsubscribeAllowedUsers = null;
	}

	/**
	 * Component mounted
	 */
	componentDidMount() {
		/* Get facilitator data */
		this.subscribeToUser().then((response) => {
			if (response.status === 'success') {
				const subscriptions = [this.subscribeToGames()];
				if (this.state.userData.isAdmin === true) {
					subscriptions.push(this.subscribeToUsers());
					subscriptions.push(this.subscribeToAllowedUsers());
				}
				/* Subscribe to games (and users and allowed users if admin) */
				Promise.all(subscriptions).then(() => {
					this.setState({isLoading: false});
				});
			}
		});
	}

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		/* Clear timeout */
		if (this.timeout) clearTimeout(this.timeout);

		/* Unsubscribe from games */
		if (this.unsubscribeUser !== null) this.unsubscribeUser();
		if (this.unsubscribeGames !== null) this.unsubscribeGames();
	};

	/**
	 * Subscribe to user (facilitator) data
	 */
	subscribeToUser = () => {
		if (this.unsubscribeUser !== null) this.unsubscribeUser();

		return new Promise((resolve) => {
			const db = firebase.firestore();
			this.unsubscribeUser = db.collection(appConfig.usersDbName).doc(this.props.userId).onSnapshot((doc) => {
				if (doc.exists) {
					/* Get user data */
					let userData = {id: doc.id, ...doc.data()};

					/* Update state */
					this.setState({userData}, () => {resolve({ status: 'success' });});
				} else {
					console.error('user data not found');
					this.setState({loadErrMsg: 'Error: User data not in database. Auto log out in 5 seconds.'}, () => {
						this.timeout = setTimeout(() => {this.props.handleLogout();}, 5000);
					});
				}
			},
			(error) => {
				console.error('could not get user: ', error);
				this.setState({loadErrMsg: 'Error: ' + error.code + '. Auto log out in 5 seconds.'}, () => {
					this.timeout = setTimeout(() => {this.props.handleLogout();}, 5000);
				});
			});
		});
	};

	/**
	 * Called by the facilitator to subscribe to all their games.
	 */
	subscribeToGames = () => {
		if (this.unsubscribeGames !== null) this.unsubscribeGames();

		return new Promise((resolve) => {
			const db = firebase.firestore();
			const query = db.collection(appConfig.gamesDbName).where('facilitatorId', '==', this.props.userId);			
			this.unsubscribeGames = query.onSnapshot((querySnapshot) => {
				let games = [];
				querySnapshot.forEach((doc) => {games.push({id: doc.id, ...doc.data()});});
				this.setState({games: games}, () => {resolve({status: 'success'});});
			}, (error) => {
				console.error('could not get games: ', error);
				resolve({status: 'error', error: error});
			});
		});
	};

	/**
	 * Called by the admin to subscribe to all users (facilitators + admins)
	 */
	subscribeToUsers = () => {
		if (this.unsubscribeUsers !== null) this.unsubscribeUsers();
	
		return new Promise((resolve) => {
			const db = firebase.firestore();
			db.collection(appConfig.usersDbName).onSnapshot((querySnapshot) => {
				let users = [];
				querySnapshot.forEach((doc) => {users.push({id: doc.id, ...doc.data()});});
				this.setState({users: users}, () => {resolve({status: 'success'});});
			}, (error) => {
				console.error('could not get users: ', error);
				resolve({status: 'error', error: error});
			});
		});
	};

	/**
	 * Called by the admin to subscribe to list of allowed users
	 */
	subscribeToAllowedUsers = () => {
		if (this.unsubscribeAllowedUsers !== null) this.unsubscribeAllowedUsers();
		return new Promise((resolve) => {
			const db = firebase.firestore();
			db.collection(appConfig.allowedUsersDbName).onSnapshot((querySnapshot) => {
				let allowedUsers = [];
				querySnapshot.forEach((doc) => {allowedUsers.push({id: doc.id, ...doc.data()});});
				this.setState({allowedUsers: allowedUsers}, () => {resolve({status: 'success'});});
			}, (error) => {
				console.error('could not get allowed users: ', error);
				resolve({status: 'error', error: error});
			});
		});
	};

	/**
	 * Update user (facilitator) data
	 * @param {object} updates 
	 * @returns 
	 */
	updateUser = (updates) => {
		const userId = this.props.userId;
		const db = firebase.firestore();
		const userRef = db.collection('users').doc(userId);
		return userRef.update(updates);
	};

	/**
	 * Delete a user, their data and all their games
	 * @param {string} userId 
	 * @returns 
	 */
	deleteUser = (userId, auserId) => {
		return new Promise((resolve) => {
			apiHelper('facilitator/delete-user', {userId: userId, auserId: auserId}).then(
				(response)=>{
					if (response.status === 'success') {
						resolve({status: 'success'});
					} else {
						console.error(response);
						resolve({status: 'error', error: response});
					}
				},
				(error) => {
					console.error(error);
					resolve({status: 'error', error: error});
				}
			);
		});
	};

	/**
	 * Update game
	 * @param {object} updates
	 * @returns {promise}
	 */
	updateGame = (updates, id) => {
		let gameId = id ? id : this.state.gameId;
		let db = firebase.firestore();
		let gameRef = db.collection(appConfig.gamesDbName).doc(gameId);
		return gameRef.update(updates);
	};

	handleGoToPage = (page) => {
		this.setState({page: page, gameId: null, groups: []});
	};

	/**
	 * Go to game
	 * @param {string} gameId
	 */
	handleGoToGame = (gameId) => {
		/* No game */
		if (!gameId) {
			this.setState({page: 'settings', gameId: null});
			return;
		}

		/* Game already selected */
		if (gameId === this.state.gameId) {
			this.setState({ page: 'game' });
			return;
		}

		/* Start game (it not already started) */
		let gameData = this.state.games.find((game) => {return game.id === gameId;});
		if (gameData && !gameData.isStarted) {
			this.updateGame({isStarted: true}, gameId);
		}

		/* Select game  */
		this.setState({gameId: gameId, page: 'game', groups: []});
	};


	/**
	 * Open/close minigames of a page
	 * @returns 
	 */
	toggleMinigameStatus = (pageId, status) => {
		return new Promise((resolve) => {
			apiHelper('facilitator/toggle-minigames-status', {
				gameId: this.state.gameId,
				pageId: pageId,
				status: status
			}).then(
				(response)=>{
					if (response.status === 'success') {
						resolve({status: 'success'});
					} else {
						console.error(response);
						resolve({status: 'error', error: response});
					}
				},
				(error) => {
					console.error(error);
					resolve({status: 'error', error: error});
				}
			);
		});
	};

	/**
	 * Update minigame
	 * @param {object} updates 
	 * @returns 
	 */
	updateMinigame = (minigameId, updates) => {
		return new Promise((resolve) => {
			apiHelper('facilitator/update-minigame', {
				gameId: this.state.gameId,
				minigameId: minigameId,
				updates
			}).then(
				(response)=>{
					if (response.status === 'success') {
						resolve({status: 'success'});
					} else {
						console.error(response);
						resolve({status: 'error', error: response});
					}
				},
				(error) => {
					console.error(error);
					resolve({status: 'error', error: error});
				}
			);
		});
	};

	/**
	 * Render component
	 */
	render() {
		if (this.state.isLoading) {
			return (
				<Loading 
					feedback={this.state.feedback}
					handleLogout={this.props.handleLogout} 
				/>
			);
		};

		if (this.state.page === 'admin') {
			return (
				<Admin 
					userId={this.props.userId}
					users={this.state.users}
					allowedUsers={this.state.allowedUsers}
					deleteUser={this.deleteUser}
					handleGoToPage={this.handleGoToPage}	
				/>
			);
		}

		if (this.state.page === 'game') {
			let gameData = this.state.games.find((game) => {return game.id === this.state.gameId;});
			return (
				<Game 
					role="facilitator"
					userData={this.state.userData}
					gameData = {gameData}
					handleGoToGame={this.handleGoToGame}
					toggleMinigameStatus={this.toggleMinigameStatus}
					updateMinigame={this.updateMinigame}
					updateGame={this.updateGame}
					handleLogout={this.props.handleLogout}					
				/>
			);
		}
		return (
			<SettingsController 
				userId={this.props.userId}
				userData={this.state.userData}
				games={this.state.games}
				updateUser={this.updateUser}
				updateGame={this.updateGame}
				handleGoToPage={this.handleGoToPage}
				handleGoToGame={this.handleGoToGame}
				handleLogout={this.props.handleLogout}
			/>
		);
	}
}

FacilitatorController.propTypes = {
	userId: PropTypes.string.isRequired,
	handleLogout: PropTypes.func.isRequired,
};

export default FacilitatorController;
