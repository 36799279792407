/**
 * Get minigame status
 * @param {string} pageId 
 * @param {object} gameData 
 * @returns 
 */
export function getMinigamesStatus(pageId, gameData) {
	const pageGameData = (gameData && gameData.pages && gameData.pages[pageId] 
		? gameData.pages[pageId] : null
	);

	const minigamesStatus = (pageGameData && pageGameData.minigamesStatus 
		? pageGameData.minigamesStatus : 'ready');

	return minigamesStatus;
}