/* eslint-disable camelcase */
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';

/* Log environment */
let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
console.log('ENVIRONMENT: ', env);

let firebaseConfig = {};

/* Connect to firebase project test (includes demo) */
if (env === 'development' || env === 'test' || env === 'demo') {
	firebaseConfig = {
		apiKey: 'AIzaSyB4AWXkWfUtDWqNUXBNUxZeqfaCHrtfLAY',
		authDomain: 'cgl-mentalisering-test.firebaseapp.com',
		projectId: 'cgl-mentalisering-test',
		storageBucket: 'cgl-mentalisering-test.appspot.com',
		messagingSenderId: '441300038382',
		appId: '1:441300038382:web:53d7c1984720f80641f9ff'
	};
}

/* Connect to firebase: production */
if (env === 'production') {
	firebaseConfig = {
		apiKey: 'AIzaSyB14xIttqrGhJMw0offwVnBHfh7VeMyESo',
		authDomain: 'cgl-mentalisering-production.firebaseapp.com',
		projectId: 'cgl-mentalisering-production',
		storageBucket: 'cgl-mentalisering-production.appspot.com',
		messagingSenderId: '954855554696',
		appId: '1:954855554696:web:d9c508a1b7b3e2c6dc693d'
	};
}


/* Initialize firebase */
// const app = firebase.initializeApp(firebaseConfig);

firebase.initializeApp(firebaseConfig);
/* Initialize analytics */
// if (env === 'production') {
// 	firebase.analytics(app);
// }

export default firebase.firestore;