import React from 'react';
import PropTypes from 'prop-types';
import './icon.scss';

const Icon = ({iconId}) => {
	
	return (
		<div className={'Icon ' + iconId} />
	);

};




Icon.propTypes = {
	iconId: PropTypes.string.isRequired,
};


export default Icon;