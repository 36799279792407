const minigameData = {
	id: 'm5-thought-traps',
	title: 'Tanketræning',
	playBtnText: 'Start øvelse',
	viewBtnText: 'Prøv øvelse og se elevsvar',
	parts: [

		{
			id: 1,
			type: 'brainstorm',
			title: 'Del 1',
			categories: [
				{id: 'useful', title: 'Brugbare tanker', btnText: 'Brugbar tanke'},
				{id: 'negative', title: 'Negative tanker', btnText: 'Negativ tanke'},
			],
			part1IntroPopupText: `Skriv jeres bud på, hvad der er brugbare tanker, og hvad der er negative tanker.
				<br /><br/>Hver gang I har skrevet noget, så tryk på "Brugbar tanke" eller "Negativ tanke" for at sende den op til jeres underviser.`,
			part1InfoPopupText: `Eksempler på en persons brugbare tanker:
				<br />"Det kan jeg godt", "Det bliver godt", "Det bliver en god dag i klassen", "Jeg er ok", "Jeg er god nok, som jeg er", "Pyt". 
				<br /><br />Eksempler på en persons negative tanker:
				<br />"Det regner altid", "De andre er altid meget dygtigere end mig", "Alt er kedeligt, og jeg gider ikke", "Jeg er dårlig til…", "Jeg er grim", "Jeg kan ikke", "Lortedag", "Der er altid uro i klassen".`,
			part1Placeholder: `Skriv et ord eller en sætning og tryk på "negativ tanke" eller "brugbar tanke".`,

			part2IntroPopupText: `Omformuler de negative tanker til brugbare tanker.`,
			part2InfoPopupText: `Omformuler de negative tanker til brugbare tanker`,
			part2CategoryId: 'useful',
			part2Options: [
				'Jeg fik 02 i terminsprøven, så nu går det helt galt til eksamen. Jeg kommer ikke til at bestå',
				'Når vi skal på lejrskole, kommer jeg helt sikkert til at sidde helt alene, mens de andre bare hygger sig sammen på deres værelser',
				'Den dårlige stemning er min skyld, det er nok, fordi jeg er kedelig',
				'Min kammerat skrev ikke til mig i går. Det er nok, fordi han synes jeg er for meget ',
				'Sådan er det bare. Jeg er alligevel én, de andre ikke gider at være sammen med',
				'Sidste idrætstime var nederen, så det bliver sikkert også nederen i dag',
				'Enten rykker jeg op på et andet hold i fodbold, eller også stopper jeg',
				'Jeg skal have nul fejl i den her prøve, ellers kan det være lige meget'
			],
			part2Placeholder: `Skriv en negativ tanke om til en brugbar tanke og tryk på "Send til din undervisers skærm"`,
			part2SubmitBtnText: `Send til din undervisers skærm`,
			part2Title: 'Omformuler de negative tanker til brugbare tanker',
			part2TitleGroup: 'Negative tanker til brugbare tanker',
		},
	],
	submissions: {

	}
};

export {
	minigameData
}