import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {gameUiTexts} from 'data/ui-texts';
import Button from '../button/button';
import Image from '../image/image';
import BrainstormFacilitator from 'components/minigames/brainstorm/brainstorm-facilitator'; 
import BrainstormGroup from 'components/minigames/brainstorm/brainstorm-group';
import Sort from 'components/minigames/sort/sort';
import Dialogue from 'components/minigames/dialogue/dialogue';
import Organize from 'components/minigames/organize/organize';
import Match from 'components/minigames/match/match';
import PdfMaker from 'components/minigames/pdf-maker/pdf-maker';
import './popup-minigame.scss';

const PopupMinigame = ({role, minigameId, pageData, gameData, updateMinigame, togglePopup}) => {
	/* Minigame data */
	let minigameData = null;
	let minigameGameData = null;
	if (minigameId) {
		minigameData = pageData.minigames.find((m) => {return m.id === minigameId;});
		if (gameData && gameData.minigames && gameData.minigames[minigameId]) {
			minigameGameData = gameData.minigames[minigameId];
		}
	}

	/* Part index, game status */
	const [partIndex, setPartIndex] = useState(0);
	const [gameStatus, setGameStatus] = useState('ready');

	const handleGoToNextPart = () => {
		const nextPartIndex = partIndex + 1;
		setPartIndex(nextPartIndex);
	};
	
	/* Minigame component */
	let MinigameComponent = null;
	let imageId = null;
	if (minigameData && minigameData.parts && minigameData.parts.length > partIndex) {
		const minigamePartType = minigameData.parts[partIndex].type;
		if (minigamePartType === 'brainstorm') {
			MinigameComponent = (role === 'facilitator' ? BrainstormFacilitator : BrainstormGroup);
		}
		if (minigamePartType === 'sort') MinigameComponent = Sort;
		if (minigamePartType === 'dialogue') MinigameComponent = Dialogue;
		if (minigamePartType === 'organize') MinigameComponent = Organize;
		if (minigamePartType === 'match') MinigameComponent = Match;
		if (minigamePartType === 'pdf-maker') MinigameComponent = PdfMaker;
		if (minigamePartType === 'image') {
			MinigameComponent = Image;
			imageId = minigameData.parts[partIndex].imageId;
		}
	}

	const showPrevBtn = (partIndex > 0 ? true : false);
	const showNextBtn = (minigameData && minigameData.parts && partIndex + 1 < minigameData.parts.length 
		? true : false
	);

	return (
		<div className="PopupMinigame">
			<div className="PopupMinigame-content">
				

				<MinigameComponent 
					type="minigame"
					gameId={gameData.id}
					partIndex={partIndex}
					gameStatus={gameStatus}
					imageId={imageId}
					minigameData={minigameData}
					minigameGameData={minigameGameData}
					updateMinigame={updateMinigame}
					setPartIndex={setPartIndex}
					setGameStatus={setGameStatus}
					handleGoToNextPart={handleGoToNextPart}
				/>
				<div className="PopupMinigame-closeBtn">
					<Button classes={['popup-close']} onClick={togglePopup} />
				</div>

				{showPrevBtn && <div className="PopupMinigame-prevBtn"><Button 
					classes={['red']}
					text={minigameData.parts[partIndex - 1].title} 
					onClick={() => {setPartIndex((partIndex - 1));}} 
				/></div>} 
				{showNextBtn && <div className="PopupMinigame-nextBtn"><Button 
					classes={['red']}
					text={minigameData.parts[partIndex + 1].title} 
					onClick={() => {setPartIndex((partIndex + 1));}} 
				/></div>}
				{!showNextBtn && <div className="PopupMinigame-nextBtn"><Button 
					classes={['red']}
					text={gameUiTexts.finish} 
					onClick={togglePopup} 
				/></div>}

				
			</div>
			<div className="PopupMinigame-title">
				<span>{minigameData.title}</span>
			</div>
		</div>
	);
};

PopupMinigame.propTypes = {
	role: PropTypes.string.isRequired,
	minigameId: PropTypes.string.isRequired,
	pageData: PropTypes.object.isRequired,
	gameData: PropTypes.object,
	updateMinigame: PropTypes.func,
	togglePopup: PropTypes.func.isRequired
};

export default PopupMinigame;
