import React from 'react';
import PropTypes from 'prop-types';
import {loginUiTexts} from 'data/ui-texts';
import Button from 'components/ui/button/button';
import './login-group.scss';

const LoginGroup = (props) => {
	const {
		isLoading,
		code,
		feedback,
		handleInput,
		handleFindGame,
	} = props;

	/* Input game code */
	return (
		<div className="LoginGroup">
			<div className="LoginGroup-title"><span>{loginUiTexts.groupLogin}</span></div>
			<div className="LoginGroup-body">
				<form className="LoginGroup-form" onSubmit={(e) => {handleFindGame(e);}}>
					<input
						name="code"
						type="text"
						placeholder={loginUiTexts.gameCode} 
						autoComplete="section-grouplogin code"
						value={code ? code : ''}
						onChange={(event)=>{handleInput(event);}}
					/>
					<div className="LoginGroup-loginBtn">
						<Button 
							isLoading={isLoading}
							classes={['login']}
							text={loginUiTexts.login}
							onClick={() => {handleFindGame();}}
						/>
					</div>
					<div className="LoginGroup-errorMessage">{feedback}</div>
				</form>
			</div>
		</div>
	);
		
};

LoginGroup.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	code: PropTypes.any,
	feedback: PropTypes.string,
	handleInput: PropTypes.func.isRequired,
	handleFindGame: PropTypes.func.isRequired,
};

export default LoginGroup;