import React, {useState, useEffect} from 'react';
import appConfig from 'config/app.config';
import {cookieUiTexts} from 'data/ui-texts';
import {getCookie, setCookie} from 'helpers/cookie-helper';
import Button from 'components/ui/button/button';
import './cookie-consent-popup.scss';

const CookieConsentPopup = () => {
	/* Show / hide popup */
	const [showCookieConsentPopup, setShowCookieConsentPopup] = useState(false);

	/**
	 * Component mounted / will unmount
	 */
	 useEffect(() => {
		/* Component mounted */
		const cookieConsent = getCookie(appConfig.cookiesAcceptedCookieName);
		if (!cookieConsent) setShowCookieConsentPopup(true);

		/* Component will ummount */
		// return () => {};
	}, []);

	/* Accept and close cookies popup */
	const handleClosePopup = () => {
		setCookie(appConfig.cookiesAcceptedCookieName, 'ok');
		setShowCookieConsentPopup(false);
	};


	if (!showCookieConsentPopup) return null;

	return (
		<div className="CookieConsentPopup">
			<div className="CookieConsentPopup-text">
				<span>{cookieUiTexts.popupText}</span>
				<Button 
					classes={['red']} 
					text={cookieUiTexts.popupBtn} 
					onClick={(handleClosePopup)}
				/>
			</div>
		</div>
	);	
};


export default CookieConsentPopup;