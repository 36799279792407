import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {gameUiTexts} from 'data/ui-texts';
import apiHelper from 'helpers/api-helper';
import Button from 'components/ui/button/button';
import './brainstorm-facilitator.scss';

const BrainstormFacilitator = (props) => {
	const {partIndex, minigameData, gameId, minigameGameData, updateMinigame, handleGoToNextPart} = props;

	const [isUpdatingMinigame, setIsUpdatingMinigame] = useState(false);
	const partId = (minigameGameData && minigameGameData.partId ? minigameGameData.partId : 1);

	/**
	 * 
	 * @param {object} updates 
	 * @returns 
	 */
	const handleUpdateMinigame = (updates) => {
		if (isUpdatingMinigame) return;
		setIsUpdatingMinigame(true);
		updateMinigame(minigameData.id, updates).then((response) => {
			setIsUpdatingMinigame(false);
		});
	};

	/**
	 * Remove group submission
	 * @param {number} submissionIndex 
	 * @returns 
	 */
	const removeSubmission = (submissionIndex) => {
		if (isUpdatingMinigame) return;
		setIsUpdatingMinigame(true);
		apiHelper('facilitator/remove-minigame-submission', {
			gameId,
			minigameId: minigameData.id,
			submissionIndex
		}).then(
			(response)=>{
				if (response.status === 'success') {
					setIsUpdatingMinigame(false);
				} else {
					console.error(response);
					setIsUpdatingMinigame(false);
				}
			},
			(error) => {
				console.error(error);
				setIsUpdatingMinigame(false);
			}
		);
	};

	return (
		<div className={'BrainstormFacilitator ' + minigameData.id}>
			{/* Part 1 */}
			{(partId === 1) && <div className="BrainstormFacilitator-part1">
				{/* Submissions */}
				<div className="BrainstormFacilitator-categories">
					{minigameData.parts[partIndex].categories.map((category) => {
						return (
							<div key={category.id} className="BrainstormFacilitator-category">
								<div className="BrainstormFacilitator-categoryTitle">
									<span>{category.title}</span>
								</div>
								<div className="BrainstormFacilitator-categorySubmissions">
									{(minigameGameData && minigameGameData.submissions) && 
										minigameGameData.submissions.map((submission, index) => {
											if (submission.partId === partId && submission.categoryId === category.id) {
												return (
													<div 
														key={index} 
														className="BrainstormFacilitator-categorySubmission"
													>
														<span>{submission.value}</span>
														<div 
															className="BrainstormFacilitator-removeSubmissionBtn" 
															onClick={() => {removeSubmission(index);}}
														/>
													</div>
												);
											}
											return null;
										})
									}
								</div>
							</div>
						);
					})}
				</div>

				{/* Next part button */}
				<div className="BrainstormFacilitator-nextPartBtn">
					<Button 
						text={gameUiTexts.continue + ' >'} 
						classes={['red']}
						isLoading={isUpdatingMinigame}
						onClick={() => {handleUpdateMinigame({partId: 2});}}
					/>
				</div>
			</div>}

			{/* Part 2 */}
			{(partId === 2) && <div className="BrainstormFacilitator-part2">
				<div className="BrainstormFacilitator-title">
					<span>{minigameData.parts[partIndex].part2Title}</span></div>
				<div className="BrainstormFacilitator-submissions">
					{(minigameGameData && minigameGameData.submissions) && 
						minigameGameData.submissions.map((submission, index) => {
							if (submission.partId === partId) {
								return (
									<div key={index} className="BrainstormFacilitator-submission">
										<span>{submission.value}</span>
										<div 
											className="BrainstormFacilitator-removeSubmissionBtn" 
											onClick={() => {removeSubmission(index);}}
										/>
									</div>
								);
							} 
							return null;
						})
					}
				</div>

				{/* Finish button */}
				<div className="BrainstormFacilitator-buttons">
					<div className="BrainstormFacilitator-backBtn">
						<Button 
							text={'< Tilbage til Del 1'} 
							classes={['red']}
							isLoading={isUpdatingMinigame}
							onClick={() => {handleUpdateMinigame({partId: 1});}}
						/>
					</div>
					{(partId === 2 && partIndex + 1 < minigameData.parts.length) && 
						<div className="BrainstormFacilitator-nextBtn">
							<Button classes={['red']} text={gameUiTexts.next} onClick={() => {handleGoToNextPart();}} />
						</div>
					}
				</div>
			</div>}
		</div>
	);
};

BrainstormFacilitator.propTypes = {
	partIndex: PropTypes.number.isRequired,
	minigameData: PropTypes.object.isRequired,
	gameId: PropTypes.string.isRequired,
	minigameGameData: PropTypes.object,
	updateMinigame: PropTypes.func.isRequired,
	handleGoToNextPart: PropTypes.func.isRequired,
};

export default BrainstormFacilitator;
