import React from 'react';
import PropTypes from 'prop-types';
import {useDrop} from 'react-dnd';
import './match-dnd-container.scss';

const MatchDndContainer = ({isEmpty, layout, groupId, typeId, handleFindItem, handleMoveItem, children}) => {
	/* Drop functionality */
	const [{isOver, canDrop}, drop] = useDrop({
		accept: 'item',
		drop(item, monitor) {
			if (handleFindItem && handleMoveItem) {
				const {fromGroupId, fromTypeId} = handleFindItem(item.groupId, item.typeId);

				/* Ignore none-shallow drops */
				// if (containerId === 'all' && !monitor.isOver({shallow: true})) return;
				
				/* Ignore dropping on blank space or itself */
				if (
					groupId === 'all' || 
					(fromGroupId === groupId && fromTypeId === typeId)
				) return;

				/* Move card */
				handleMoveItem(item.groupId, item.typeId, item.fromGroupId, item.fromTypeId, groupId, typeId);
			}
		},
		collect: (monitor) => {
			return {
				isOver: monitor.isOver(),
				canDrop: monitor.canDrop(),
			};
		}
	});

	return (
		<div 
			id={groupId + '-' + typeId} 
			ref={drop}
			className={'MatchDndContainer ' + layout + ' ' 
				 + typeId + (isOver && canDrop ? ' hover' : '') + (isEmpty ? ' empty' : '')}
		>
			{children}
		</div>
	);
};

MatchDndContainer.propTypes = {
	isEmpty: PropTypes.bool.isRequired,
	layout: PropTypes.string.isRequired,
	groupId: PropTypes.string.isRequired,
	typeId:  PropTypes.string.isRequired,
	handleFindItem: PropTypes.func.isRequired,
	handleMoveItem: PropTypes.func.isRequired,
	children: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.object,
	]),
};

export default MatchDndContainer;
