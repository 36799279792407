import React from 'react';
import PropTypes from 'prop-types';
import {gameUiTexts, loginUiTexts} from 'data/ui-texts';
import Logo from 'components/ui/logo/logo';
import Button from 'components/ui/button/button';
import './group-lobby.scss';

const GroupLobby = ({handleLogout}) => {
	
	return (
		<div className="GroupLobby">
			{/* Logo */}
			<div className="GroupLobby-logo"><Logo page="game" /></div>

			
			<div className="GroupLobby-wrap">
				<div className="GroupLobby-text">{gameUiTexts.lookAtTeachersScreen}</div>

				{/* Log out btn (group only) */}
				<div className="GroupLobby-logoutBtn">
					<Button classes={['red', 'logout']} text={loginUiTexts.logout} onClick={handleLogout} />
				</div>
			</div>
		</div>
	);
};

GroupLobby.propTypes = {
	handleLogout: PropTypes.func.isRequired,
};

export default GroupLobby;
