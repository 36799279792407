import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import {facilitatorUiTexts, loginUiTexts} from 'data/ui-texts';
import {sortArrayByProperty} from 'helpers/array-helper';
import {renderMarkdown} from 'helpers/text-helper';
import Logo from 'components/ui/logo/logo';
import Button from 'components/ui/button/button';
import CreateGamePopup from './create-game-popup';
import DeleteGamePopup from './delete-game-popup';
import ResourcesPopup from './resources-popup';
import Logos from 'components/ui/logos/logos';
import './settings.scss';


const Settings = (props) => {
	let {
		showCreateGamePopup,
		showDeleteGamePopup,
		showResourcesPopup,
		isCreatingGame,
		isDeletingGame,
		userData,
		gameId,
		games,
		toggleCreateGamePopup,
		toggleDeleteGamePopup,
		toggleResourcesPopup,
		handleCreateGame,
		handleDeleteGame,
		handleGoToGame,
		handleGoToPage,
		handleLogout,
	} = props;


	/* Sort games by created  date and title, filter by facilitatorId */
	let sortedGames = sortArrayByProperty(games, 'created', 'DESC', 'title', 'ASC');


	return (
		<div className="Settings">
			<div className="Settings-logo"><Logo page="settings" /></div>
			<div className="Settings-logoutBtn">
				<Button classes={['red', 'logout']} text={loginUiTexts.logout} onClick={() => {handleLogout();}} />
			</div>

			<div className="Settings-createGameBtn">
				<Button 
					classes={['red-medium']}
					text={facilitatorUiTexts.createNewGameBtn}
					onClick={() => {toggleCreateGamePopup();}}
				/>
			</div>
			<div className="Settings-resourcesBtn">
				<Button 
					classes={['red-medium']}
					text={facilitatorUiTexts.resources}
					onClick={() => {toggleResourcesPopup();}}
				/>
			</div>
			{userData.isAdmin === true && <div className="Settings-adminBtn">
				<Button 
					classes={['red']}
					text={facilitatorUiTexts.adminBtn}
					onClick={() => {handleGoToPage('admin');}}
				/>
			</div>}

			<Logos />

			<div className="Settings-info">
				{renderMarkdown(facilitatorUiTexts.info)}
			</div>

			<div className="Settings-games">
				{/* Loop over games */}
				{sortedGames.map((game) => {
					const isStarted = (game.isStarted ? true : false);
					const creationDate = (game.created ? dayjs(game.created).format('DD/MM-YYYY') : '-');
					return (
						<div key={game.id} className={'Settings-game' + (isStarted ? ' started' : '')}>
							{/* Game header: title, delete btn */}
							<div className="Settings-gameHeader">
								<div className="Settings-gameTitle">{game.title}</div>
								<div 
									className="Settings-deleteBtn" 
									onClick={() => {toggleDeleteGamePopup(game.id);}}
								/>
							</div>
							{/* Game body: game code, creation date */}
							<div className="Settings-gameBody">
								<div className="Settings-gameCode">
									<span>{facilitatorUiTexts.gameCode}:</span>
									{game.id}
								</div>

								<div className="Settings-gameDate">
									<span>{facilitatorUiTexts.created}:</span>
									{creationDate}
								</div>
								<div className={'Settings-playBtn' + (isStarted ? ' started' : '')}>
									<Button 
										classes={['login']}
										text={facilitatorUiTexts.start}
										onClick={() => {handleGoToGame(game.id);}}
									/>
								</div>
									
								
								
							</div>
						</div>
					);
				})}
			</div>



			{/* Create game popup */}
			{showCreateGamePopup &&
				<CreateGamePopup 
					isCreatingGame={isCreatingGame}
					handleCreateGame={handleCreateGame}
					toggleCreateGamePopup={toggleCreateGamePopup}
				/>}
			{/* Delete game popup */}
			{(showDeleteGamePopup && gameId) && 
				<DeleteGamePopup 
					isDeletingGame={isDeletingGame}
					gameId={gameId}
					toggleDeleteGamePopup={toggleDeleteGamePopup}
					handleDeleteGame={handleDeleteGame}
				/>
			}
			{/* Resourcess popup */}
			{showResourcesPopup &&
				<ResourcesPopup toggleResourcesPopup={toggleResourcesPopup} />}

		</div>
	);
};

Settings.propTypes = {
	showCreateGamePopup: PropTypes.bool.isRequired,
	showDeleteGamePopup: PropTypes.bool.isRequired,
	showResourcesPopup: PropTypes.bool.isRequired,
	isCreatingGame: PropTypes.bool.isRequired,
	isDeletingGame: PropTypes.bool.isRequired,
	userData: PropTypes.object.isRequired,
	gameId: PropTypes.string,
	games: PropTypes.array.isRequired,
	toggleCreateGamePopup: PropTypes.func.isRequired,
	toggleDeleteGamePopup: PropTypes.func.isRequired,
	toggleResourcesPopup: PropTypes.func.isRequired,
	handleCreateGame: PropTypes.func.isRequired,
	handleDeleteGame: PropTypes.func.isRequired,
	handleGoToGame: PropTypes.func.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired,
};

export default Settings;
