import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDrag} from 'react-dnd';
import {getEmptyImage} from 'react-dnd-html5-backend';
import './match-dnd-item.scss';

const MatchDndItem = ({
	isDraggable,
	itemData,
	classes,
	handleMoveItem
}) => {
	/* Drag functionality */
	const [{ isDragging }, drag, preview] = useDrag({
		type: 'item',
		item: {
			type: 'item', 
			groupId: itemData.groupId, 
			typeId: itemData.typeId, 
			fromGroupId: itemData.placedInGroupId, 
			fromTypeId: itemData.placedInTypeId
		},
		canDrag() {return (isDraggable);},
		isDragging(monitor) {
			return (itemData.groupId === monitor.getItem().groupId && itemData.typeId === monitor.getItem().typeId);
		},
		endDrag(monitor) {
			if (handleMoveItem && !(monitor.didDrop())) {
				let item = monitor.getItem();
				handleMoveItem(item.groupId, item.typeId, item.fromGroupId, item.fromTypeId, null, null);
			}
		},
		collect: (monitor) => {return {isDragging: monitor.isDragging()};},
	});

	/* Hide browser-drawn drag preview image when dragging */
	useEffect(() => {
		preview(getEmptyImage(), { captureDraggingState: true });
	});

	/* Opacity (invisible if dragging) */
	const opacity = (isDragging ? 0 : 1);

	/* Class name */
	let className = 'MatchDndItem ' + itemData.typeId;
	if (!isDraggable) className += ' locked';
	if (classes && classes.length > 0) {classes.forEach((c) => {className += ' ' + c;});}
	
	return (
		<div
			ref={drag}
			className={className}
			style={{opacity}}
		>
			<div className="MatchDndItem-text"><span>{itemData.text}</span></div>
		</div>
	);
};

MatchDndItem.propTypes = {
	isDraggable: PropTypes.bool.isRequired,
	itemData: PropTypes.object.isRequired,

	handleMoveItem: PropTypes.func.isRequired,
	classes: PropTypes.array

};

export default MatchDndItem;
