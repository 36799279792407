import React from 'react';
import PropTypes from 'prop-types';
import {facilitatorUiTexts} from 'data/ui-texts';
import {resourcesData} from 'data/resources-data';
import './resources-popup.scss';

const ResourcesPopup = ({toggleResourcesPopup}) => {

	return (
		<div className="ResourcesPopup" onClick={() => {toggleResourcesPopup(null);}}>
			<div className="ResourcesPopup-content" onClick={(e) => {e.stopPropagation();}}>
				<div className="ResourcesPopup-header">
					<div className="ResourcesPopup-title">
						{facilitatorUiTexts.resources}
					</div>
				</div>
				<div className="ResourcesPopup-body">
					{resourcesData.map((resourceGroup, groupIndex) => {
						return (
							<div className="ResourcesPopup-resourceGroup" key={groupIndex} >
								<div className="ResourcesPopup-text"><span>{resourceGroup.title}:</span></div>
								<div className="ResourcesPopup-buttons">
									{resourceGroup.files.map((file, index) => {
										if (groupIndex === 0) {
											return (
												<a 
													key={index}
													className={'ResourcesPopup-button'}
													href={'/guide-til-facilitering'} 
													target="_blank"
													rel="noopener noreferrer" 
												><span>{file.title}</span></a>
											);
										}

										return (
											<a 
												key={index}
												className={'ResourcesPopup-button'}
												href={'/files/' + file.file} 
												download
												rel="noopener noreferrer" 
											><span>{file.title}</span></a>
										
										);
									})}
								</div>
							</div>
						);
					})}
				</div>				
			</div>
		</div>
	);
};

ResourcesPopup.propTypes = {
	toggleResourcesPopup: PropTypes.func.isRequired
};

export default ResourcesPopup;
