const modulesData = [
	{
		id: 'module-1',
		title: 'Modul 1',
		colorId: 'pink',
		description: 'I får kendskab til alarmhjernen og tænkehjernen, værktøjskassen og den svære cirkel. Med en vejrtrækningsøvelse får I ro på.',
		pages: require('data/module-pages/module-1-pages').modulePages,
	},
	{
		id: 'module-2',
		title: 'Modul 2',
		colorId: 'purple',
		description: 'I arbejder videre med alarmhjernen og tænkehjernen, og så får I et værktøj til problemløsning. I arbejder også med, hvordan I kan finde balance mellem aktivitet og pauser.',
		pages: require('data/module-pages/module-2-pages').modulePages,
		requiredModuleId: 'module-1',
	},
	{
		id: 'module-3',
		title: 'Modul 3',
		colorId: 'green',
		description: 'I arbejder med tanker og tanketræning. Modulet handler også om at passe godt på sig selv og hinanden og vide, hvordan man kan give sig selv mere energi og overskud. På modul 3 bliver I også introduceret til Basalplan.',
		pages: require('data/module-pages/module-3-pages').modulePages,
		requiredModuleId: 'module-2',
	},
	{
		id: 'module-4',
		title: 'Modul 4',
		colorId: 'pink',
		description: 'I arbejder med tanker og følelser. I skal også arbejde med, hvordan god kommunikation kan give et godt fællesskab.',
		pages: require('data/module-pages/module-4-pages').modulePages,
		requiredModuleId: 'module-3',
	},
	{
		id: 'module-5',
		title: 'Modul 5',
		colorId: 'yellow',
		description: 'I arbejder med tanketræning, herunder brugbare og negative tanker. Afsnittet om søvn skal give en bevidsthed om søvnens indvirkning på, hvordan I går og har det.',
		pages: require('data/module-pages/module-5-pages').modulePages,
		requiredModuleId: 'module-4',
	},
	{
		id: 'module-6',
		title: 'Modul 6',
		colorId: 'purple',
		description: 'I arbejder med, hvordan jeres opmærksomhed kan hjælpe jer med at få styr på tankerne. Med en afledningsøvelse får I et værktøj til at aflede tankerne. Og så skal I arbejde med et basalt værktøj, som kan være en hjælp, når I skal træffe en beslutning.',
		pages: require('data/module-pages/module-6-pages').modulePages,
		requiredModuleId: 'module-5',
	},
	{
		id: 'module-7',
		title: 'Modul 7',
		colorId: 'blue',
		description: 'I arbejder med, hvordan tanker, følelser, krop og handling gensidigt påvirker hinanden. I skal også give gode råd til, hvordan man kan handle anderledes i forskellige situationer.',
		pages: require('data/module-pages/module-7-pages').modulePages,
		requiredModuleId: 'module-6',
	},
	{
		id: 'module-8',
		title: 'Modul 8',
		colorId: 'yellow',
		description: 'I får et værktøj til at fokusere på det gode i livet. Og så skal I arbejde med, hvordan I kan håndtere konflikter på en konstruktiv måde.',
		pages: require('data/module-pages/module-8-pages').modulePages,
		requiredModuleId: 'module-7',
	},

];

export {
	modulesData
};