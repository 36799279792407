import React from 'react';
import PropTypes from 'prop-types';
import {usePreview} from 'react-dnd-preview';
import './match-dnd-preview.scss';

const MatchDndPreview = ({layout, items}) => {
	const {display, item, style} = usePreview();
	if (!display) return null;

	/* Item data */
	const itemData = items.find((i) => {
		return (i.groupId === item.groupId && i.typeId === item.typeId);
	});

	return (
		<div 
			className={'MatchDndPreview ' + layout}
			style={style}
		>
			<div className="MatchDndPreview-item">
				<div className="MatchDndPreview-text"><span>{itemData.text}</span></div>
			</div>
		</div>
	);
};

MatchDndPreview.propTypes = {
	layout: PropTypes.string.isRequired,
	items: PropTypes.array.isRequired
};

export default MatchDndPreview;