const minigameData = {
	id: 'm8-conflicts',
	title: 'Dialog',
	playBtnText: 'Start øvelse',
	viewBtnText: 'Prøv øvelse',
	parts: [
		/* Karla og Sofie */
		{
			id: 1,
			type: 'dialogue',
			title: 'Del 1',
			introPopupText: `Karla og Sofie går i samme klasse. Sofie har lagt et billede på Instagram uden Karlas tilladelse. I skal vælge Sofies svar til Karla.`,
			infoPopupText: `Vælg, hvad Sofie skal svare Karla. Jeres svar kan op- eller nedtrappe konflikten.`,
			dialogueOutro: `Læg mærke til, hvordan jeres valg af svar får konflikten til at op- eller nedtrappe. Gør det nu en gang til, men prøv nogle andre muligheder og se, hvordan de to pigers samtale udvikler sig.`,
			people: {
				'person-1': 'Karla',
				'person-2': 'Sofie'
			},
			defaultOptionPrompt: `Vælg Sofies svar`,
			steps: [
				{
					id: 2,
					events: [{
						personId: 'person-1',
						type: 'dialogue',
						text: `Det er så mega tarveligt, at du bare lægger et billede op af mig uden at spørge mig først.`
					}],
					optionType: 'dialogue',
					options: [
						{
							id: 1,
							text: `Jeg troede sgu da ikke, at du ville blive ked af det over det. Jeg troede, du ville blive glad.`,
							nextStepId: 3,
						},
						{
							id: 2,
							text: `Slap af! Det var jo ikke for at gøre dig ked af det. Du er så sart.`,
							nextStepId: 4
						},
					]
				},
				{
					id: 3,
					events: [{
						personId: 'person-1',
						type: 'dialogue',
						text: `Ok. men det gjorde jeg ikke.  Jeg føler mig bare hængt ud, når du ikke spørger først.`
					}],
					optionType: 'dialogue',
					options: [
						{
							id: 1,
							text: `Hvad med dig selv? Du spørger da heller aldrig!`,
							nextStepId: 5
						},
						{
							id: 2,
							text: `Jeg kan godt se, at jeg har fejlet. Det er jeg sgu ked af.`,
							nextStepId: 6
						},
						{
							id: 3,
							text: `Det gik lidt hurtigt. Jeg tænkte mig ikke rigtigt om, men der sker jo ikke noget ved det.`,
							nextStepId: 7
						},
					]
				},
				{
					id: 4,
					events: [{
						personId: 'person-1',
						type: 'dialogue',
						text: `Hvad!? Hvor er du latterlig. Det er typisk dig ikke at spørge først.`
					}],
					optionType: 'dialogue',
					options: [
						{
							id: 1,
							text: `Slap af. Det var jo ikke for at gøre dig ked af det. Du bliver altid så fucking sur. Jeg er så træt af at være venner med dig!`,
							nextStepId: 8
						},
						{
							id: 2,
							text: `Jeg er ked af, at jeg har fejlet. Kan vi ikke finde ud af det, så vi kan blive venner igen?`,
							nextStepId: 9
						},
						{
							id: 3,
							text: `Seriøst? Det var jo bare fra isbaren. Det var jo ikke noget vildt. Jeg synes, du er lidt for følsom.`,
							nextStepId: 10
						},
						{
							id: 4,
							text: `Det kan jeg godt forstå. Sådan ville jeg nok også have det.`,
							nextStepId: 11
						}
					]
				},
				{
					id: 5,
					events: [{
						personId: 'person-1',
						type: 'dialogue',
						text: `Hvor er det strengt, at du bare fyrer den tilbage til mig. Jeg gider dig ikke mere!`
					}],
					nextStepId: 18
				},
				{
					id: 6,
					events: [{
						personId: 'person-1',
						type: 'dialogue',
						text: `Tak, fordi du lytter til mig.`
					}],
					nextStepId: 18
				},
				{
					id: 7,
					events: [{
						personId: 'person-1',
						type: 'dialogue',
						text: `Du tager det slet ikke seriøst. Jeg gider ikke at snakke med dig mere!`
					}],
					optionType: 'dialogue',
					options: [
						{
							id: 1,
							text: `Jeg var ikke klar over, at det var så vigtigt for dig. Undskyld.`,
							nextStepId: 12
						},
						{
							id: 2,
							text: `Fint, du så slipper jeg for dit brok.`,
							nextStepId: 13
						},
					]
				},
				{
					id: 8,
					events: [{
						personId: 'person-1',
						type: 'dialogue',
						text: `Det er, fordi du opfører dig så sygt. Jeg gider heller ikke dig mere!`
					}],
					nextStepId: 18
				},
				{
					id: 9,
					events: [{
						personId: 'person-1',
						type: 'dialogue',
						text: `Jo, lad os lave en aftale om at vi spørger hinanden først.`
					}],
					nextStepId: 18
				},
				{
					id: 10,
					events: [{
						personId: 'person-1',
						type: 'dialogue',
						text: `Du tager det slet ikke seriøst. Jeg gider ikke at snakke med dig mere!`,
					}],
					optionType: 'dialogue',
					options: [
						{
							id: 1,
							text: `Okay, kan du så ikke forklare mig, hvorfor det er så stort et problem?`,
							nextStepId: 14
						},
						{
							id: 2,
							text: `Så slipper du også for, jeg tager billeder af dig.`,
							nextStepId: 15
						}
					],
				},
				{
					id: 11,
					events: [{
						personId: 'person-1',
						type: 'dialogue',
						text: `Tak, fordi du lytter til mig.`
					}],
					nextStepId: 18
				},
				{
					id: 12,
					events: [{
						personId: 'person-1',
						type: 'dialogue',
						text: `Det er okay. Tak, fordi du lytter til mig.`
					}],
					nextStepId: 18
				},
				{
					id: 13,
					events: [{
						personId: 'person-1',
						type: 'dialogue',
						text: `Fint! Og så slipper jeg for dig!`
					}],
					nextStepId: 18
				},
				{
					id: 14,
					events: [{
						personId: 'person-1',
						type: 'dialogue',
						text: `Jeg kan bare bedst lide selv at bestemme, hvilke billeder folk ser af mig.`,
					}],
					optionType: 'dialogue',
					options: [
						{
							id: 1,
							text: `Det kan jeg faktisk godt forstå. Undskyld.`,
							nextStepId: 12
						},
						{
							id: 2,
							text: `Okay, men kunne du ikke bare have sagt det?`,
							nextStepId: 16
						}
					],
				},
				{
					id: 15,
					events: [{
						personId: 'person-1',
						type: 'dialogue',
						text: `Godt, så slipper du også for mig!`
					}],
					nextStepId: 18
				},
				{
					id: 16,
					events: [{
						personId: 'person-1',
						type: 'dialogue',
						text: `Jo, det er rigtigt. Kan vi lave en aftale om, at vi spørger hinanden, inden vi lægger noget ud?`,
					}],
					optionType: 'dialogue',
					options: [
						{
							id: 1,
							text: `God idé.`,
							nextStepId: 17
						}
					],
				},
				{
					id: 17,
					events: [{
						personId: 'person-1',
						type: 'dialogue',
						text: `Fedt, det er jeg glad for.`
					}],
					nextStepId: 18
				},
				{
					id: 18,
					isFinalStep: true,
					events: [{
						type: 'action',
						text: `Læg mærke til, hvordan jeres valg af svar får konflikten til at op- eller nedtrappe. Gør det nu en gang til, men prøv nogle andre muligheder og se, hvordan de to pigers samtale udvikler sig.`
					}],
				}
			]			
		},
		/* Ahmad og Vera */
		{
			id: 2,
			type: 'dialogue',
			title: 'Del 2',
			introPopupText: `Ahmad og Vera bliver uvenner over, hvem af dem der skal med i skolefestudvalget. De har fået lige mange stemmer. Læreren siger, at de selv skal blive enige.
				<br /><br />Vælg Veras svar til Ahmad, så konflikten enten optrappes eller nedtrappes.`,
			infoPopupText: `Vælg, hvad Vera skal svare Ahmad. Jeres svar kan op- eller nedtrappe konflikten.`,
			dialogueOutro: `Læg mærke til, hvordan jeres valg af svar får konflikten til at op- eller nedtrappe. Gør det nu en gang til, men prøv nogle andre muligheder og se, hvordan samtalen udvikler sig.`,
			people: {
				'person-1': 'Ahmad',
				'person-2': 'Vera'
			},
			defaultOptionPrompt: `Vælg Veras svar`,
			steps: [
				{
					id: 31,
					events: [{
						personId: 'person-1',
						type: 'dialogue',
						text: `Det er altid dig, der får lov til alt det, der er sjovt. Kan du ikke bare give mig chancen?`
					}],
					optionType: 'dialogue',
					options: [
						{
							id: 1,
							text: `Aldrig.`,
							nextStepId: 32,
						},
						{
							id: 2,
							text: `Joo, måske, men kan du ikke prøve at forklare hvorfor det er så vigtigt for dig. Jeg vil det jo også vildt gerne...`,
							nextStepId: 33
						},
					]
				},
				{
					id: 32,
					events: [{
						personId: 'person-1',
						type: 'dialogue',
						text: `Mener du det? Hvor er du nederen.`
					}],
					optionType: 'dialogue',
					options: [
						{
							id: 1,
							text: `Jeg vil det bare virkelig gerne, og jeg fik ikke lov sidste år.`,
							nextStepId: 34
						},
						{
							id: 2,
							text: `Ja, det mener jeg - og jeg vil være bedre til opgaven end dig.`,
							nextStepId: 35
						}
					]
				},
				{
					id: 33,
					events: [{
						personId: 'person-1',
						type: 'dialogue',
						text: `Det betyder rigtig meget for mig. Jeg har jo ikke været med før. Og jeg har vildt mange gode idéer.`
					}],
					optionType: 'dialogue',
					options: [
						{
							id: 1,
							text: `Det kan da godt være, men det har jeg da også. Jeg er ikke enig med dig i, at det altid er mig, der får lov til alt det sjove. Du tror, at alting handler om dig!`,
							nextStepId: 36
						},
						{
							id: 2,
							text: `Det kan jeg godt forstå. Ved du hvad? Jeg synes, at du skal repræsentere os. Jeg kan høre, at det er vigtigt for dig.`,
							nextStepId: 37
						}							
					]
				},
				{
					id: 34,
					events: [{
						personId: 'person-1',
						type: 'dialogue',
						text: `OK. Jeg vidste ikke, det var så vigtigt for dig.`
					}],
					optionType: 'dialogue',
					options: [
						{
							id: 1,
							text: `Tak, fordi du giver dig.`,
							nextStepId: 38
						},
						{
							id: 2,
							text: `Hvis jeg nu kommer med, så kan vi jo lige tale sammen inden mødet, og så kan jeg høre dine idéer.`,
							nextStepId: 39
						},
					]
				},
				{
					id: 35,
					events: [{
						personId: 'person-1',
						type: 'dialogue',
						text: `Hvorfor er du så ego?`
					}],
					optionType: 'dialogue',
					options: [
						{
							id: 1,
							text: `Du er da præcis lige så ego.`,
							nextStepId: 40
						},
						{
							id: 2,
							text: `Det er da ikke ego at jeg gerne vil lave en god fest.`,
							nextStepId: 41
						},
					]
				},
				{
					id: 36,
					events: [{
						personId: 'person-1',
						type: 'dialogue',
						text: `Okay, slap af. Du er altid sådan en drama queen.`
					}],
					optionType: 'dialogue',
					options: [
						{
							id: 1,
							text: `Slap dog selv af!`,
							nextStepId: 42
						},
						{
							id: 2,
							text: `Du kan bare give dig, så er det ikke besværligt.`,
							nextStepId: 43
						},
					]
				},
				{
					id: 37,
					nextStepId: 46,
					events: [{
						personId: 'person-1',
						type: 'dialogue',
						text: `Fedt, tak. Det var pænt af dig.`
					}],
				},
				{
					id: 38,
					nextStepId: 46,
					events: [{
						personId: 'person-1',
						type: 'dialogue',
						text: `Det er okay. I har bare at lave en god fest.`
					}],
				},
				{
					id: 39,
					nextStepId: 46,
					events: [{
						personId: 'person-1',
						type: 'dialogue',
						text: `Det er en super god idé. Tak.`
					}],
				},
				{
					id: 40,
					nextStepId: 46,
					events: [{
						personId: 'person-1',
						type: 'dialogue',
						text: `Hvor modent bare at sige 'hvad med dig selv'. Jeg gider ikke mere.`
					}],
				},
				{
					id: 41,
					nextStepId: 46,
					events: [{
						personId: 'person-1',
						type: 'dialogue',
						text: `Nåja, for jeg vil jo bare lave en dårlig fest. Selvfølgelig. Ved du hvad? Hyg dig i udvalget, jeg gider ikke.`
					}],
				},
				{
					id: 42,
					nextStepId: 46,
					events: [{
						personId: 'person-1',
						type: 'dialogue',
						text: `Der kan du se, hvad jeg mener. Der skal altid være drama med dig. Hyg dig i festudvalget, jeg gider ikke på den her måde.`
					}],
				},
				{
					id: 43,
					events: [{
						personId: 'person-1',
						type: 'dialogue',
						text: `Eller også kan du bare give dig.`,
					}],
					optionType: 'dialogue',
					options: [
						{
							id: 1,
							text: `Kommer ikke til at ske.`,
							nextStepId: 44
						},
						{
							id: 2,
							text: `Okay, men så skal du også høre mine ideer så du kan tage dem med til mødet.`,
							nextStepId: 45
						}
					],
				},
				{
					id: 44,
					nextStepId: 46,
					events: [{
						personId: 'person-1',
						type: 'dialogue',
						text: `Nå, men jeg giver mig heller ikke, så vi kommer til at sidde her for evigt.`
					}],
				},
				{
					id: 45,
					events: [{
						personId: 'person-1',
						type: 'dialogue',
						text: `Det er faktisk en god idé. Den er jeg med på.`
					}],
					nextStepId: 46
				},
				{
					id: 46,
					isFinalStep: true,
					events: [{
						type: 'action',
						text: `Læg mærke til, hvordan jeres valg af svar får konflikten til at op- eller nedtrappe. Gør det nu en gang til, men prøv nogle andre muligheder og se, hvordan samtalen udvikler sig.`
					}],
				}
			]
		},
		/* Tobias og Mikkel */
		{
			id: 3,
			type: 'dialogue',
			title: 'Del 3',
			introPopupText: `Tobias og Mikkel går i samme klasse. Mikkel har fortalt en historie, som Tobias ikke tror på - måske har han misforstået noget? I skal vælge, hvad Mikkel skal svare Tobias.`,
			infoPopupText: `Vælg, hvad Mikkel skal svare Tobias. Jeres svar kan op- eller nedtrappe konflikten.`,
			dialogueOutro: `Læg mærke til, hvordan jeres valg af svar får konflikten til at op- eller nedtrappe. Gør det nu en gang til, men prøv nogle andre muligheder og se, hvordan de to drenges samtale udvikler sig.`,
			people: {
				'person-1': 'Tobias',
				'person-2': 'Mikkel'
			},
			defaultOptionPrompt: `Vælg Mikkels svar`,
			steps: [
				{
					id: 61,
					events: [{
						personId: 'person-1',
						type: 'dialogue',
						text: `Jeg synes, du er skrald, når du lyver for mig.`
					}],
					optionType: 'dialogue',
					options: [
						{
							id: 1,
							text: `Stop dig selv! Jeg lyver sgu da ikke.`,
							nextStepId: 62,
						},
						{
							id: 2,
							text: `Lad være med at sige, at jeg lyver.`,
							nextStepId: 63
						},
						{
							id: 3,
							text: `Hvad er det, du ikke tror på?`,
							nextStepId: 64
						},
					]
				},
				{
					id: 62,
					events: [{
						personId: 'person-1',
						type: 'dialogue',
						text: `Hvor dum tror du lige, at jeg er?`
					}],
					optionType: 'dialogue',
					options: [
						{
							id: 1,
							text: `Pænt dum.`,
							nextStepId: 65
						},
						{
							id: 2,
							text: `Overhovedet ikke dum.`,
							nextStepId: 66
						},
						{
							id: 3,
							text: `Men hvad er det, du ikke tror på?`,
							nextStepId: 64
						},
					]
				},
				{
					id: 63,
					events: [{
						personId: 'person-1',
						type: 'dialogue',
						text: `Så lad være med at lyve.`
					}],
					optionType: 'dialogue',
					options: [
						{
							id: 1,
							text: `Jeg tror altså, du har misforstået det, jeg siger.`,
							nextStepId: 67
						},
						{
							id: 2,
							text: `Jeg bliver altså ked af det, når du siger, at jeg lyver.`,
							nextStepId: 68
						},
						{
							id: 3,
							text: `Jeg gider ikke at snakke med dig, når du skal være sådan.`,
							nextStepId: 69
						}
					]
				},
				{
					id: 64,
					events: [{
						personId: 'person-1',
						type: 'dialogue',
						text: `Hvad med det hele? Det er jo totalt usandsynligt at du kunne gøre det.`
					}],
					optionType: 'dialogue',
					options: [
						{
							id: 1,
							text: `Hvorfor er det så svært at tro på mig?`,
							nextStepId: 71
						},
						{
							id: 2,
							text: `Jeg siger jo ikke, at jeg gjorde det hele alene.`,
							nextStepId: 70
						},
						{
							id: 3,
							text: `Ved du, hvad der er usandsynligt? At man kan være så latterlig som dig.`,
							nextStepId: 65
						}
					]
				},
				{
					id: 65,
					events: [{
						personId: 'person-1',
						type: 'dialogue',
						text: `Fuck hvor er du åndssvag.`
					}],
					nextStepId: 75
				},
				{
					id: 66,
					events: [{
						personId: 'person-1',
						type: 'dialogue',
						text: `Men hvorfor fortæller du mig så sådan en historie om at du helt alene kan alt det der pis?`
					}],
					optionType: 'dialogue',
					options: [
						{
							id: 1,
							text: `Jeg tror altså, du har misforstået det, jeg siger.`,
							nextStepId: 67
						},
						{
							id: 2,
							text: `Jeg siger jo ikke, at jeg gjorde det hele alene.`,
							nextStepId: 70
						}
					]
				},
				{
					id: 67,
					events: [{
						personId: 'person-1',
						type: 'dialogue',
						text: `Så prøv at forklare det én gang til.`
					}],
					optionType: 'dialogue',
					options: [
						{
							id: 1,
							text: `Okay, jeg prøver lige at starte forfra.`,
							nextStepId: 72
						},
						{
							id: 2,
							text: `Jeg gider ikke når du alligevel bare beskylder mig for at lyve.`,
							nextStepId: 69
						}
					]
				},
				{
					id: 68,
					events: [{
						personId: 'person-1',
						type: 'dialogue',
						text: `Okay, det forstår jeg faktisk godt. Jeg synes bare, det lød usandsynligt, at du kunne alt det alene.`
					}],
					optionType: 'dialogue',
					options: [
						{
							id: 1,
							text: `Nej nej, jeg var ikke alene. Jeg var bare med til det.`,
							nextStepId: 70
						},
						{
							id: 2,
							text: `Det er så typisk dig, at du aldrig tror på mig.`,
							nextStepId: 73
						}
					]
				},
				{
					id: 69,
					events: [{
						personId: 'person-1',
						type: 'dialogue',
						text: `Fint, jeg gider sgu da heller ikke snakke med dig.`
					}],
					nextStepId: 75
				},
				{
					id: 70,
					events: [{
						personId: 'person-1',
						type: 'dialogue',
						text: `Så har jeg måske misforstået det. Jeg troede bare, at du prøvede at snyde mig.`
					}],
					optionType: 'dialogue',
					options: [
						{
							id: 1,
							text: `Nej, slet ikke. Men måske behøver du ikke at dømme mig så hurtigt.`,
							nextStepId: 74
						},
						{
							id: 2,
							text: `Det kan jeg godt forstå, du kunne tro. Det kunne jeg også have troet.`,
							nextStepId: 72
						}
					]
				},
				{
					id: 71,
					events: [{
						personId: 'person-1',
						type: 'dialogue',
						text: `Jeg tror bare ikke på at du kan gøre alt det dér helt alene.`
					}],
					optionType: 'dialogue',
					options: [
						{
							id: 1,
							text: `Se, du tror ikke på mig. Jeg gider det ikke.`,
							nextStepId: 69
						},
						{
							id: 2,
							text: `Jeg tror altså, du har misforstået det, jeg siger.`,
							nextStepId: 67
						},
						{
							id: 3,
							text: `Jeg siger jo ikke at jeg gjorde det hele alene.`,
							nextStepId: 70
						}
					]
				},
				{
					id: 72,
					events: [{
						personId: 'person-1',
						type: 'dialogue',
						text: `Tak, fordi du også ser det fra min side.`
					}],
					nextStepId: 75
				},
				{
					id: 73,
					events: [{
						personId: 'person-1',
						type: 'dialogue',
						text: `Og det er så typisk dig at du altid digter ting for at snyde mig. Jeg gider det ikke.`
					}],
					nextStepId: 75
				},
				{
					id: 74,
					events: [{
						personId: 'person-1',
						type: 'dialogue',
						text: `Okay, så nu er det pludselig min skyld. Du er helt fra den.`
					}],
					nextStepId: 75
				},
				{
					id: 75,
					isFinalStep: true,
					events: [{
						type: 'action',
						text: `Læg mærke til, hvordan jeres valg af svar får konflikten til at op- eller nedtrappe. Gør det nu en gang til, men prøv nogle andre muligheder og se, hvordan de to drenges samtale udvikler sig.`
					}],
				}
			]
		},
		{
			id: 4,
			type: 'image',
			title: 'Del 4',
			imageId: 'conflict-behaviours'
			
		}
	]
};

export {
	minigameData
}