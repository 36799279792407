const modulePages = [
	{
		id: 'm5-intro',
		isFacilitatorOnly: true,
		title: 'Intro til modulet',
		facilitatorInstructions: `Følg guiden. 
			<br /><br />Fortæl eleverne, hvad der skal ske på modul 5.`,
		facilitatorText: null,
		groupText: null,
		icons: [],
		slides: [
			{
				id: 'video-dancing-bear',
				type: 'video',
				text: 'Se video',
				videoId: 'xNSgmm9FX2s'
			},
			{
				id: 'the-bad-circle',
				type: 'image', 
				text: 'Den svære cirkel'
			},
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskassen'
			},
		]
	},
	{
		id: 'm5-problem-solving',
		isFacilitatorOnly: true,
		title: 'Feedback og problemløsning',
		facilitatorInstructions: `Følg guiden trin for trin. <br/><br/>
		Eleverne fortæller, hvordan det er gået med basalplanen, og der gennemføres en fælles problemløsning.<br/><br/>
		Husk, at du selv er rollemodel.</br> </br>
		<strong>Gode råd</strong>
		<ul><li>Hvis en elev har gennemført sin basalplan, anerkender du eleven.
		<li>Hvis en elev har mødt forhindringer og har justeret sin basalplan, anerkender du elevens indsats og understreger, at det er en god strategi at justere sin plan.</li>
		<li>Hvis en elev har oplevet udfordringer og gennemført basalplanen delvist, bemærker du det positive i at være kommet i gang. Lav evt. en fælles problemløsning med udgangspunkt i elevens udfordring, medmindre eleven selv har idéer til løsning af udfordringen.</li>
		<li>Hvis en elev har oplevet udfordringer og ikke har gennemført sin basalplan, kan du starte med at spørge om eleven selv har løsningsforslag. Hvis ikke kan du foreslå en fælles problemløsning i klassen.</li></ul>
		</br>
		<p>Vær opmærksom på, så vidt muligt at gøre problemet til ”tavlens problem”, så løsninger gives til tavlen og ikke den enkelte elev. Vælg så vidt muligt udfordringer, som kan bredes ud, så flere elever kan spejle sig i dem.</p>`,
		facilitatorText: `<strong>Hvad gik din basalplan ud på?</strong><br /><br />
			<strong>Hvordan er det gået med din basalplan?</strong><br/><br/>
			<strong>Mulighed for problemløsning.</strong><br /><br /><br />`,
		icons: ['plan-of-action', 'problem-solving'],
		slides: [
			{
				id: 'plan-of-action',
				type: 'image',
				text: 'Basalplansskema'
			},
			{
				id: 'problem-solving',
				type: 'image',
				text: 'Problemløsningsskema'
			},
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskasse'
			},
			{
			id: 'the-bad-circle',
			type: 'image', 
			text: 'Den svære cirkel'
			},
		
		]	
	},

	{
		id: 'm5-thought-traning',
		isFacilitatorOnly: false,
		title: 'Tanketræning: Brugbare og negative tanker',
		menuTitle: 'Tanketræning: Brugbare og negative tanker',
		facilitatorInstructions: `Følg guiden. <br/><br/>
		Eleverne skal komme med bud på brugbare og negative tanker. Deres svar kommer til syne på din skærm. Eleverne kan arbejde sammen i mindre grupper eller alene. <br/><br/>
		Når eleverne har givet deres bud, skal de vende negative tanker til brugbare tanker.`,
		facilitatorText: `Gå til <strong>www.basalsammen.dk.</strong> </br></br>
		Skriv koden i elevlogin. </br></br>`,
		groupInstructions: `<strong>Opgave 1:</strong> Hvad er brugbare tanker og negative tanker? Skriv et bud på en persons brugbare eller negative tanker. <br/> <br/>
		<strong>Opgave 2:</strong> Vend de negative tanker til brugbare tanker. `,
		groupText: null,
		icons: ['online-teaching'],
		slides: [
			{
				id: 'idea-round',
				type: 'image',
				text: 'Idérunde'
			},
			{
				id: 'the-bad-circle',
				type: 'image', 
				text: 'Den svære cirkel'
			},
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskassen'
			}
		],
		minigames: [
			require('data/minigames/m5-thought-traps').minigameData
		]
	},
	{
		id: 'm5-active-break',
		isFacilitatorOnly: true,
		title: 'Aktiv pause',
		menuTitle: 'Aktiv pause',
		facilitatorInstructions: `Her kan du lægge en aktiv pause ind. Vælg én fra appendiks bagerst i Guide til facilitering eller find din egen. `,
		facilitatorText: null,
		groupText: null,
		icons: [],
		slides: [
			{
				id: 'the-bad-circle',
				type: 'image', 
				text: 'Den svære cirkel'
			},
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskassen'
			}
		],
		minigames: []
	},
	{
		id: 'm5-sleep',
		isFacilitatorOnly: true,
		title: 'Søvn',
		facilitatorInstructions: `Følg guiden`,
		facilitatorText: null,
		groupText: null,
		icons: ['calm'],
		slides: [
			{
				id: 'video-me-and-my-sleep',
				type: 'video',
				text: 'Mig og min søvn',
				videoId: '8bIsZ7YlMuk'
			}
		]
	},
	{
		id: 'm5-basal-plan',
		isFacilitatorOnly: true,
		title: 'Basalplan',
		menuTitle: 'Basalplan',
		facilitatorInstructions: `Følg guiden trin for trin. Eleverne udarbejder hver en basalplan. Husk, at du selv er rollemodel og også selv skal lave en basalplan.`,
		facilitatorText: `<strong>Opgave:</strong> <br/>
		Gå sammen i grupper og hjælp hinanden med at lave en basalplan. Fortæl jeres basalplaner til hinanden.`,
		groupText: null,
		icons: ['plan-of-action'],
		slides: [
			{
				id: 'plan-of-action',
				type: 'image',
				text: 'Basalplansskema'
			},
			{
				id: 'problem-solving',
				type: 'image',
				text: 'Problemløsningsskema'
			},
			{
				id: 'the-bad-circle',
				type: 'image', 
				text: 'Den svære cirkel'
			},
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskassen'
			}
		],
		minigames: []
	},
	{
		id: 'm5-finish',
		isFacilitatorOnly: true,
		title: 'Afrunding',
		menuTitle: 'Afrunding',
		facilitatorInstructions: `Følg guiden.`,
		facilitatorText: `<strong>Hvad har givet dig noget at tænke over?</strong> <br/><br/>
		<strong>Hvornår gør du noget meningsfuldt? Fx hvornår hjælper du dine klassekamerater eller din familie?</strong><br/><br/>
		<strong>Hvornår gør du noget sammen med andre?</strong>`,
		groupText: null,
		icons: ['toolbox'],
		slides: [
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskasse'
			},
			{
				id: 'the-bad-circle',
				type: 'image', 
				text: 'Den svære cirkel'
			},
		],
	},
];


export {
	modulePages
};