import React from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import Button from 'components/ui/button/button';
import './popup.scss';

const Popup = ({type, title, text, image, buttons}) => {
	return (
		<div className={'Popup ' + type}>
			<div className="Popup-content">
				{title && <div className="Popup-title"><span>{title}</span></div>}
				{text && <div className="Popup-text">{renderMarkdown(text)}</div>}
				{image && <div className={'Popup-image ' + image} />}
				{buttons && <div className={'Popup-buttons' + (buttons.length === 1 ? ' single' : '')}>
					{buttons.map((button, index) => {
						const buttonClasses = (button.classes ? button.classes : ['red']);
						return (
							<Button 
								key={index}
								classes={buttonClasses} 
								text={button.text} 
								onClick={() => {button.action(...button.parameters);}} />
						);
					})}
				</div>}
			</div>	
		</div>
	);
};

Popup.propTypes = {
	type: PropTypes.string,
	title: PropTypes.string,
	text: PropTypes.string,
	image: PropTypes.string,
	buttons: PropTypes.array
};

export default Popup;
