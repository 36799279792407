import React from 'react';
import PropTypes from 'prop-types';
import {facilitatorUiTexts} from 'data/ui-texts';
import './game-code.scss';

const GameCode = ({page, gameData}) => {


	return (
		<div className={'GameCode ' + page}>
			<div className="GameCode-label"><span>{facilitatorUiTexts.gameCode}:</span></div>
			<div className="GameCode-code"><span>{gameData.id}</span></div>
		</div>
	);
};

GameCode.propTypes = {
	page: PropTypes.string.isRequired,
	gameData: PropTypes.object.isRequired
};

export default GameCode;
