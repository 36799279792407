import {useEffect} from 'react';
import firebaseInit from 'firebase-init';
import {DndProvider} from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch';
import LoginController from 'components/login/login-controller';
import ImageLoader from './ui/image-loader/image-loader';
import PdfGuide from './pdf-guide/pdf-guide';
import './app.scss';

const App = () => {


	/**
	 * Component mounted / will unmount
	 */
	useEffect(() => {
		/* Component mounted */

		/* Initialize firebase */
		firebaseInit();

		/* Component will ummount */
		// return () => {};
	});

	if (window.location.pathname.toLowerCase().includes('guide-til-facilitering')) {
		return (
			<PdfGuide />
		);
	}


	return (
		<div className="App">
			<DndProvider options={HTML5toTouch}>
				<LoginController />
				<ImageLoader />
			</DndProvider>
		</div>
	);
};



export default App;
