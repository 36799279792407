import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { renderMarkdown } from 'helpers/text-helper';
import {gameUiTexts} from 'data/ui-texts';
import Button from '../button/button';
import './instructions.scss';

const Instructions = ({instructionsData}) => {
	
	const [isOpen, setIsOpen] = useState(true);
	const toggleInstructions = () => {
		setIsOpen(!isOpen);
	};
	
	return (
		<div className={'Instructions' + (isOpen ? '' : ' closed')}>
			<div className="Instructions-header">
				<div className="Instructions-title"><span>{gameUiTexts.instructions}</span></div>
				<div className="Instructions-toggleBtn" onClick={toggleInstructions} />
			</div>
			<div className="Instructions-body">
				{(instructionsData && instructionsData.text) && 
				<div className="Instructions-text">{renderMarkdown(instructionsData.text)}</div>
				}
				{(instructionsData && instructionsData.downloads) && 
					<div className="Instructions-buttons">
						{instructionsData.downloads.map((download, index) => {
							return (
								<div key={index} className="Instructions-downloadBtn">								
									<Button 
										openInNewTab={true}
										type="link" 
										classes={['red']} 
										text={download.title} 
										url={'/files/' + download.file} 
									/>
								</div>
							);
						})}
					</div>
				}
			</div>

		</div>
	);

};




Instructions.propTypes = {
	instructionsData: PropTypes.object.isRequired,
};


export default Instructions;