const imagesData = [
	'backgrounds/alarm-brain.svg',
	'backgrounds/background-game.svg',
	'backgrounds/background-gamepage-box.svg',
	'backgrounds/background-login-header.svg',
	'backgrounds/background-module-header.svg',
	'backgrounds/background-swirl-grey.svg',
	'backgrounds/background-swirl.svg',
	'backgrounds/background.svg',
	'backgrounds/thinking-brain.svg',
	'buttons/button-audio-pause.svg',
	'buttons/button-audio-play.svg',
	'buttons/button-instructions.svg',
	'buttons/button-login.svg',
	'buttons/button-red-download.svg',
	'buttons/button-red-frame.svg',
	'buttons/button-red-medium.svg',
	'buttons/button-red-wide.svg',
	'buttons/button-red.svg',
	'buttons/nav-first.svg',
	'buttons/nav-last.svg',
	'buttons/nav-next-skip.svg',
	'buttons/nav-next.svg',
	'buttons/nav-prev-skip.svg',
	'buttons/nav-prev.svg',
	'frames/frame-brainstorm-category.svg',
	'frames/frame-dialogue-example-1.svg',
	'frames/frame-dialogue-example-2.svg',
	'frames/frame-dialogue.svg',
	'frames/frame-game-code.svg',
	'frames/frame-match-container.svg',
	'frames/frame-menu-item-big-selected.svg',
	'frames/frame-menu-item-big.svg',
	'frames/frame-menu-item-selected.svg',
	'frames/frame-menu-item.svg',
	'frames/frame-organize-container.svg',
	'frames/frame-popup.svg',
	'frames/frame-sort-category-hover.svg',
	'frames/frame-sort-category.svg',
	'icons/alarm-brain.svg',
	'icons/analog-teaching.svg',
	'icons/attention.svg',
	'icons/calm.svg',
	'icons/communication.svg',
	'icons/idea-round.svg',
	'icons/making-decisions.svg',
	'icons/online-teaching.svg',
	'icons/plan-of-action.svg',
	'icons/problem-solving.svg',
	'icons/thinking-brain.svg',
	'icons/thoughts-helping.svg',
	'icons/toolbox.svg',
	'illustrations/eks-brugbar-tanke.svg',
	'illustrations/eks-negativ-tanke.svg',
	'illustrations/eksempel-1.svg',
	'illustrations/eksempel.svg',
	'illustrations/firkanten.svg',
	'illustrations/langsigtet-handleplan.svg',
	'illustrations/tanketraening-1-2-3.svg',
	'slides/balance-cirkel-1.svg',
	'slides/balance-cirkel-2.svg',
	'slides/beslutninger.svg',
	'slides/breaks.svg',
	'slides/case-1.svg',
	'slides/case-2.svg',
	'slides/case-3.svg',
	'slides/case-4.svg',
	'slides/case-5.svg',
	'slides/case-6.svg',
	'slides/case-7.svg',
	'slides/conflict-behaviours.svg',,
	'slides/den-daarlige-cirkel.svg',
	'slides/eks-beslutning.svg',
	'slides/exsample-1.svg',
	'slides/exsample-2.svg',
	'slides/handleplansskema.svg',
	'slides/kanin-and.svg',
	'slides/langsigtet-maal.svg',
	'slides/problemloesningsskema.svg',
	'slides/quotes.svg',
	'slides/stroop-1.svg',
	'slides/stroop-2.svg',
	'slides/tanketraening-eks-1.svg',
	'slides/tanketraening-eks-2.svg',
	'slides/thinking-example.svg',
	'slides/thought-and-feeling.svg',
	'slides/vaerktoejskasse.svg',
	'slides/thought-trap.svg',
	'icon-arrow-down.svg',
	'icon-arrow-left.svg',
	'icon-arrow-right.svg',
	'icon-audio-progress.svg',
	'icon-close.svg',
	'icon-info.svg',
	'icon-loading.svg',
	'icon-lobby.svg',
	'icon-logout.svg',
	'icon-play.svg',
	'icon-remove.svg',
	'icon-select-answer.svg',
	'icon-swirl.svg',
	'icon-trash.svg',
	'icon-video.svg',
	'logo-cgl.png',
	'logo-kfs.png',
	'logo-support.png',
	'logo.svg',
];

export {
	imagesData
};