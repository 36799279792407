import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {gameUiTexts} from 'data/ui-texts';
import {renderMarkdown} from 'helpers/text-helper';
import ReactToPdf from 'react-to-pdf';
import Button from 'components/ui/button/button';
import Popup from 'components/ui/popup/popup';
import './pdf-maker.scss';

const PdfMaker = ({partIndex, minigameData}) => {
	/* Pdf config */
	/* https://www.npmjs.com/package/react-to-pdf */
	const componentRef = useRef();
	const options = {
		orientation: 'landscape',
		unit: 'mm',
		format: [297, 210],
	};

	/* Input fields */
	const [inputFields, setInputFields] = useState([]);

	/* Info popup */
	const [showInfoPopup, setShowInfoPopup] = useState(false);

	/**
	 * Start game
	 * @param {bool} isFirstGame 
	 */
	const handleStartGamePart = (isFirstGame = true) => {
		if (
			minigameData && 
			minigameData.parts && 
			minigameData.parts[partIndex].textFields && 
			minigameData.parts[partIndex].textFields.length > 0
		) {
			setInputFields(minigameData.parts[partIndex].textFields);

			if (isFirstGame && minigameData.parts[partIndex].infoPopupText) setShowInfoPopup(true);
		} else {
			console.error('No minigame data');
		}
	};


	/**
	 * Update input field
	 * @param {object} event 
	 */
	const updateInputField = (event) => {
		const fieldIndex = inputFields.findIndex((i) => {return i.id === event.target.name;});
		if (fieldIndex >= 0) {
			const newInputFields = JSON.parse(JSON.stringify(inputFields));
			newInputFields[fieldIndex].value = event.target.value;
			setInputFields(newInputFields);
		}
	};

	/**
	 * Component mounted / will unmount
	 */
	 useEffect(() => {
		/* Component mounted */
		handleStartGamePart(true);	
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		handleStartGamePart();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [partIndex]);

	
	return (
		<div className={'PdfMaker ' + minigameData.id}>
			<div ref={componentRef} className="PdfMaker-pdfWrap">
				<div className="PdfMaker-title"><span>{minigameData.parts[partIndex].title2}</span></div>
				<div className="PdfMaker-text">{renderMarkdown(minigameData.parts[partIndex].text)}</div>
				<div className="PdfMaker-textFields">
					{inputFields.map((field) => {
						return (
							<div key={field.id} className={'PdfMaker-textField ' + field.id}>
								<div className="PdfMaker-textFieldTitle"><span>{field.title}</span></div>
								<div className="PdfMaker-textFieldInput">
									<textarea 
										name={field.id}
										value={(field.value ? field.value : '')}
										placeholder={field.text}
										onChange={(e) => {updateInputField(e);}}
									/>
								</div>
							</div>
						);
					})}
					{[...Array(minigameData.parts[partIndex].numberOfLines)].map((_, index) => {
						return <div key={index} className={'PdfMaker-line line-' + (index + 1)} />;
					})}
				</div>
			</div>
			{minigameData.parts[partIndex].showSaveAsPdfBtn === true && <div className="PdfMaker-makePdfBtn">
				<ReactToPdf 
					targetRef={componentRef} 
					options={options} 
					scale={minigameData.parts[partIndex].pdfScale}
					filename={minigameData.parts[partIndex].pdfFilename}
					x={20}
					y={20}
				>
					{({toPdf}) => {
						return (
							<Button classes={['red']} text={gameUiTexts.makePdf} onClick={toPdf} />
						);
					}}
				</ReactToPdf>
			</div>}

			{/* Info popup button */}
			{(minigameData.parts[partIndex].infoPopupText || minigameData.parts[partIndex].infoPopupImage) && 
				<div className="PdfMaker-infoBtn">
					<Button classes={['info']} onClick={() => {setShowInfoPopup(true);}} />
				</div>
			}
			

			{/* Info popup */}
			{showInfoPopup &&
				<Popup
					type={'intro' + (minigameData.parts[partIndex].infoPopupImage ? ' image' : '')}
					text={minigameData.parts[partIndex].infoPopupText} 
					image={minigameData.parts[partIndex].infoPopupImage}
					buttons={[
						{text: gameUiTexts.ok, action: setShowInfoPopup, parameters: [false]}
					]}
				/>
			}


		</div>
	);
};

PdfMaker.propTypes = {
	partIndex: PropTypes.number.isRequired,
	minigameData: PropTypes.object.isRequired
};

export default PdfMaker;
