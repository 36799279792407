import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {gameUiTexts} from 'data/ui-texts';
import apiHelper from 'helpers/api-helper';
import Button from 'components/ui/button/button';
import Popup from 'components/ui/popup/popup';
import './brainstorm-group.scss';

const BrainstormGroup = ({partIndex, minigameData, gameId, minigameGameData, handleGoToNextPart}) => {

	const [showIntroPopup, setShowIntroPopup] = useState(true);
	const [showInfoPopup, setShowInfoPopup] = useState(false);
	const [partId, setPartId] = useState(
		(minigameGameData && minigameGameData.partId ? minigameGameData.partId : 1)
	);
	const [textInput, setTextInput] = useState('');
	const [submittingCategoryId, setSubmittingCategoryId] = useState(null);

	useEffect(() => {
		if (minigameGameData && minigameGameData.partId && minigameGameData.partId !== partId) {
			setPartId(minigameGameData.partId);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [minigameGameData]);

	useEffect(() => {
		if (partId === 2) setShowIntroPopup(true);
	}, [partId]);



	/**
	 * Handle input
	 * @param {obj} event 
	 */
	const handleInput = (event) => {
		let value = event.target.value;
		setTextInput(value);
	};

	/**
	 * Submit input
	 * @param {string} categoryId 
	 * @returns 
	 */
	const submitInput = (categoryId) => {
		if (!textInput || textInput.length === 0 || submittingCategoryId) return; 

		setSubmittingCategoryId(categoryId);
		apiHelper('group/submit-minigame-input', {
			gameId: gameId,
			minigameType: minigameData.parts[partIndex].type,
			minigameId: minigameData.id,
			partId: partId,
			categoryId: categoryId,
			value: textInput
		}).then(
			(response)=>{
				if (response.status === 'success') {
					setSubmittingCategoryId(null);
					setTextInput('');
				} else {
					console.error(response);
					setSubmittingCategoryId(null);
				}
			},
			(error) => {
				console.error(error);
				setSubmittingCategoryId(null);
			}
		);
	};

	return (
		<div className={'BrainstormGroup' + (partId ? ' part-' + partId : '')}>
			{/* Minigame not opened yet */}
			{!partId && <div className="BrainstormGroup-ready"><span>{gameUiTexts.minigameNotOpenYet}</span></div>}

			{/* Part 1 */}
			{partId === 1 && <div className="BrainstormGroup-part1">
				{/* <div className="BrainstormGroup-instructions"></div> */}
				<textarea 
					className="BrainstormGroup-textarea"
					placeholder={minigameData.parts[partIndex].part1Placeholder}
					value={textInput ? textInput : ''}
					onChange={(event)=>{handleInput(event);}}
					
				/>
				<div className="BrainstormGroup-buttons">
					{minigameData.parts[partIndex].categories.map((category) => {
						return (
							<Button 
								key={category.id} 
								isLoading={submittingCategoryId === category.id}
								classes={['red', 'uppercase']}
								text={category.btnText} 
								onClick={() => {submitInput(category.id);}} 
							/>
						);
					})}
				</div>						
			</div>}

			{/* Part 2 */}
			{(partId === 2) && <div className="BrainstormGroup-part2">
				<div className="BrainstormGroup-category">
					<div className="BrainstormGroup-categoryTitle">
						{minigameData.id === 'm5-thought-traps' ?
							<span>
								{minigameData.parts[partIndex].part2TitleGroup}
							</span>
							: <span>
								{minigameData.parts[partIndex].categories.find((c) => {
									return c.id === minigameData.parts[partIndex].part2CategoryId;
								}).title}
							</span>}
						
						

						<div className="BrainstormGroup-options">
							{minigameData.parts[partIndex].part2Options.map((option, index) => {
								return (
									<div key={index} className='BrainstormGroup-option'>
										<span >{option}</span>
									</div>
								);
							})
							}
						</div>
					</div>
					{/* <div className="BrainstormGroup-categorySubmissions">
						{(minigameGameData && minigameGameData.submissions) && 
								minigameGameData.submissions.filter((submission) => {
									return (
										submission.partId === 1 && 
												submission.categoryId === minigameData.parts[partIndex].part2CategoryId
									);
								}).map((submission, index) => {
									return (
										<div key={index} className="BrainstormGroup-categorySubmission">
											<span>{submission.value}</span>
										</div>
									);
								})
						}
					</div> */}
					
				</div>
				<textarea 
					className="BrainstormGroup-textarea"
					placeholder={minigameData.parts[partIndex].part2Placeholder}
					value={textInput ? textInput : ''}
					onChange={(event)=>{handleInput(event);}}
				/>
				<div className="BrainstormGroup-button">
					<Button 
						isLoading={(submittingCategoryId ? true : false)}
						classes={['red']}
						text={minigameData.parts[partIndex].part2SubmitBtnText} 
						onClick={() => {submitInput(minigameData.parts[partIndex].part2CategoryId);}} 
					/>
				</div>
			</div>}

			{/* Info popup button */}
			{(
				(partId === 2 && minigameData.parts[partIndex].part2InfoPopupText) || 
				minigameData.parts[partIndex].part1InfoPopupText
			) &&
				<div className="BrainstormGroup-infoBtn">
					<Button classes={['info']} onClick={() => {setShowInfoPopup(true);}} />
				</div>
			}


			{(partId === 2 && partIndex + 1 < minigameData.parts.length) && 
				<div className="Organize-okBtn">
					<Button classes={['red']} text={gameUiTexts.next} onClick={() => {handleGoToNextPart();}} />
				</div>
			}

			{/* Info popup */}
			{showInfoPopup &&
				<Popup
					type={(partId === 1 ? 'intro-medium' : 'intro-big')}
					text={(partId === 2 
						? minigameData.parts[partIndex].part2InfoPopupText 
						: minigameData.parts[partIndex].part1InfoPopupText
					)} 
					buttons={[
						{text: gameUiTexts.ok, action: setShowInfoPopup, parameters: [false]}
					]}
				/>
			}

			{/* Intro popup */}
			{showIntroPopup && 
				<Popup 
					type={(partId === 1 ? 'intro' : 'intro-big')}
					text={(partId === 2 
						? minigameData.parts[partIndex].part2IntroPopupText 
						: minigameData.parts[partIndex].part1IntroPopupText
					)} 
					buttons={[
						{text: gameUiTexts.ok, action: setShowIntroPopup, parameters: [false]}
					]}
				/>
			}
		</div>
	);
};

BrainstormGroup.propTypes = {
	partIndex: PropTypes.number.isRequired,
	minigameData: PropTypes.object.isRequired,
	gameId: PropTypes.string.isRequired,
	minigameGameData: PropTypes.object,
	handleGoToNextPart: PropTypes.func.isRequired
};

export default BrainstormGroup;
