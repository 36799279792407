import React from 'react';
import PropTypes from 'prop-types';
import {usePreview} from 'react-dnd-preview';
import './organize-dnd-preview.scss';

const OrganizeDndPreview = ({layout, itemsData}) => {
	const {display, item, style} = usePreview();
	if (!display) return null;

	/* Item data */
	const itemData = itemsData.find((i) => {return i.id === item.id;});

	return (
		<div 
			className={'OrganizeDndDragLayer ' + layout}
			style={style}
		>
			<div className="OrganizeDndDragLayer-item">
				<div className="OrganizeDndDragLayer-text"><span>{itemData.text}</span></div>
			</div>
		</div>
	);
};

OrganizeDndPreview.propTypes = {
	layout: PropTypes.string.isRequired,
	itemsData: PropTypes.array.isRequired
};

export default OrganizeDndPreview;