const modulePages = [
	{
		id: 'm2-intro',
		isFacilitatorOnly: true,
		title: 'Intro til modulet',
		facilitatorInstructions: `Følg guiden.
			<br /><br />Fortæl eleverne, hvad der skal ske på modul 2.`,
		facilitatorText: null,
		icons: [],
		slides: [
			{
				id: 'video-two-brains',
				type: 'video',
				text: 'Se video',
				videoId: 'pi0Ggp18FhE'
			},
			{
				id: 'the-bad-circle',
				type: 'image', 
				text: 'Den svære cirkel'
			},
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskassen'
			}
		]	
	},
	{
		id: 'm2-problem-solving',
		isFacilitatorOnly: true,
		title: 'Problemløsning',
		facilitatorInstructions: `Følg guiden`,
		facilitatorText: null,
		icons: ['problem-solving'],
		slides: [
			{
				id: 'problem-solving',
				type: 'image',
				text: 'Problemløsningsskema'
			},
			{
				id: 'the-bad-circle',
				type: 'image', 
				text: 'Den svære cirkel'
			},
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskasse'
			},
		]	
	},
	{
		id: 'm2-the-two-brains',
		isFacilitatorOnly: false,
		title: 'Alarmhjernen og tænkehjernen',
		facilitatorInstructions: `Eleverne logger ind på www.basalsammen.dk og laver to digitale øvelser om tænkehjernen og alarmhjernen. I hver øvelse skal de placere udsagn i den rigtige "hjerne" og så trykke OK. Øvelsen fortæller, hvor mange rigtige eleverne har, hvorefter de kan prøve at spotte eventuelle fejl. 
			<br /><br />Eleverne arbejder sammen to og to.`,
		facilitatorText: `Gå til <strong>www.basalsammen.dk.</strong>
			<br /><br />Skriv kode i elevlogin.
			<br /><br /><span><%GAMECODE%></span><br /><br />`,
		groupText: `Start den digitale øvelse og find ud af, hvad der hører til henholdsvis tænkehjernen og alarmhjernen.`,
		icons: ['alarm-brain', 'thinking-brain', 'online-teaching'],
		minigames: [
			require('data/minigames/m2-the-two-brains').minigameData
		],
		slides: [
			{
				id: 'idea-round',
				type: 'image',
				text: 'Idérunde'
			},
			{
				id: 'the-bad-circle',
				type: 'image', 
				text: 'Den svære cirkel'
			},
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskasse'
			},
		]	
	},
	{
		id: 'm2-active-break',
		isFacilitatorOnly: true,
		title: 'Aktiv pause',
		menuTitle: 'Aktiv pause',
		facilitatorInstructions: `Her kan du lægge en aktiv pause ind. Vælg én fra appendiks bagerst i Guide til facilitering eller find din egen. `,
		facilitatorText: null,
		groupText: null,
		icons: [],
		slides: [
			{
				id: 'the-bad-circle',
				type: 'image', 
				text: 'Den svære cirkel'
			},
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskassen'
			}
		],
		minigames: []
	},
	{
		id: 'm2-balance',
		isFacilitatorOnly: true,
		title: 'Balance mellem aktivitet og pause',
		menuTitle: 'Balance mellem aktivitet og pause',
		facilitatorInstructions: `Følg guiden`,
		facilitatorText: null,
		groupText: null,
		icons: ['idea-round'],
		slides: [
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskasse'
			},
			{
				id: 'the-bad-circle',
				type: 'image', 
				text: 'Den svære cirkel'
			},
			{
				id: 'too-many-breaks',
				type: 'image',
				text: 'For mange pauser'
			},
			{
				id: 'too-few-breaks',
				type: 'image',
				text: 'For få pauser'
			},
		],
	},

	{
		id: 'm2-finish',
		isFacilitatorOnly: true,
		title: 'Afrunding',
		menuTitle: 'Afrunding',
		facilitatorInstructions: `Følg guiden.`,
		facilitatorText: `<strong>Hvad har givet dig noget at tænke over?</strong> <br/><br/>
		<strong>Hvad var svært?</strong><br/><br/>
		<strong>Hvornår har du en god skoledag?</strong><br/><br/>`,
		groupText: null,
		icons: ['toolbox'],
		slides: [
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskasse'
			},
			{
				id: 'the-bad-circle',
				type: 'image', 
				text: 'Den svære cirkel'
			},
		],
	},
];


export {
	modulePages
};