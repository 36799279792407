import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import {getMinigamesStatus} from 'helpers/game-helper';
import {modulesData} from 'data/modules-data';
import {facilitatorUiTexts, gameUiTexts, loginUiTexts} from 'data/ui-texts';
import {ReactComponent as BackgroundSwirl} from 'assets/images/backgrounds/background-swirl.svg';
import Logo from 'components/ui/logo/logo';
import GameCode from 'components/ui/game-code/game-code';
import Menu from 'components/ui/menu/menu';
import Instructions from 'components/ui/instructions/instructions';
import Button from 'components/ui/button/button';
import Icon from 'components/ui/icon/icon';
import NextStepButton from 'components/ui/next-step-button/next-step-button';
import PopupMinigame from 'components/ui/popup-minigame/popup-minigame';
import PopupSlide from 'components/ui/popup-slide/popup-slide';
import './game-page.scss';

const GamePage = (props) => {
	const {
		role, 
		moduleId,
		pageId, 
		gameData, 
		toggleMinigameStatus, 
		updateMinigame, 
		handleGoToPage, 
		handleLogout
	} = props;

	/* Module and page data */
	const moduleData = modulesData.find((m) => {return m.id === moduleId;});
	const pageData = moduleData.pages.find((page) => {return page.id === pageId;});
	
	/* Page text & buttons*/
	const pageInstructions = (role === 'facilitator' && pageData.facilitatorInstructions 
		? {text: pageData.facilitatorInstructions}
		: role === 'group' && pageData.groupInstructions 
			? {text: pageData.groupInstructions}
			: null
	);
	if (pageInstructions && role === 'facilitator' && pageData.facilitatorDownloads) {
		pageInstructions.downloads = pageData.facilitatorDownloads;
	}
	
	let pageText = (role === 'facilitator' ? pageData.facilitatorText : pageData.groupText);
	if (pageText) pageText = pageText.replace('<%GAMECODE%>', gameData.id);
	const pageSlides = (pageData.slides ? pageData.slides : null);
	const showMinigames = (pageData.hasOwnProperty('minigames') && pageData.minigames.length > 0);
	const showMinigamesToggleBtn = (role === 'facilitator' && showMinigames);


	/* Minigames status (ready / open / closed) */
	const minigamesStatus = getMinigamesStatus(pageId, gameData);

	/* Check if page hos content (buttons, minigames, text ) */
	const pageHasContent = (
		showMinigames || 
		(pageData.icons && pageData.icons.length > 0) ||
		(pageSlides && pageSlides.length > 0) || 
		(pageText && pageText.length > 0) ||
		(pageData.illustrations && pageData.illustrations.length > 0)
	);

	/**
	 * Toggle minigames status
	 */
	const [isTogglingMinigamesStatus, setIsTogglingMinigamesStatus] = useState(false);
	const handleToggleMinigamesStatus = () => {
		setIsTogglingMinigamesStatus(true);
		const newStatus = (minigamesStatus === 'open' ? 'closed' : 'open');
		toggleMinigameStatus(pageData.id, newStatus).then((response) => {
			setIsTogglingMinigamesStatus(false);
		});
	};

	/* Minigame popup */
	const [minigameId, setMinigameId] = useState(null);
	const closeMinigamePopup = () => {setMinigameId(null);};


	/* Auto-close minigame popup if minigames are closed */
	useEffect(() => {
		if (role === 'group' && minigameId) {
			let status = getMinigamesStatus(pageId, gameData);
			if (status !== 'open') setMinigameId(null);
		}
		
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [gameData]);

	/* Slide popup */
	const [slideIndex, setSlideIndex] = useState(null);
	const closeSlidePopup = () => {setSlideIndex(null);};

	return (
		<div className={'GamePage ' + moduleData.colorId}>
			{/* Logo */}
			<div className="GamePage-logo"><Logo page="game" colorId={moduleData.colorId} /></div>

			{/* Game code */}
			{role === 'facilitator' && <GameCode page="game-page" gameData={gameData} />}

			<div className="GamePage-wrap">
				{/* Background swirl */}
				<div className={'GamePage-backgroundSwirl ' + moduleData.colorId}><BackgroundSwirl /></div>

				{/* Game menu */}
				<div className="GamePage-menu">
					<Menu 
						role={role}
						pageId={pageId}
						moduleData={moduleData}
						handleGoToPage={handleGoToPage}
					/>
				</div>

				{/* Log out btn (group only) */}
				{role === 'group' && <div className="GamePage-logoutBtn">
					<Button classes={['red', 'logout']} text={loginUiTexts.logout} onClick={handleLogout} />
				</div>}

				{/* Lobby btn (facilitator only) */}
				{role === 'facilitator' && <div className="GamePage-lobbyBtn">
					<Button 
						classes={['red', 'lobby']} 
						text={facilitatorUiTexts.home}
						onClick={() => {handleGoToPage();}}
					/>
				</div>}
				
				{/* Game page */}
				<div className="GamePage-page">
					{/* Instructions */}
					{pageInstructions && <Instructions instructionsData={pageInstructions} />}		


					{/* Page content */}
					{pageHasContent && <div className="GamePage-content">
						{/* Icons */}
						{(pageData.icons && pageData.icons.length > 0) && 
							<div className={'GamePage-icons icons-' + pageData.icons.length}>
								{pageData.icons.map((iconId) => {return <Icon key={iconId} iconId={iconId} />;})}
							</div>
						}
						
						{(
							pageText || 
							showMinigamesToggleBtn || 
							showMinigames || 
							(pageData.illustrations && pageData.illustrations.length > 0)
						) && <div className="GamePage-text">
							{/* Page text */}
							{renderMarkdown(pageText)}

							{pageData.illustrations && pageData.illustrations.map((illustrationId) => {
								return (
									<div key={illustrationId} className={'GamePage-illustration ' + illustrationId} />
								);
							})}
							
							
							{showMinigames && <div className="GamePage-minigames">
								{/* Toggle minigames */}
								{showMinigamesToggleBtn && <Button 
									isLoading={isTogglingMinigamesStatus}
									classes={['red']} 
									text={(minigamesStatus === 'open' 
										? gameUiTexts.closeMinigame : gameUiTexts.openMinigame)}
									onClick={() => {handleToggleMinigamesStatus();}}
								/>}
								{/* Play minigames */}
								{pageData.minigames.map((minigame) => {
									const btnText = (minigame.playBtnText 
										? (role === 'group' ? minigame.playBtnText : minigame.viewBtnText)
										: gameUiTexts.start
									);
									if (minigame.id === 'm7-thought-training') {
										return null;
									}
									
									return (
										<Button 
											key={minigame.id}
											isDisabled={(role === 'group' && minigamesStatus !== 'open')}
											classes={['red']} 
											text={btnText} 
											onClick={() => {setMinigameId(minigame.id);}} 
										/>	
									);
								})}
							</div>}
						</div>}

						<div style={{clear: 'both'}} />


						{/* Page slides */}
						{pageSlides &&
							<div className={'GamePage-buttons' 
								+ (pageSlides.length === 1 ? ' single' : '')
								+ (pageSlides.length > 3 ? ' many' : '')
							+ ((moduleData.id === 'module-7' && pageData.id === 'm7-thinking') ? ' cases' : '')
							+ ((role === 'group' && minigamesStatus === 'open') ? ' open' : '')
							+ ((role === 'group' && minigamesStatus !== 'open') ? ' groupView' : '')}
							>
								{pageSlides.map((button, index) => {
									const btnClasses = ['red-medium', button.type];
									return (
										<Button 
											key={button.id}
											classes={btnClasses} 
											text={button.text} 
											onClick={() => {setSlideIndex(index);}} 
										/>	
									);
								})}
							</div>
						}
					</div>}



					

					{/* Slide popup */}
					{slideIndex !== null &&
						<PopupSlide 
							initialSlideIndex={slideIndex}
							pageData={pageData} 
							togglePopup={closeSlidePopup}
						/>
					}
				
					{/* Minigame popup */}
					{(minigameId && (role === 'facilitator' || minigamesStatus === 'open')) &&
						<PopupMinigame 
							role={role}
							minigameId={minigameId}
							pageData={pageData} 
							gameData={gameData}
							updateMinigame={updateMinigame}
							togglePopup={closeMinigamePopup}
						/>
					}


				</div>

				{/* Next page btn */}
				{role === 'facilitator' && <NextStepButton 
					moduleId={moduleId}
					pageId={pageId}
					gameData={gameData}
					handleGoToPage={handleGoToPage}
					toggleMinigameStatus={toggleMinigameStatus}
				/>}
			</div>
		</div>
	);
};

GamePage.propTypes = {
	role: PropTypes.string.isRequired,
	moduleId: PropTypes.string.isRequired,
	pageId: PropTypes.string.isRequired,
	gameData: PropTypes.object.isRequired,
	toggleMinigameStatus: PropTypes.func,
	updateMinigame: PropTypes.func,
	handleGoToPage: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired,
};

export default GamePage;
