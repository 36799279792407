let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
if (!env) {
	console.error('ENV: ', env);
	env = 'development';
}

let usersDbName = 'users';
let gamesDbName = 'games';
let allowedUsersDbName = 'allowed-users';
let gameUrl = 'localhost:3000';
let apiURL = 'http://localhost:8080/api/';
if (env === 'test' || env === 'demo') {
	gameUrl = 'cgl-mentalisering-test.web.app';
	apiURL = 'https://cgl-mentalisering-test.web.app/api/';
	if (env === 'demo') {
		gameUrl = 'cgl-mentalisering-demo.web.app';
		apiURL = 'https://cgl-mentalisering-demo.web.app/api/';
		gamesDbName = 'games-demo';
	}
}
if (env === 'production') {
	gameUrl = 'cgl-mentalisering-production.web.app';
	apiURL = 'https://cgl-mentalisering-production.web.app/api/';
}

const appConfig = {
	env: env,
	apiURL: apiURL,
	gameUrl: gameUrl,
	usersDbName: usersDbName,
	gamesDbName: gamesDbName,
	allowedUsersDbName: allowedUsersDbName,
	groupNameMinLength: 3,
	groupNameMaxLength: 12,
	gameTitleMaxLength: 25,
	cookiesAcceptedCookieName: 'mentalisering_cookies_accepted'
};

export default appConfig;