const modulePages = [
	{
		id: 'm8-intro',
		isFacilitatorOnly: true,
		title: 'Intro til modulet',
		facilitatorInstructions: `Følg guiden. 
			<br /><br />Fortæl eleverne, hvad der skal ske på modul 8.`,
		facilitatorText: null,
		groupText: null,
		icons: [],
		slides: [
			{
				id: 'the-bad-circle',
				type: 'image', 
				text: 'Den svære cirkel'
			},
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskassen'
			}
		]	
	},
	{
		id: 'm8-problem-solving',
		isFacilitatorOnly: true,
		title: 'Feedback og problemløsning',
		facilitatorInstructions: `Følg guiden trin for trin. 
			<br /><br />Eleverne fortæller, hvordan det er gået med basalplanen, og der gennemføres en fælles problemløsning.
			<br /><br />Husk, at du selv er rollemodel.<br /> </br>
			<strong>Gode råd</strong>
			<ul><li>Hvis en elev har gennemført sin basalplan, anerkender du eleven.</li>
			<li>Hvis en elev har mødt forhindringer og har justeret sin basalplan, anerkender du elevens indsats og understreger, at det er en god strategi at justere sin plan. </li>
			<li>Hvis en elev har oplevet udfordringer og gennemført basalplanen delvist, bemærker du det positive i at være kommet i gang. Lav evt. en fælles problemløsning med udgangspunkt i elevens udfordring, medmindre eleven selv har idéer til løsning af udfordringen.</li>
			<li>Hvis en elev har oplevet udfordringer og ikke har gennemført sin basalplan, kan du starte med at spørge om eleven selv har løsningsforslag. Hvis ikke kan du foreslå en fælles problemløsning i klassen.</li></ul>
			<br/>
			<p>Vær opmærksom på, så vidt muligt at gøre problemet til ”tavlens problem”, så løsninger gives til tavlen og ikke den enkelte elev. Vælg så vidt muligt udfordringer, som kan bredes ud, så flere elever kan spejle sig i dem.</p>`,
		facilitatorText: `<strong>Hvad gik din basalplan ud på?</strong><br /><br />
			<strong>Hvordan er det gået med din basalplan?</strong><br/><br/>
			<strong>Mulighed for problemløsning.</strong><br /><br /><br />`,
		icons: ['plan-of-action', 'problem-solving'],
		slides: [
			{
				id: 'plan-of-action',
				type: 'image',
				text: 'Basalplansskema'
			},
			{
				id: 'problem-solving',
				type: 'image',
				text: 'Problemløsningsskema'
			},
			{
				id: 'the-bad-circle',
				type: 'image', 
				text: 'Den svære cirkel'
			},
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskasse'
			},
		]	
	},
	{
		id: 'm8-gratefulness',
		isFacilitatorOnly: true,
		title: 'Taknemmelighed',
		facilitatorInstructions: `Følg guiden`,
		facilitatorText: `Taknemmelighedsliste.`,
		groupText: null,
		icons: ['thoughts-helping'],
		slides: [
			{
				id: 'idea-round',
				type: 'image',
				text: 'Idérunde'
			},
			{
				id: 'the-bad-circle',
				type: 'image', 
				text: 'Den svære cirkel'
			},
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskasse'
			},
	]
	},
	{
		id: 'm8-active-break',
		isFacilitatorOnly: true,
		title: 'Aktiv pause',
		menuTitle: 'Aktiv pause',
		facilitatorInstructions: `Her kan du lægge en aktiv pause ind. Vælg én fra appendiks bagerst i Guide til facilitering eller find din egen. `,
		facilitatorText: null,
		groupText: null,
		icons: [],
		slides: [
			{
				id: 'the-bad-circle',
				type: 'image', 
				text: 'Den svære cirkel'
			},
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskassen'
			}
		],
		minigames: []
	},
	{
		id: 'm8-conflicts',
		isFacilitatorOnly: false,
		title: 'Konflikter',
		facilitatorInstructions: `Følg guiden.
			<br /><br />Eleverne løser opgaven i www.basalsammen.dk.`,
		facilitatorText: `<br /><br />Gå til <strong>www.basalsammen.dk.</strong>
		<br /><br />Skriv kode i elevlogin.
		<br /><br /><span><%GAMECODE%></span>`,
		groupText: `Se, hvad jeres valg betyder for udviklingen af konflikten.`,
		icons: ['communication'],
		slides: [
			{
				id: 'rabbit-duck',
				type: 'image',
				text: 'Tegning'
			},
			{
				id: 'the-bad-circle',
				type: 'image', 
				text: 'Den svære cirkel'
			},
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskassen'
			}
		],
		minigames: [
			require('data/minigames/m8-conflicts').minigameData,
		]
	},
	{
		id: 'm8-plan-of-action',
		isFacilitatorOnly: true,
		title: 'Langsigtet basalplan',
		facilitatorInstructions: `Følg guiden trin for trin.
			<br /><br />Eleverne udarbejder hver en langsigtet basalplan. 
			<br /><br />Husk, at du selv er rollemodel og selv skal lave en langsigtet basalplan.`,
		facilitatorText: `<p><strong>Opgave:</strong>
			<br />Gå sammen to og to, og hjælp hinanden med at lave en langsigtet basalplan.`,
		groupText: null,
		icons: ['plan-of-action'],
		illustrations: ['long-term-plan-of-action'],
		slides: [
			{
				id: 'longterm-plan-of-action',
				type: 'image',
				text: 'Langsigtet basalplan'
			},
			{
				id: 'problem-solving',
				type: 'image',
				text: 'Problemløsningsskema'
			},
			{
				id: 'the-bad-circle',
				type: 'image', 
				text: 'Den svære cirkel'
			},
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskassen'
			},
			{
				id: 'exsample-1',
				type: 'image',
				text: 'Eksempel 1'
			},
			{
				id: 'exsample-2',
				type: 'image',
				text: 'Eksempel 2'
			}
		]
	},
	{
		id: 'm8-finish',
		isFacilitatorOnly: true,
		title: 'Afrunding',
		menuTitle: 'Afrunding',
		facilitatorInstructions: `Følg guiden.`,
		facilitatorText: `<strong>Hvordan kan man gøre dig glad?</strong> <br/> <br/>
		<strong>Hvornår gør du noget meningsfuldt? Fx hvornår hjælper du dine klassekammerater eller din familie?</strong> <br/> <br/>
		<strong>Hvordan kan du konkret bruge det, du har lært fremover?</strong>`,
		groupText: null,
		icons: ['toolbox'],
		slides: [
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskasse'
			},
			{
				id: 'the-bad-circle',
				type: 'image', 
				text: 'Den svære cirkel'
			},
		],
	},
];


export {
	modulePages
};