import React from 'react';
import PropTypes from 'prop-types';
import {ReactComponent as IconSwirl} from 'assets/images/icon-swirl.svg';
import './menu.scss';

const Menu = ({role, pageId, moduleData, handleGoToPage}) => {
	const availablePages = moduleData.pages.filter((p) => {
		return (role === 'facilitator' || p.isFacilitatorOnly !== true);
	});
	return (
		<div className="Menu">
			<div className={'Menu-title ' + moduleData.colorId}>
				<span>{moduleData.title}</span>
				<IconSwirl />
			</div>			
			{availablePages.map((page) => {
				const menuText = (page.menuTitle ? page.menuTitle : page.title);
				return (
					<div 
						key={page.id}
						className={'Menu-item' + (pageId === page.id ? ' selected' : '')
							+ (menuText.length > 24 ? ' big' : '')}
						onClick={() => {handleGoToPage(page.id);}}
					>
						<span>{menuText}</span>
					</div>
				);
			})}
		</div>

	);
};

Menu.propTypes = {
	role: PropTypes.string.isRequired,
	pageId: PropTypes.string.isRequired,
	moduleData: PropTypes.object.isRequired,
	handleGoToPage: PropTypes.func.isRequired
};

export default Menu;
