import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {getMinigamesStatus} from 'helpers/game-helper';
import {modulesData} from 'data/modules-data';
import {gameUiTexts} from 'data/ui-texts';
import Button from 'components/ui/button/button';
import './next-step-button.scss';

const NextStepButton = ({moduleId, pageId, gameData, handleGoToPage, toggleMinigameStatus}) => {

	/* Module and page */
	const moduleData = modulesData.find((m) => {return m.id === moduleId;});
	const pageData = moduleData.pages.find((page) => {return page.id === pageId;});

	/* Loading state */
	const [isLoading, setIsLoading] = useState(false);

	/**
	 * Toggle minigames status
	 */
	 const handleToggleMinigamesStatus = (minigamesStatus) => {
		 if (isLoading) return;

		 setIsLoading(true);
		 const newStatus = (minigamesStatus === 'open' ? 'closed' : 'open');
		 toggleMinigameStatus(pageData.id, newStatus).then((response) => {
			 setIsLoading(false);
		 });
	 };


	/**
	 * Finish module
	 */
	const handleFinishModule = () => {
		handleGoToPage();
	};


	let nextBtnText = null;
	let nextBtnAction = null;
	let nextBtnParameters = [];
	if (
		pageData.minigames &&
		pageData.minigames.length > 0 &&
		getMinigamesStatus(pageId, gameData) !== 'closed'
	) {
		/* Open or close minigames */
		const minigamesStatus = getMinigamesStatus(pageId, gameData);
		nextBtnText = (minigamesStatus === 'open' 
			? gameUiTexts.closeMinigame : gameUiTexts.openMinigame);
		nextBtnAction = handleToggleMinigamesStatus;
		nextBtnParameters = [minigamesStatus];
	} else {
		/* Page is completed, go to next page or finish module */
		const pageIndex = moduleData.pages.findIndex((p) => {return p.id === pageId;});
		if (pageIndex >= 0 && pageIndex + 1 < moduleData.pages.length) {
			/* Go to next page in module */
			nextBtnText = gameUiTexts.nextStep;
			nextBtnAction = handleGoToPage;
			nextBtnParameters = [moduleData.pages[pageIndex + 1].id];
		} else {
			/* Finish module */
			nextBtnText = gameUiTexts.finishModule;
			nextBtnAction = handleFinishModule;
		}
	}

	return (
		<div className="NextStepButton">
			{(nextBtnText && nextBtnAction) &&
			<Button 
				isLoading={isLoading}
				classes={['red', 'uppercase']} 
				text={nextBtnText} 
				onClick={() => {nextBtnAction(...nextBtnParameters);}}
			/>}
		</div>
	);
};


NextStepButton.propTypes = {
	moduleId: PropTypes.string.isRequired,
	pageId: PropTypes.string.isRequired,
	gameData: PropTypes.object.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	toggleMinigameStatus: PropTypes.func.isRequired
};


export default NextStepButton;