import React, {Component} from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import apiHelper from 'helpers/api-helper';
import {errorUiTexts} from 'data/ui-texts';
import appConfig from 'config/app.config';
import LoginGroup from './login-group';

class LoginGroupController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			code: null,
			gameId: null,
			feedback: null
		};
	}

	/**
	 * Update input field
	 * @param {obj} event 
	 */
	handleInput = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
			feedback: null
		});
	};


	/**
	 * Find the game with the given code, return error if not found
	 * @param {obj} event 
	 */
	handleFindGame = (event = null) => {
		if (event) event.preventDefault();
		const code = this.state.code;
		if (!code || code.length === 0) {
			this.setState({feedback: errorUiTexts.emptyFields});
			return;
		}

		const lowercaseCode = code.toLowerCase();
		this.setState({isLoading: true}, () => {
			const db = firebase.firestore();
			db.collection(appConfig.gamesDbName).doc(lowercaseCode).get().then((doc) => {
				if (doc.exists) {
					this.handleGetLoginToken(lowercaseCode);
				} else {
					this.setState({isLoading: false, feedback: errorUiTexts.invalidGameCode});
				}
			}).catch((error) => {
				console.error(error);
				this.setState({isLoading: false, feedback: errorUiTexts.unknownError});
			});	
		});
	};


	/**
	 * Get a login token.
	 * Call firebase auth to sign in with that token.
	 * @param {number} groupId 
	 */
	handleGetLoginToken = (gameId) => {
		this.setState({isLoading: true, gameId: gameId}, () => {
			apiHelper('group/join-game', {gameId}).then(
				(response)=>{
					if (response.status === 'success' && response.token) {
						this.loginWithToken(response.token);
					} else {
						console.error(response);
						this.setState({
							isLoading: false,
							feedback: errorUiTexts.unknownError
						});
					}
				},
				(rejection) => {
					console.error('rejection: ', rejection);
					this.setState({
						isLoading: false,
						feedback: errorUiTexts.unknownError
					});
				}
			);
		});
	};


	/**
	 * Login with token
	 * @param {string} token 
	 */
	loginWithToken = (token) => {
		firebase.auth().signInWithCustomToken(token)
			.then(() => {
				this.setState({isLoading: false});
			// All okay, should trigger the authStateChanged in LoginController
			})
			.catch((error) => {
				console.error('sign in error', error);
				this.setState({
					feedback: errorUiTexts.unknownError,
					isLoading: false,
				});
			});
	};

	render = () => {
		return (
			<LoginGroup
				isLoading={this.state.isLoading}
				showNewGroupPopup={this.state.showNewGroupPopup}
				code={this.state.code}
				groups={this.state.groups}
				groupId={this.state.groupId}
				groupName={this.state.groupName}
				feedback={this.state.feedback}
				handleInput={this.handleInput}
				handleFindGame={this.handleFindGame}
				handleGetLoginToken={this.handleGetLoginToken}
				toggleCreateNewGroupPopup={this.toggleCreateNewGroupPopup}
			/>
		);
	};
}

export default LoginGroupController;