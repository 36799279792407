/**
 * Gets the orientation of the screen 
 * @returns {string} orientation
 */
export function getOrientation() {
	const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
	const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
	const orientation = (width > height ? 'landscape' : 'portrait');
	return orientation;
}

/**
 * Smooth scroll
 * @param {object} element 
 * @param {number} endPosition 
 * @param {number} duration 
 * @param {function} onDone 
 */
export function scrollTo(element, endPosition, duration, onDone) {
	const startPosition = element.scrollTop;
	const totalScrollDistance = endPosition - startPosition;
	const startTime = performance.now();
	let currentTime = null;
	let elapsedTime = null;
	let elapsesdTimeFraction = null;

	function animateScroll() {
		currentTime = performance.now();
		elapsedTime = (currentTime - startTime) / 1000;
		elapsesdTimeFraction = (elapsedTime / duration);

		element.scrollTop = startPosition + totalScrollDistance * easeInOutQuad(elapsesdTimeFraction);

		if (elapsesdTimeFraction < 1) {
			window.requestAnimationFrame(animateScroll);
		} else {
			onDone && onDone();
		}
	};

	animateScroll();
}

function easeInOutQuad(t) {
	return 1 - (1 - t) * (1 - t);
	// return t;
	// return t < .5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
};