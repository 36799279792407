const modulePages = [
	{
		id: 'm3-intro',
		isFacilitatorOnly: true,
		title: 'Intro til modulet',
		facilitatorInstructions: `Følg guiden. 
			<br /><br />Fortæl eleverne, hvad der skal ske på modul 3.`,
		facilitatorText: null,
		icons: [],
		slides: [
			{
				id: 'the-bad-circle',
				type: 'image', 
				text: 'Den svære cirkel'
			},
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskassen'
			}
		]	
	},
	{
		id: 'm3-energy',
		isFacilitatorOnly: true,
		title: 'Energi, pauser og overskud',
		facilitatorInstructions: `Følg guiden. Eleverne arbejder to og to.`,
		facilitatorText: `Hvad kan give os energi og overskud?
			<br /><br /><strong>Opgave:</strong>
			<br /><strong>Hvordan får vi gjort mere af det, der gør os godt?</strong>
			<br />Lav en problemløsning.
			<ol><li>Skriv ned, hvad der lader dig op og giver dig energi, men som du ikke får gjort.</li>
			<li>Skriv derefter ned, hvad der forhindrer dig i at gøre det, der lader dig op.</li>
			<li>I par hjælper I hinanden med idéer til at løse det, der forhindrer jer. Brug trin-for-trin-problemløsning.</li>
			<li>Vælg én løsning at afprøve.</li></ol>`,
		groupText: null,
		icons: ['problem-solving', 'calm'],
		slides: [
			{
				id: 'the-bad-circle',
				type: 'image',
				text: 'Den svære cirkel'
			},
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskassen'
			},
			{
				id: 'problem-solving',
				type: 'image',
				text: 'Problemløsningsskema'
			},
			{
				id: 'breaks',
				type: 'image',
				text: 'Om pauser'
			}
		]
	},
	{
		id: 'm3-thought-traning',
		isFacilitatorOnly: false,
		title: 'Tanketræning: Tankefælder',
		menuTitle: 'Tanketræning: Tankefælder',
		facilitatorInstructions: `Følg guiden. </br></br>
		Tal med eleverne om tankefælder.</br></br>
		Lad eleverne sidde sammen i mindre grupper eller to og to om en computer. </br></br>
		Bed dem logge ind på www.basalsammen.dk og giv dem den kode, der står på din skærm. </br></br>
		Eleverne skal lave en øvelse, hvor de skal matche en tankefælde med en sætning, som kan passe til den enkelte tankefælde. Efter øvelsen samler I op i plenum.`,
		facilitatorText: `Gå til <strong>www.basalsammen.dk.</strong> </br></br>
		Skriv koden i elevlogin. Indsæt kode </br></br>`,
		groupInstructions: `Løs opgaven i grupper.
			<br /><br /><strong>Opgave 1:</strong><br />Tal i gruppen om, hvilket udsagn der passer til hvilken tankefælde. Vælg et udsagn, og træk det hen til den tankefælde, I mener den passer til. Tryk OK, når I mener, I har placeret dem rigtigt.`,
		groupText: null,
		icons: ['online-teaching'],
		slides: [
			{
				id: 'the-bad-circle',
				type: 'image', 
				text: 'Den svære cirkel'
			},
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskassen'
			},
			{
				id: 'thought-trap',
				type: 'image',
				text: 'Tankefælder'
			}
		],
		minigames: [
			require('data/minigames/m3-thought-traps').minigameData
		]
	},
	{
		id: 'm3-active-break',
		isFacilitatorOnly: true,
		title: 'Aktiv pause',
		menuTitle: 'Aktiv pause',
		facilitatorInstructions: `Her kan du lægge en aktiv pause ind. Vælg én fra appendiks bagerst i Guide til facilitering eller find din egen. `,
		facilitatorText: null,
		groupText: null,
		icons: [],
		slides: [
			{
				id: 'the-bad-circle',
				type: 'image', 
				text: 'Den svære cirkel'
			},
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskassen'
			}
		],
		minigames: []
	},
	{
		id: 'm3-basal-plan',
		isFacilitatorOnly: true,
		title: 'Basalplan',
		menuTitle: 'Basalplan',
		facilitatorInstructions: `Følg guiden trin for trin. Eleverne udarbejder hver en basalplan. Husk, at du selv er rollemodel og også selv skal lave en basalplan.`,
		facilitatorText: `<strong>Opgave:</strong> <br/>
		Gå sammen to og to, og hjælp hinanden med at lave en basalplan.`,
		groupText: null,
		icons: ['plan-of-action'],
		slides: [
			{
				id: 'plan-of-action',
				type: 'image',
				text: 'Basalplansskema'
			},
			{
				id: 'problem-solving',
				type: 'image',
				text: 'Problemløsningsskema'
			},
			{
				id: 'the-bad-circle',
				type: 'image', 
				text: 'Den svære cirkel'
			},
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskassen'
			}
		],
		minigames: []
	},
	{
		id: 'm3-finish',
		isFacilitatorOnly: true,
		title: 'Afrunding',
		menuTitle: 'Afrunding',
		facilitatorInstructions: `Følg guiden.`,
		facilitatorText: `<strong>Hvilket værktøj, hvilken øvelse eller aktivitet fra dagens modul vil du fokusere på i den næste uges tid?</strong><br/><br/>
		<strong>Hvordan kan man gøre dig glad?</strong><br/><br/>
		<strong>Hvad har givet dig noget at tænke over?</strong>`,
		groupText: null,
		icons: ['toolbox'],
		slides: [
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskasse'
			},
			{
				id: 'the-bad-circle',
				type: 'image', 
				text: 'Den svære cirkel'
			},
		],
	},
];


export {
	modulePages
};