import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {gameUiTexts} from 'data/ui-texts';
import {shuffleArray} from 'helpers/array-helper';
import Button from 'components/ui/button/button';
import Popup from 'components/ui/popup/popup';
import SortDndContainer from './sort-dnd-container';
import SortDndItem from './sort-dnd-item';
import SortDndPreview from './sort-dnd-preview';
import './sort.scss';

const Sort = ({partIndex, gameStatus, minigameData, setGameStatus}) => {
	const [showIntroPopup, setShowIntroPopup] = useState(true);
	const [showInfoPopup, setShowInfoPopup] = useState(false);
	const [gameRound, setGameRound] = useState(1);
	const [items, setItems] = useState([]);
	const [categoryContainers, setCategoryContainers] = useState({});
	const [feedback, setFeedback] = useState(null);


	/**
	 * Start game
	 * @param {bool} isFirstGame 
	 */
	const handleStartGamePart = (isFirstGame = true) => {
		if (
			minigameData && 
			minigameData.parts && minigameData.parts.length > partIndex &&
			minigameData.parts[partIndex].categories && minigameData.parts[partIndex].categories.length > 0 &&
			minigameData.parts[partIndex].items && minigameData.parts[partIndex].items.length > 0
		) {
			/* Set to round 1 */
			setGameRound(1);

			/* Sort items  */
			let initialItems = minigameData.parts[partIndex].items.map((item) => {return {itemId: item.id};});
			initialItems = shuffleArray(initialItems);
			setItems(initialItems);

			/* Prepare category containers */
			let initialCategoryContainers = {};
			minigameData.parts[partIndex].categories.forEach((category) => {
				initialCategoryContainers[category.id] = [];
			});
			setCategoryContainers(initialCategoryContainers);

			/* Update game status */
			setGameStatus('playing');

			/* If first game, show intro */
			if (isFirstGame) setShowIntroPopup(true);
			
		} else {
			console.error('No sort data');
		}
	};

	/**
	 * Find item
	 * @param {number} itemId 
	 * @returns 
	 */
	const handleFindItem = (itemId) => {
		let categoryId = null;
		minigameData.parts[partIndex].categories.forEach((category) => {
			if (categoryId) return;
			if (
				categoryContainers[category.id] &&
				categoryContainers[category.id].indexOf(itemId) >= 0
			) categoryId = category.id;
		});
		return categoryId;
	};

	/**
	 * Move item to new container
	 * @param {number} itemId 
	 * @param {string} containerId 
	 */
	const handleMoveItem = (itemId, fromCategoryId, toCategoryId) => {
		let newCategoryContainers = JSON.parse(JSON.stringify(categoryContainers));

		/* Remove item from category */
		if (fromCategoryId) {			
			const itemIndex = newCategoryContainers[fromCategoryId].indexOf(itemId);
			newCategoryContainers[fromCategoryId].splice(itemIndex, 1);
		}

		/* Add item to category */
		newCategoryContainers[toCategoryId].push(itemId);

		setCategoryContainers(newCategoryContainers);
	};

	/**
	 * Confirm sorted items
	 * @returns 
	 */
	const handleConfirmSort = () => {
		/* Cannot confirm if some items have not been put in a category */
		if (items.some((i) => {return i.containerId === null;})) return;

		let errors = 0;
		items.forEach((item) => {
			const itemData = minigameData.parts[partIndex].items.find((itemData) => {
				return itemData.id === item.itemId;
			});
			const placedInCategoryId = handleFindItem(item.itemId);
			if (placedInCategoryId !== itemData.correctCategoryId) errors += 1;
		});
		if (errors === 0) {
			/* All correct */
			setFeedback(gameUiTexts.sortFeedbackPopup.text);
			setGameStatus('done');
		} else {
			const correctPlacements = items.length - errors;
			let newFeedback = gameUiTexts.sortFeedbackPopup.text2;
			newFeedback = newFeedback.replace(
				/%correctPlacements%/g, 
				correctPlacements + ' ' + (correctPlacements === 1 
					? gameUiTexts.sortFeedbackPopup.correctPlacement
					: gameUiTexts.sortFeedbackPopup.correctPlacements)
			);
			setFeedback(newFeedback);
			setGameRound(gameRound + 1);
		}
	};

	/**
	 * Handle scroll
	 * @param {string} direction 
	 */
	const handleScroll = (direction) => {
		const itemsRef = document.getElementById('items');
		if (itemsRef) {
			const scrollDiff = itemsRef.scrollWidth - itemsRef.clientWidth;
			if (direction === 'left' && itemsRef.scrollLeft > 0) {
				itemsRef.scrollLeft = Math.max(0, itemsRef.scrollLeft - 300);
			}
			if (direction === 'right' && itemsRef.scrollLeft < scrollDiff) {
				itemsRef.scrollLeft = Math.min(scrollDiff, itemsRef.scrollLeft + 300);
			}
		}
		
	};

	/**
	 * Component mounted / will unmount
	 */
	useEffect(() => {
		/* Component mounted */
		handleStartGamePart(true);
	
		return () => {
			/* Component will unmount */
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		handleStartGamePart();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [partIndex]);


	// const itemsRef = document.getElementById('items');
	// const scrollDiff = itemsRef.scrollWidth - itemsRef.clientWidth;
	const canScrollLeft = true; // (itemsRef.scrollLeft > 0);
	const canScrollRight = true; // (itemsRef.scrollLeft < scrollDiff);

	return (
		<div className="Sort">		
			{/* Categories */}
			<div className="Sort-categories">
				{minigameData.parts[partIndex].categories.map((category) => {
					return (
						<div key={category.id} className={'Sort-category ' + category.id}>
							<div className="Sort-categoryTitle">{category.title}</div>
							<SortDndContainer
								layout={minigameData.id}
								containerId={category.id}
								handleFindItem={handleFindItem}
								handleMoveItem={handleMoveItem}
							>
								{
									categoryContainers[category.id] && 
									categoryContainers[category.id].length > 0 &&
									categoryContainers[category.id].map((itemId) => {
										const itemData = minigameData.parts[partIndex].items.find((itemData) => {
											return itemData.id === itemId;
										});
										if (!itemData) return null;

										let isDraggable = true;
										let classes = [];
										if (gameStatus === 'done') {
											isDraggable = false;
											classes.push('completed');
										}
										if (gameRound >= 3) {
											const isPlacedCorrect = 
															(itemData.correctCategoryId === category.id);
											if (isPlacedCorrect) {
												classes.push('animateCorrect');
											} else {
												classes.push('animateWrong');
											}
										}	
										return (
											<SortDndItem 
												key={itemData.id} 
												isDraggable={isDraggable} 
												classes={classes}
												itemId={itemData.id}
												categoryId={category.id}
											>
												<span>{itemData.title}</span>
											</SortDndItem>
										);
									})
								}
							</SortDndContainer>
						</div>
					);
				})}
			</div>

			{/* Items */}
			{gameStatus !== 'done' && <div className="Sort-panel">
				<div 
					className={'Sort-scrollLeftBtn' + (canScrollLeft ? ' active' : '')} 
					onClick={() => {handleScroll('left');}} 
				/>
				<div 
					className={'Sort-scrollRightBtn' + (canScrollRight ? ' active' : '')}
					onClick={() => {handleScroll('right');}} 
				/>
				<div className="Sort-itemsWrap">
					<div id="items" className="Sort-items">
						{items.map((item) => {
							if (handleFindItem(item.itemId)) return null;
						
							const itemData = minigameData.parts[partIndex].items.find((itemData) => {
								return itemData.id === item.itemId;
							});
							if (!itemData) return null;
							const isDraggable = (gameStatus === 'playing');
							return (
								<SortDndItem 
									key={item.itemId} 
									isDraggable={isDraggable} 
									itemId={item.itemId}
								>
									<span>{itemData.title}</span>
								</SortDndItem>
							);
						})}
					</div>
				</div>
				
			</div>}
			<SortDndPreview itemsData={minigameData.parts[partIndex].items} />

			{/* Confirm button */}
			{gameStatus === 'playing' && 
					<div className="Sort-okBtn">
						<Button classes={['red']} text="OK" onClick={() => {handleConfirmSort();}} />
					</div>
			}

			{/* Info popup button */}
			{minigameData.parts[partIndex].infoPopupText && <div className="Sort-infoBtn">
				<Button classes={['info']} onClick={() => {setShowInfoPopup(true);}} />
			</div>}

			{/* Info popup */}
			{showInfoPopup && 
				<Popup 
					type="intro"
					text={minigameData.parts[partIndex].infoPopupText} 
					buttons={[
						{text: gameUiTexts.ok, action: setShowInfoPopup, parameters: [false]}
					]}
				/>
			}

			{/* Intro popup */}
			{showIntroPopup && 
				<Popup 
					type="intro"
					text={minigameData.parts[partIndex].introPopupText} 
					buttons={[
						{text: gameUiTexts.ok, action: setShowIntroPopup, parameters: [false]}
					]}
				/>
			}

			{/* Feedback popup */}
			{feedback && 
				<Popup 
					type="intro"
					text={feedback} 
					buttons={[
						{text: gameUiTexts.ok, action: setFeedback, parameters: [null]}
					]}
				/>
			}
		</div>
	);
};

Sort.propTypes = {
	partIndex: PropTypes.number.isRequired,
	gameStatus: PropTypes.string.isRequired,
	minigameData: PropTypes.object.isRequired,
	setGameStatus: PropTypes.func.isRequired
};

export default Sort;
