import React from 'react';
import PropTypes from 'prop-types';
import {loginUiTexts} from 'data/ui-texts';
import Button from 'components/ui/button/button';
import './create-user.scss';

const CreateUser = (props) => {
	const {
		isLoading,
		userCreated,
		email,
		password,
		passwordRepeat,
		feedback,
		handleInput,
		handleCreateUser,
		setFacilitatorBoxType
	} = props;

	if (userCreated) {
		return (
			<div className="CreateUser">
				<div className="CreateUser-title"><span>{loginUiTexts.facilitatorCreateUser}</span></div>
				<div className="CreateUser-body">
					<div className="CreateUser-info"><span>{loginUiTexts.newUserCreated}</span></div>
					<div 
						className="CreateUser-resetPasswordBtn"
						onClick={()=>{setFacilitatorBoxType('login');}}
					><span>{loginUiTexts.backToLogin}</span></div>
				</div>
			</div>
		);
	}
	
	return (
		<div className="CreateUser">
			<div className="CreateUser-title"><span>{loginUiTexts.facilitatorCreateUser}</span></div>
			<div className="CreateUser-body">
				<form className="CreateUser-form" onSubmit={handleCreateUser}>
					<input
						name="email"
						type="text"
						placeholder={loginUiTexts.email}
						autoComplete="section-createUser email"
						value={email ? email : ''}
						onChange={(event)=>{handleInput(event);}}
					/>
					<input
						name="password"
						type="password"
						placeholder={loginUiTexts.password}
						autoComplete="section-createUser password"
						value={password ? password : ''}
						onChange={(event)=>{handleInput(event);}}
					/>
					<input
						name="passwordRepeat"
						type="password"
						placeholder={loginUiTexts.repeatPassword}
						autoComplete="section-createUser repeatpassword"
						value={passwordRepeat ? passwordRepeat : ''}
						onChange={(event)=>{handleInput(event);}}
					/>
					<Button
						isLoading={isLoading}
						classes={['login']}
						text={loginUiTexts.create}
						onClick={(event) => {handleCreateUser(event);}}
					/>
					<p className="CreateUser-errorMessage">{feedback}</p>
				</form>
				<div 
					className="CreateUser-resetPasswordBtn"
					onClick={()=>{setFacilitatorBoxType('login');}}
				><span>{loginUiTexts.backToLogin}</span></div>
			</div>
		</div>
	);	
};

CreateUser.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	userCreated: PropTypes.bool.isRequired,
	email: PropTypes.string,
	password: PropTypes.string,
	passwordRepeat: PropTypes.string,
	feedback: PropTypes.string,
	handleInput: PropTypes.func.isRequired,
	handleCreateUser: PropTypes.func.isRequired,
	setFacilitatorBoxType: PropTypes.func.isRequired
};

export default CreateUser;