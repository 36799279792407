import React, { Component } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import appConfig from 'config/app.config';
import Loading from 'components/loading/loading';
import Game from 'components/game/game';

class GroupController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			pageId: 'game',
			feedback: null
		};
		this.timeout = null;
		this.unsubscribeGame = null;
		this.unsubscribeGroup = null;
	}

	/**
	 * Component mounted
	 */
	componentDidMount = () => {
		let gameId = this.props.userId;
		this.subscribeToGame(gameId).then((response) => {
			if (response.status === 'ok') {
				this.setState({isLoading: false});
			} else {
				console.error(response);
			}
		});
	};

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		/* Unsubscribe from game / group */
		if (this.unsubscribeGame !== null) this.unsubscribeGame();
		if (this.unsubscribeGroup !== null) this.unsubscribeGroup();

		/* Clear timeout */
		if (this.timeout) clearTimeout(this.timeout);
	}	;

	/**
	 * Subscribe to game
	 * @param {string} gameId
	 */
	subscribeToGame = (gameId) => {
		if (this.unsubscribeGame !== null) this.unsubscribeGame();
		const db = firebase.firestore();
		return new Promise((resolve) => {
			this.unsubscribeGame = db.collection(appConfig.gamesDbName).doc(gameId).onSnapshot((doc) => {
				if (doc.exists) {
					/* Get updated game */
					let data = doc.data();
					data.id = doc.id;
					let prevGameData = this.state.gameData;
					this.setState({ gameData: data }, () => {
						this.checkGameUpdates(prevGameData);
						resolve({ status: 'ok' });
					});
				} else {
					/* No game, probably deleted: log out group */
					this.props.handleLogout();
				}
			},
			(error) => {
				/* Error: game not found */
				console.error('could not get game: ', error);
				resolve({ status: 'error', error: error });
			}
			);
		});
	};

	/**
	 * Check game updates
	 * @param {object} prevGameData
	 */
	checkGameUpdates = (prevGameData) => {
		/* Facilitator opened phase 2 */
		// if (prevGameData && this.state.gameData && prevGameData.phaseId !== this.state.gameData.phaseId) {
		// 	let roundData = roundsData.find((round) => {return round.id === this.state.groupData.roundId;});
		// 	if (roundData && roundData.showIntroPopup === true && this.state.gameData.phaseId === roundData.phaseId) {
		// 		this.setState({showIntroPopup: true});
		// 	}
		// }
	};


	/**
	 * Go to page
	 * @param {string} pageId
	 */
	handleGoToPage = (pageId) => {
		this.setState({pageId});
	};


	/**
	 * Render component
	 */
	render = () => {
		if (this.state.isLoading) {
			return (
				<Loading 
					feedback={this.state.feedback}
					handleLogout={this.props.handleLogout} 
				/>
			);
		};


		return (
			<Game 
				role="group"
				gameData={this.state.gameData}			
				handleLogout={this.props.handleLogout}
			/>
		);
	};
}

GroupController.propTypes = {
	userId: PropTypes.string.isRequired,
	handleLogout: PropTypes.func.isRequired
};

export default GroupController;
