import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {gameUiTexts} from 'data/ui-texts';
import {shuffleArray} from 'helpers/array-helper';
import Button from 'components/ui/button/button';
import Popup from 'components/ui/popup/popup';
import OrganizeDndContainer from './organize-dnd-container';
import OrganizeDndItem from './organize-dnd-item';
import OrganizeDndPreview from './organize-dnd-preview';
import './organize.scss';

const Organize = ({partIndex, gameStatus, minigameData, setGameStatus}) => {
	const [showIntroPopup, setShowIntroPopup] = useState(true);
	const [showInfoPopup, setShowInfoPopup] = useState(false);
	const [items, setItems] = useState([]);
	const [feedback, setFeedback] = useState(null);

	/**
	 * Start game part
	 * @param {bool} isFirstGame 
	 */
	const handleStartGamePart = () => {
		if (
			minigameData && 
			minigameData.parts && minigameData.parts.length > partIndex &&
			minigameData.parts[partIndex].items && minigameData.parts[partIndex].items.length > 0 &&
			minigameData.parts[partIndex].groups && minigameData.parts[partIndex].groups.length > 0
		) {

			/* Shuffle items  */
			const initialItems = shuffleArray(minigameData.parts[partIndex].items.map((item, index) => {
				return {itemId: item.id, containerId: null};
			}));
			setItems(initialItems);
			
			/* Update game status */
			setGameStatus('playing');

			/* If first part, show intro */
			if (partIndex === 0) setShowIntroPopup(true);
			
		} else {
			console.error('No organize data');
		}
	};
	
	/**
	 * Find item
	 * @param {number} itemId 
	 * @returns 
	 */
	 const handleFindItem = (itemId) => {
		const item = items.find((item) => {return item.itemId === itemId;});
		return (item ? item.containerId : null);
	};
	/**
	 * Move item to new container
	 * @param {number} itemId 
	 * @param {string} containerId 
	 */
	const handleMoveItem = (itemId, fromContainerId, toContainerId) => {
		let newItems = JSON.parse(JSON.stringify(items));
		const itemIndex = newItems.findIndex((item) => {return item.itemId === itemId;});

		/* From items to container */
		if (fromContainerId === null && toContainerId !== null) {
		/* If container is occupied, the initial item is sent back to the items area */
			let itemToSwitchIndex = newItems.findIndex((item) => {return item.containerId === toContainerId;});
			if (itemToSwitchIndex > -1) {
				newItems[itemToSwitchIndex].containerId = null;
			}
			newItems[itemIndex].containerId = toContainerId;
		}

		/* From container to container */
		if (fromContainerId !== null && toContainerId !== null && fromContainerId !== toContainerId) {
		/* If container is occupied, make items switch place */
			let itemToSwitchIndex = newItems.findIndex((item) => {return item.containerId === toContainerId;});
			if (itemToSwitchIndex > -1) {
				newItems[itemToSwitchIndex].containerId = fromContainerId;
			}
			newItems[itemIndex].containerId = toContainerId;
		}

		setItems(newItems);
	};

	/**
	 * Check if all items have been placed
	 * @returns {bool} allItemsArePlaced
	 */
	const checkIfallItemsArePlaced = () => {
		const allItemsArePlaced = (!items.some((i) => {
			return i.containerId === null;
		}));
		return allItemsArePlaced;
	};

	/**
	 * Confirm organized items
	 * @returns 
	 */
	const handleConfirm = () => {
		/* Cannot confirm if some items have not been placedr */
		if (!checkIfallItemsArePlaced()) return;
		
		/* Count errors */
		let errors = 0;
		let newItems = items.map((item) => {
			const itemData = minigameData.parts[partIndex].items.find((itemData) => {
				return itemData.id === item.itemId;
			});
			const groupId = (item.containerId ? item.containerId.split('-')[0] : null);
			const isCorrect = (groupId === itemData.correctGroupId);
			if (!isCorrect) errors += 1;
			const isCompleted = item.isCompleted === true || isCorrect;
			return {...item, isCompleted: isCompleted};
		});

		if (errors === 0) {
			/* All correct */
			setItems(newItems);
			setFeedback(gameUiTexts.organizeFeedbackPopup.text);
			setGameStatus('done');
		} else {
			/* 2+ errors, try again */
			const newFeedback = gameUiTexts.organizeFeedbackPopup.text2;
			setFeedback(newFeedback);
			setItems(newItems);
		}
	};

	/**
	 * Handle scroll
	 * @param {string} direction 
	 */
	 const handleScroll = (direction) => {
		const itemsRef = document.getElementById('items');
		if (itemsRef) {
			const scrollDiff = itemsRef.scrollWidth - itemsRef.clientWidth;
			if (direction === 'left' && itemsRef.scrollLeft > 0) {
				itemsRef.scrollLeft = Math.max(0, itemsRef.scrollLeft - 300);
			}
			if (direction === 'right' && itemsRef.scrollLeft < scrollDiff) {
				itemsRef.scrollLeft = Math.min(scrollDiff, itemsRef.scrollLeft + 300);
			}
		}
	};



	useEffect(() => {
		handleStartGamePart();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [partIndex]);


	const canScrollLeft = true; 
	const canScrollRight = true;

	return (
		<div className="Organize">	
			{/* Catch all drag'n'drop container */}	
			<OrganizeDndContainer
				isEmpty={false}
				type="all"
				containerId="all"
				minigameId={minigameData.id}
				layout={minigameData.id}
				handleFindItem={handleFindItem}
				handleMoveItem={handleMoveItem}
			>
				{/* Loop over groups */}
				<div className="Organize-groups">
					{minigameData.parts[partIndex].groups.map((group) => {
						return (
							<div key={group.id} className={'Organize-group ' + group.id}>
								<div className="Organize-groupTitle"><span>{group.title}</span></div>
								<div className="Organize-groupWrap">
									{(group && group.description) && 
									<div className="Organize-groupDescription"><span>{group.description}</span></div>}
									<div className="Organize-groupContainers">
										{[...Array(group.containers)].map((_, index) => {
											const containerId = group.id + '-' + index;
											const item = items.find((item) => {
												return item.containerId === containerId;
											});
											const itemData = (item 
												? minigameData.parts[partIndex].items.find((itemData) => {
													return itemData.id === item.itemId;
												})
												: null
											);
											const isDraggable = (
												itemData && 
											!item.isCompleted === true && 
											gameStatus === 'playing' ? true : false 
											);
											let classes = [];
											if (item && item.isCompleted === true) classes.push('completed');
											return (
												<div 
													key={containerId} 
													className={'Organize-container' + (itemData ? '' : ' empty')}
												>
													<OrganizeDndContainer
														isEmpty={(itemData ? false : true)}
														layout={minigameData.id}
														containerId={containerId}
														handleFindItem={handleFindItem}
														handleMoveItem={handleMoveItem}
													>
														{itemData && (
															<OrganizeDndItem
																key={itemData.id}
																isDraggable={isDraggable}
																layout={minigameData.id}
																classes={classes}
																itemId={itemData.id}
																itemText={itemData.text}
																handleMoveItem={handleMoveItem}
																containerId={containerId}
															/>)
														}
													</OrganizeDndContainer>	
												</div>
											);
										})}
									</div>								
								</div>
							</div>
						);
					})}
				</div>

				{/* Items */}
				<div className="Organize-panel">
					<div 
						className={'Organize-scrollLeftBtn' + (canScrollLeft ? ' active' : '')} 
						onClick={() => {handleScroll('left');}} 
					/>
					<div className="Organize-itemsWrap">
						<div id="items" className="Organize-items">
							{items.filter((i) => {return i.containerId === null;}).map((item) => {
								const itemData = minigameData.parts[partIndex].items.find((itemData) => {
									return itemData.id === item.itemId;
								});
								if (!itemData) return null;
								return (
									<div key={item.itemId} className="Organize-item">
										<OrganizeDndItem
											key={itemData.id}
											isDraggable={true}
											layout={minigameData.id}
											itemId={itemData.id}
											itemText={itemData.text}
											handleMoveItem={handleMoveItem}
											containerId={null}
										/>
									</div>
								);
							})}
						</div>
					</div>
					<div 
						className={'Organize-scrollRightBtn' + (canScrollRight ? ' active' : '')}
						onClick={() => {handleScroll('right');}} 
					/>

				</div>

				{/* Drag'n'drop preview */}
				<OrganizeDndPreview layout={minigameData.id} itemsData={minigameData.parts[partIndex].items} />
			</OrganizeDndContainer>


			{/* Confirm button */}
			{gameStatus === 'playing' && 
					<div className="Organize-okBtn">
						<Button classes={['red']} text="OK" onClick={() => {handleConfirm();}} />
					</div>
			}

			{/* Info popup button */}
			{minigameData.parts[partIndex].infoPopupText && <div className="Organize-infoBtn">
				<Button classes={['info']} onClick={() => {setShowInfoPopup(true);}} />
			</div>}

			{/* Info popup */}
			{showInfoPopup && 
				<Popup 
					type="intro-small"
					text={minigameData.parts[partIndex].infoPopupText} 
					buttons={[
						{text: gameUiTexts.ok, action: setShowInfoPopup, parameters: [false]}
					]}
				/>
			}

			{/* Intro popup */}
			{showIntroPopup && 
				<Popup 
					type="intro-small"
					text={minigameData.parts[partIndex].introPopupText} 
					buttons={[
						{text: gameUiTexts.ok, action: setShowIntroPopup, parameters: [false]}
					]}
				/>
			}

			{/* Feedback popup */}
			{feedback && 
				<Popup 
					type="intro"
					text={feedback} 
					buttons={[
						{text: gameUiTexts.ok, action: setFeedback, parameters: [null]}
					]}
				/>
			}
		</div>
	);
};

Organize.propTypes = {
	partIndex: PropTypes.number.isRequired,
	gameStatus: PropTypes.string.isRequired,
	minigameData: PropTypes.object.isRequired,
	setGameStatus: PropTypes.func.isRequired
};

export default Organize;
