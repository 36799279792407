import React from 'react';
import PropTypes from 'prop-types';
import './loading.scss';

const Loading = ({feedback, handleLogout}) => {


	return (
		<div className="Loading">
			<div className="Loading-logoutBtn" onClick={() => {handleLogout();}} />
			<div className="Loading-content">
				<div className="Loading-circles">
					<div className="Loading-circle" />
					<div className="Loading-circle" />
					<div className="Loading-circle" />
				</div>
				<div className="Loading-text">{feedback}</div>
			</div>
		</div>
	);
};

Loading.propTypes = {
	feedback: PropTypes.string,
	handleLogout: PropTypes.func.isRequired,
};

export default Loading;
