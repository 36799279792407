import React from 'react';
import PropTypes from 'prop-types';
import {gameUiTexts} from 'data/ui-texts';
import AudioPlayer, {RHAP_UI}  from 'react-h5-audio-player';
import {ReactComponent as PlayIcon} from 'assets/images/buttons/button-audio-play.svg';
import {ReactComponent as PauseIcon} from 'assets/images/buttons/button-audio-pause.svg';
// import mp3file from 'assets/audio/firkanten.mp3'; 
import 'react-h5-audio-player/lib/styles.css';
import './audio.scss';


const Audio = ({audioFile, image}) => {
	const src = require('assets/audio/' + audioFile);

	return (
		<div className="Audio">
			<div className="Audio-title"><span>{gameUiTexts.playAudio}</span></div>
			{image && <div className={'Audio-image ' + image} />}
			<div className="Audio-player">
				<AudioPlayer
					preload="auto"
					crossOrigin="anonymous"
					showJumpControls={false}
					layout="horizontal-reverse"
					progressUpdateInterval={1000}
					autoPlay={false}
					src={src}
					customIcons={{
						play: <PlayIcon />,
						pause: <PauseIcon />
					}}				
					customControlsSection={
						[RHAP_UI.MAIN_CONTROLS]
					}
					customProgressBarSection={
						[
							RHAP_UI.PROGRESS_BAR,
							RHAP_UI.CURRENT_TIME,
							RHAP_UI.DURATION,
						]
					}
				/>
			</div>
		</div>
	);
};


Audio.propTypes = {
	audioFile: PropTypes.string.isRequired,
	image: PropTypes.string
};


export default Audio;