const resourcesData = [
	{
		title: 'Se',
		files: [
			{
				title: 'Guide til facilitering',
				file: 'basal-undervisningsvejledning.pdf',
			},
		]
	},
	{
		title: 'Download',
		files: [
			{
				title: 'Følelsesmemory',
				file: '0622_Basal_undervisningsvejledning_A4_memory.pdf'
			},
			{
				title: 'Firkanten',
				file: 'Firkanten.pdf'
			},
			{
				title: 'Elevhæfte',
				file: '0622_Basal_elevhæfte_190X240mm_web.pdf'
			}
		]
	}
];

export {
	resourcesData
};