import React, {useState} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import appConfig from 'config/app.config';
import {facilitatorUiTexts} from 'data/ui-texts';
import Logo from 'components/ui/logo/logo';
import Button from 'components/ui/button/button';
import DeleteUserPopup from './delete-user-popup/delete-user-popup';
import './admin.scss';

const Admin = ({userId, users, allowedUsers, handleGoToPage, deleteUser}) => {
	/* Input: email */
	const [email, setEmail] = useState(null);
	const updateEmail = (event) => {
		setEmail(event.target.value);
	};

	/* Add new allowed user */
	const handleAddAllowedUser = () => {
		if (email.length > 0) {
			const db = firebase.firestore();
			const lowercaseEmail = email.toLowerCase();
			db.collection(appConfig.allowedUsersDbName).add({email:lowercaseEmail}).then(() => {
				setEmail(null);
			});
		}
	};

	/* Remove allowed user (not created yet) */
	const handleRemoveAllowedUser = (uid) => {
		const db = firebase.firestore();
		db.collection(appConfig.allowedUsersDbName).doc(uid).delete().catch((error) => {
			console.error(error);
		});
	};


	/* Delete user popup */
	const [deleteUserData, setDeleteUserData] = useState(null);


	return (
		<div className="Admin">
			<div className="Admin-logo"><Logo page="admin" /></div>
			<div className="Admin-settingsBtn">
				<Button 
					classes={['red', 'logout']} 
					text={facilitatorUiTexts.lobby} 
					onClick={() => {handleGoToPage('settings');}}
				/>
			</div>
			<div className="Admin-addUser">
				<div className="Admin-addUserInput">
					<input
						name="email"
						type="text"
						placeholder={facilitatorUiTexts.email}
						value={email ? email : ''}
						onChange={(event) => {updateEmail(event);}}
					/>
				</div>
				<div className="Admin-addUserBtn">
					<Button 
						classes={['red']}
						text={facilitatorUiTexts.addUser}
						onClick={() => {handleAddAllowedUser();}}
					/>
				</div>
			</div>
			<div className="Admin-users">
				<div className="Admin-usersHeader">
					<div className="Admin-usersItem email">{facilitatorUiTexts.email}</div>
					<div className="Admin-usersItem created">{facilitatorUiTexts.status}</div>
					<div className="Admin-usersItem delete">{facilitatorUiTexts.delete}</div>
				</div>
				<div className="Admin-usersBody">
					{allowedUsers.map((auser) => {
						const userData = users.find((u) => {return u.email === auser.email;});
						const isCreated = users.some((u) => {return u.email === auser.email;});
						return (
							<div key={auser.id} className="Admin-usersRow">
								<div className="Admin-usersItem email">{auser.email}</div>
								<div className="Admin-usersItem created">
									{(isCreated ? facilitatorUiTexts.created : '-')}
								</div>
								<div className="Admin-usersItem delete">
									{!isCreated && 
										<Button 
											classes={['delete']}
											onClick={() => {handleRemoveAllowedUser(auser.id);}}
										/>
									}
									{(isCreated && userData.id !== userId && !userData.isAdmin) && 
										<Button 
											classes={['delete']}
											onClick={() => {setDeleteUserData({...userData, auserId: auser.id});}}
										/>
									}
								</div>
							</div>
						);
					})}
				</div>
			</div>

			{deleteUserData && <DeleteUserPopup 
				userData={deleteUserData}
				setDeleteUserData={setDeleteUserData}
				deleteUser={deleteUser}
			/>}
		</div>
	);
};

Admin.propTypes = {
	userId: PropTypes.string.isRequired,
	users: PropTypes.array.isRequired,
	allowedUsers: PropTypes.array.isRequired,
	deleteUser: PropTypes.func.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
};

export default Admin;
