const modulePages = [
	{
		id: 'm6-intro',
		isFacilitatorOnly: true,
		title: 'Intro til modulet',
		facilitatorInstructions: `Følg guiden. 
			<br /><br />Fortæl eleverne, hvad der skal ske på modul 6.`,
		facilitatorText: null,
		groupText: null,
		icons: [],
		slides: [
			{
				id: 'the-bad-circle',
				type: 'image', 
				text: 'Den svære cirkel'
			},
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskassen'
			}
		]	
	},
	{
		id: 'm6-problem-solving',
		isFacilitatorOnly: true,
		title: 'Feedback og problemløsning',
		facilitatorInstructions: `Følg guiden trin for trin. 
			<br /><br />Eleverne fortæller, hvordan det er gået med basalplanen, og der gennemføres en fælles problemløsning.
			<br /><br />Husk, at du selv er rollemodel<br /> </br>
			<strong>Gode råd</strong>
			<ul><li> Hvis en elev har oplevet udfordringer og ikke har gennemført sin basalplan, kan du starte med at spørge om eleven selv har løsningsforslag. Hvis ikke kan du foreslå en fælles problemløsning i klassen.</li>
			<li>Hvis en elev har gennemført sin basalplan, anerkender du eleven.</li>
			<li>Hvis en elev har mødt forhindringer og har justeret sin basalplan, anerkender du elevens indsats og understreger, at det er en god strategi at justere sin plan. </li>
			<li>Hvis en elev har oplevet udfordringer og gennemført basalplanen delvist, bemærker du det positive i at være kommet i gang. Lav evt. en fælles problemløsning med udgangspunkt i elevens udfordring, medmindre eleven selv har idéer til løsning af udfordringen.</li></ul>
			</br>
			<p>Vær opmærksom på, så vidt muligt at gøre problemet til ”tavlens problem”, så løsninger gives til tavlen og ikke den enkelte elev. Vælg så vidt muligt udfordringer, som kan bredes ud, så flere elever kan spejle sig i dem.</p>`,
		facilitatorText: `<strong>Hvad gik din basalplan ud på?</strong><br /><br />
		<strong>Hvordan er det gået med din basalplan?</strong><br/><br/>
		<strong>Mulighed for problemløsning</strong><br /><br /><br />`,
		icons: ['plan-of-action', 'problem-solving'],
		slides: [
			{
				id: 'plan-of-action',
				type: 'image',
				text: 'Basalplansskema'
			},
			{
				id: 'problem-solving',
				type: 'image',
				text: 'Problemløsningsskema'
			},
			{
				id: 'the-bad-circle',
				type: 'image', 
				text: 'Den svære cirkel'
			},
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskassen'
			}
		]	
	},
	{
		id: 'm6-attention',
		isFacilitatorOnly: true,
		title: 'Opmærksomhed',
		facilitatorInstructions: `Følg guiden. Lav opmærksomhedsøvelsen med eleverne. Tal med eleverne om afledning.`,
		facilitatorText: null,
		icons: ['attention', 'thoughts-helping'],
		slides: [
			{
				id: 'the-bad-circle',
				type: 'image', 
				text: 'Den svære cirkel'
			},
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskassen'
			}
		]	
	},
	// {
	// 	id: 'm6-thinking',
	// 	isFacilitatorOnly: false,
	// 	title: 'Tanketræning: tankefælder',
	// 	facilitatorInstructions: `Følg guiden.
	// 		<br /><br />Introducer info om tankefælder.
	// 		<br /><br />Eleverne arbejder sammen to og to i www.basalsammen.dk.
	// 		<br /><br />Saml op i klassen.`,
	// 	facilitatorText: `Hvis dine tanker indeholder ord som:
	// 		<br /><br />"skal, hvis, når, burde, aldrig, altid, bør og skulle"
	// 		<br /><br />- så kan der være tankefælder på spil!
	// 		<br /><br />Gå sammen i grupper af to. Gå til <strong>www.basalsammen.dk</strong>, og skriv spilkoden.
	// 		<br /><br /><span><%GAMECODE%></span><br /><br />`,
	// 	groupInstructions: `Løs de to opgaver i grupper.
	// 		<br /><br /><strong>Opgave 1:</strong><br />Tal i gruppen om, hvilket udsagn der passer til hvilken tankefælde. Vælg et udsagn, og træk det hen til den tankefælde, I mener den passer til. Tryk OK, når I mener, I har placeret dem rigtigt.
	// 		<br /><br /><strong>Opgave 2:</strong><br />Læs eksemplet, og udfyld modellen med et eksempel, I laver.`,
	// 	groupText: null,
	// 	icons: ['thoughts-helping', 'online-teaching'],
	// 	illustrations: [''],
	// 	slides: [],
	// 	minigames: [
	// 		require('data/minigames/m6-thought-traps').minigameData
	// 	]
	// },
	{
		id: 'm6-making-decisions',
		isFacilitatorOnly: true,
		title: 'At træffe beslutninger',
		facilitatorInstructions: `Følg guiden.
			<br />Forklar værktøjet "At træffe beslutninger".
			<br />Eleverne arbejder sammen to og to om at afprøve værktøjet.`,
		facilitatorText: `EKSEMPEL PÅ EN BESLUTNING
			<br />Skal jeg tage til hyggeaften med nogle fra klassen, der skal se gyserfilm?
			<br /><br />Katrine skal beslutte, om hun skal tage til hyggeaften på lørdag sammen med nogle fra klassen, eller om hun i stedet skal blive hjemme med familien, fordi hun ikke kan lide gyserfilm. På den ene side kan hun godt lide at være sammen med sine klassekammerater, men på den anden side er hun bange for, at de andre synes, det er pinligt, at hun ikke kan lide gyserfilm.
			<br /><br />Når Katrine tæller point sammen, er resultatet, at der er forskel på fordele (11) og ulemper (9).
			<br /><br />Hvis dette resultat føles rigtigt i maven, ved Katrine, hvad hun skal gøre.`,
		groupText: null,
		icons: ['making-decisions'],
		slides: [
			{
				id: 'making-decisions',
				type: 'image',
				text: 'At træffe beslutninger'
			},
			{
				id: 'the-bad-circle',
				type: 'image',
				text: 'Den svære cirkel'
			},
			{
				id: 'example-decision',
				type: 'image',
				text: 'Eksempel'
			},
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskassen'
			}
		]
	},
	{
		id: 'm6-basal-plan',
		isFacilitatorOnly: true,
		title: 'Basalplan',
		menuTitle: 'Basalplan',
		facilitatorInstructions: `Følg guiden trin for trin. Efter idérunden udarbejder eleverne hver en basalplan, der styrker fællesskabet i klassen. Husk, at du selv er rollemodel og også selv skal lave en basalplan.`,
		facilitatorText: `<strong>Opgave:</strong> <br/>
		Lav en basalplan, der styrker fællesskabet i klassen.`,
		groupText: null,
		icons: ['plan-of-action', 'idea-round'],
		slides: [
			{
				id: 'plan-of-action',
				type: 'image',
				text: 'Basalplansskema'
			},
			{
				id: 'problem-solving',
				type: 'image',
				text: 'Problemløsningsskema'
			},
			{
				id: 'the-bad-circle',
				type: 'image', 
				text: 'Den svære cirkel'
			},
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskassen'
			},
			{
				id: 'idea-round',
				type: 'image',
				text: 'Idérunde'
			},
		],
		minigames: []
	},

	// {
	// 	id: 'm6-plan-of-action',
	// 	isFacilitatorOnly: true,
	// 	title: 'Handleplan',
	// 	facilitatorInstructions: `Følg guiden trin for trin.
	// 		<br /><br />Eleverne udarbejder hver en handleplan.
	// 		<br /><br />Husk, at du selv er rollemodel og også selv skal lave en handleplan.`,
	// 	facilitatorText: `<strong>Opgave:</strong><br />Gå sammen to og to, og hjælp hinanden med at lave en handleplan.`,
	// 	groupText: null,
	// 	icons: ['plan-of-action'],
	// 	slides: [
	// 		{
	// 			id: 'plan-of-action',
	// 			type: 'image',
	// 			text: 'Basalplansskema'
	// 		},
	// 		{
	// 			id: 'problem-solving',
	// 			type: 'image',
	// 			text: 'Problemløsningsskema'
	// 		}
	// 	]
	// },
	{
		id: 'm6-finish',
		isFacilitatorOnly: true,
		title: 'Afrunding',
		menuTitle: 'Afrunding',
		facilitatorInstructions: `Følg guiden.`,
		facilitatorText: `<strong>Hvilket værktøj, hvilken øvelse eller aktivitet fra dagens modul vil du fokusere på i den næste uges tid?</strong> <br/><br/>
		<strong>Hvordan kan man gøre dig glad?</strong><br/><br/>
		<strong>Hvad har givet dig noget at tænke over?</strong>`,
		groupText: null,
		icons: ['toolbox'],
		slides: [
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskasse'
			},
			{
				id: 'the-bad-circle',
				type: 'image', 
				text: 'Den svære cirkel'
			},
		],
	},
];


export {
	modulePages
};