import React, {useState} from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { facilitatorUiTexts } from 'data/ui-texts';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import './pdf-guide.scss';

// https://github.com/wojtekmaj/react-pdf

const PdfGuide = () => {
	/* Track number of pages / current page number */
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);

	/* When pdf has loaded -> set number of pages */
	const onDocumentLoadSuccess = ({ numPages }) =>  {
		setNumPages(numPages);
	};

	/* Viewer config options */
	const options = {
		cMapUrl: 'cmaps/',
		cMapPacked: true,
		standardFontDataUrl: 'standard_fonts/',
	};

	/* Navigate to prev/next page */
	const handleNav = (direction) => {
		if (direction === 'first' && pageNumber > 1) {
			setPageNumber(1);
		}
		if (direction === 'last' && pageNumber < numPages) {
			setPageNumber(numPages);
		}
		if (direction === 'prev' && pageNumber > 1) {
			setPageNumber(pageNumber - 1);
		}
		if (direction === 'prev-skip' && pageNumber > 1) {
			const newPageNumber = Math.max(pageNumber - 10, 1);
			setPageNumber(newPageNumber);
		}
		if (direction === 'next' && pageNumber < numPages) {
			setPageNumber(pageNumber + 1);
		}
		if (direction === 'next-skip' && pageNumber < numPages) {
			const newPageNumber = Math.min(pageNumber + 10, numPages);
			setPageNumber(newPageNumber);
		}
	};
	
	/* Check if navigation buttons are disabled */
	const prevBtnIsDisabled = (pageNumber === 1);
	const nextBtnIsDisabled = (pageNumber === numPages);

	return (
		<div className="PdfGuide">
			{/* No print info */}
			<div className="PdfGuide-noPrint">
				<span>{facilitatorUiTexts.noPrintInfo}</span>
			</div>
			
			{/* PDF */}
			<div className="PdfGuide-wrap">
				<Document 
					loading={facilitatorUiTexts.loadingPdf}
					file={'/files/basal-undervisningsvejledning.pdf'}
					onLoadSuccess={onDocumentLoadSuccess} 
					options={options}
				>
					<Page 
						pageNumber={pageNumber} 
						scale={0.8} 
					/>
				</Document>
			</div>

			{/* Navigation */}
			<div className="PdfGuide-navigation">
				<div 
					className={'PdfGuide-navBtn first' + (prevBtnIsDisabled ? ' disabled' : '')}
					onClick={() => {handleNav('first');}}
				/>
				<div 
					className={'PdfGuide-navBtn prev-skip' + (prevBtnIsDisabled ? ' disabled' : '')}
					onClick={() => {handleNav('prev-skip');}}
				/>
				<div 
					className={'PdfGuide-navBtn prev' + (prevBtnIsDisabled ? ' disabled' : '')}
					onClick={() => {handleNav('prev');}}
				/>
				<div className="PdfGuide-navPage"><span>{pageNumber}/{numPages}</span></div>
				<div 
					className={'PdfGuide-navBtn next' + (nextBtnIsDisabled ? ' disabled' : '')}
					onClick={() => {handleNav('next');}}
				/>
				<div 
					className={'PdfGuide-navBtn next-skip' + (nextBtnIsDisabled ? ' disabled' : '')}
					onClick={() => {handleNav('next-skip');}}
				/>
				<div 
					className={'PdfGuide-navBtn last' + (nextBtnIsDisabled ? ' disabled' : '')}
					onClick={() => {handleNav('last');}}
				/>

			</div>
			
		</div>
	);
};


export default PdfGuide;
