import React from 'react';
import PropTypes from 'prop-types';
import {facilitatorUiTexts} from 'data/ui-texts';
import Button from 'components/ui/button/button';
import './delete-game-popup.scss';

const DeleteGamePopup = ({isDeletingGame, gameId, toggleDeleteGamePopup, handleDeleteGame}) => {
	let text = JSON.parse(JSON.stringify(facilitatorUiTexts.deleteGamePopup.text));
	text = text.replace(/%gameId%/g, gameId);

	return (
		<div className="DeleteGamePopup" onClick={() => {toggleDeleteGamePopup(null);}}>
			<div className="DeleteGamePopup-content" onClick={(e) => {e.stopPropagation();}}>
				<div className="DeleteGamePopup-header">
					<div className="DeleteGamePopup-title">
						{facilitatorUiTexts.deleteGamePopup.title}
					</div>
				</div>
				<div className="DeleteGamePopup-body">
					<div className="DeleteGamePopup-text">{text}</div>
					<div className="DeleteGamePopup-buttons">
						<Button
							classes={['login']}
							isLoading={isDeletingGame}
							text={facilitatorUiTexts.deleteGamePopup.deleteBtn}
							onClick={() => {handleDeleteGame(gameId);}}
						/>
						<Button
							classes={['red-frame']}
							text={facilitatorUiTexts.deleteGamePopup.cancelBtn}
							onClick={() => {toggleDeleteGamePopup(null);}}
						/>

					</div>
				</div>

				
			</div>
		</div>
	);
};

DeleteGamePopup.propTypes = {
	isDeletingGame: PropTypes.bool.isRequired,
	gameId: PropTypes.string,
	toggleDeleteGamePopup: PropTypes.func.isRequired,
	handleDeleteGame: PropTypes.func.isRequired,
};

export default DeleteGamePopup;
