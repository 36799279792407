import React from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import {facilitatorUiTexts} from 'data/ui-texts';
import {modulesData} from 'data/modules-data';
import {ReactComponent as IconSwirl} from 'assets/images/icon-swirl.svg';
import Logo from 'components/ui/logo/logo';
import GameCode from 'components/ui/game-code/game-code';
import Button from 'components/ui/button/button';
import './game-overview.scss';

const GameOverview = ({gameData, handleGoToModule, handleGoToGame}) => {

	return (
		<div className="GameOverview">
			{/* Logo */}
			<div className="GameOverview-logo"><Logo page="game" /></div>

			{/* Game code */}
			<GameCode page="game-page" gameData={gameData} />

			{/* Modules */}
			<div className="GameOverview-modules">
				{modulesData.map((moduleData) => {
					let btnClasses = ['red-medium', 'play'];
					
					return (
						<div 
							key={moduleData.id}
						 className={'GameOverview-module ' + moduleData.colorId}>
							<div className="GameOverview-moduleHeader">
								<span>{moduleData.title}</span>
								<IconSwirl />
							</div>
							<div className="GameOverview-moduleBody">
								<div className="GameOverview-moduleDescription">
									{renderMarkdown(moduleData.description)}
								</div>
								<div className="GameOverview-moduleBtn">
									<Button 
										classes={btnClasses}
										text={facilitatorUiTexts.startModule} 
										onClick={() => {handleGoToModule(moduleData.id);}} 
									/>
								</div>
							</div>
						</div>
					);
				})}

				{/* Lobby btn */}
				<div className="GameOverview-lobbyBtn">
					<Button 
						classes={['red', 'lobby']} 
						text={facilitatorUiTexts.home} 
						onClick={() => {handleGoToGame();}} 
					/>
				</div>
			</div>
		</div>
	);
};

GameOverview.propTypes = {
	gameData: PropTypes.object.isRequired,
	handleGoToModule: PropTypes.func.isRequired,
	handleGoToGame: PropTypes.func
};

export default GameOverview;
