import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {gameUiTexts} from 'data/ui-texts';
import {shuffleArray} from 'helpers/array-helper';
import Button from 'components/ui/button/button';
import Popup from 'components/ui/popup/popup';
import MatchDndContainer from './match-dnd-container';
import MatchDndItem from './match-dnd-item';
import MatchDndPreview from './match-dnd-preview';
import './match.scss';

const Match = ({partIndex, gameStatus, minigameData, setGameStatus}) => {
	const [showInfoPopup, setShowInfoPopup] = useState(false);
	const [items, setItems] = useState([]);
	const [feedback, setFeedback] = useState(null);

	/**
	 * Start game part
	 */
	const handleStartGamePart = () => {
		if (minigameData && minigameData.parts && minigameData.parts.length > partIndex) {
			/* Get and shuffle items */
			let initialItems = [];
			minigameData.parts[partIndex].groups.forEach((group) => {
				minigameData.parts[partIndex].partItemTypes.forEach((itemType) => {
					const isDraggable = (!minigameData.parts[partIndex].placedFromStart.some((typeId) => {
						return typeId === itemType.id;
					}));
					
					initialItems.push({
						isDraggable,
						groupId: group.id,
						typeId: itemType.id,
						text: group[itemType.id],
						placedInGroupId: (isDraggable ? null : group.id),
						placedInTypeId: (isDraggable ? null : itemType.id)
					});
				});
			});
			initialItems = shuffleArray(initialItems);
			setItems(initialItems);

			/* Update game status */
			setGameStatus('playing');

			/* Show intro popup */
			setShowInfoPopup(true);

		} else {
			console.error('No match data');
		}
	};


	/**
	 * Find item
	 * @param {string} groupId 
	 * @param {string} type
	 * @returns 
	 */
	 const handleFindItem = (groupId, typeId) => {
		const item = items.find((item) => {return item.groupId === groupId && item.typeId === typeId;});
		return (item ? {groupId: item.placedInGroupId, typeId: item.placedInTypeId} : null);
	};

	/**
	 * Move item to new container
	 * @param {string} groupId 
	 * @param {string} type
	 * @param {string} containerId 
	 */
	const handleMoveItem = (groupId, typeId, fromGroupId, fromTypeId, toGroupId, toTypeId) => {
		let newItems = JSON.parse(JSON.stringify(items));
		const itemIndex = newItems.findIndex((item) => {return (item.groupId === groupId && item.typeId === typeId);});

		/* From items to container */
		if (fromGroupId === null && toGroupId !== null) {
		/* If container is occupied, the initial item is sent back to the items area */
			let itemToSwitchIndex = newItems.findIndex((item) => {
				return (item.placedInGroupId === toGroupId && item.placedInTypeId === toTypeId);
			});
			if (itemToSwitchIndex > -1) {
				if (newItems[itemToSwitchIndex].isDraggable) {
					newItems[itemToSwitchIndex].placedInGroupId = null;
					newItems[itemToSwitchIndex].placedInTypeId = null;

				} else {
					return;
				}
			}
			newItems[itemIndex].placedInGroupId = toGroupId;
			newItems[itemIndex].placedInTypeId = toTypeId;
		}

		/* From container to container */
		if (
			fromGroupId !== null && fromTypeId !== null && toGroupId !== null && toTypeId !== null &&
			!(fromGroupId === toGroupId && fromTypeId === toTypeId)
		) {
		/* If container is occupied, make items switch place */
			let itemToSwitchIndex = newItems.findIndex((item) => {
				return (item.placedInGroupId === toGroupId && item.placedInTypeId === toTypeId);
			});
			if (itemToSwitchIndex > -1) {
				if (newItems[itemToSwitchIndex].isDraggable) {
					newItems[itemToSwitchIndex].placedInGroupId = fromGroupId;
					newItems[itemToSwitchIndex].placedInTypeId = fromTypeId;
					
				} else {
					return;
				}
			}
			newItems[itemIndex].placedInGroupId = toGroupId;
			newItems[itemIndex].placedInTypeId = toTypeId;
		}

		setItems(newItems);
	};

	const checkIfAllItemsArePlaced = () => {
		const allItemsArePlaced = !items.some((i) => {
			return (i.placedInGroupId === null || i.placedInTypeId === null);
		});
		return allItemsArePlaced;
	};


	/**
	 * Confirm placed items
	 * @returns 
	 */
	const handleConfirm = () => {
		/* Cannot confirm if some items have not been placed */
		if (!checkIfAllItemsArePlaced()) return;
		
		/* Count errors */
		let errors = 0;
		items.forEach((item) => {
			if (!item.isDraggable) return;
			if (item.typeId !== item.placedInTypeId) errors += 1;
		});

		if (errors === 0) {
			/* Items all placed in correct columns, check row relations */
			minigameData.parts[partIndex].groups.forEach((group) => {
				const item1 = items.find((i) => {
					return (
						i.placedInGroupId === group.id && 
						i.placedInTypeId === minigameData.parts[partIndex].partItemTypes[1].id
					);
				});
				const item2 = items.find((i) => {
					return (
						i.placedInGroupId === group.id && 
						i.placedInTypeId === minigameData.parts[partIndex].partItemTypes[2].id
					);
				});
				console.log(item1, item2);
				if (item1.groupId !== item2.groupId) errors += 1;
			});		
		} 

		if (errors === 0) {
			/* All correct */
			setFeedback(minigameData.parts[partIndex].donePopupText);
			setGameStatus('done');
		} else {
			/* 1+ errors, try again */
			let newFeedback = gameUiTexts.matchFeedbackPopup.text2;
			setFeedback(newFeedback);
		}
	};

	/**
	 * Handle scroll
	 * @param {string} direction 
	 */
	 const handleScroll = (direction) => {
		const itemsRef = document.getElementById('items');
		if (itemsRef) {
			const scrollDiff = itemsRef.scrollWidth - itemsRef.clientWidth;
			if (direction === 'left' && itemsRef.scrollLeft > 0) {
				itemsRef.scrollLeft = Math.max(0, itemsRef.scrollLeft - 300);
			}
			if (direction === 'right' && itemsRef.scrollLeft < scrollDiff) {
				itemsRef.scrollLeft = Math.min(scrollDiff, itemsRef.scrollLeft + 300);
			}
		}
	};

	/**
	 * Component mounted / will unmount
	 */
	useEffect(() => {
		/* Component mounted */
		handleStartGamePart();
	
		return () => {
			/* Component will unmount */
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		handleStartGamePart();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [partIndex]);

	const canScrollLeft = true; 
	const canScrollRight = true;

	return (
		<div className="Match">	
			<MatchDndContainer
				isEmpty={false}
				layout={minigameData.id}
				groupId="all"
				typeId="all"
				handleFindItem={handleFindItem}
				handleMoveItem={handleMoveItem}
			>
				{/* Groups */}
				<div className="Match-groups">
					<div className="Match-groupTypes">
						{minigameData.parts[partIndex].partItemTypes.map((type) => {
							return (
								<div key={type.id} className="Match-typeName"><span>{type.text}</span></div>
							);
						})}
					</div>
					{minigameData.parts[partIndex].groups.map((group) => {
						return (
							<div key={group.id} className="Match-group">
								{minigameData.parts[partIndex].partItemTypes.map((type) => {
									const placedItem = items.find((i) => {
										return (i.placedInGroupId === group.id && i.placedInTypeId === type.id);
									});
									return (
										<div key={type.id} className={'Match-itemContainer' 
											+ (placedItem ? '' : ' empty')}
										>
											<MatchDndContainer 
												isEmpty={(placedItem ? false : true)}
												layout={minigameData.id}
												groupId={group.id}
												typeId={type.id}
												handleFindItem={handleFindItem}
												handleMoveItem={handleMoveItem}
											>
												{placedItem && (
													<MatchDndItem
														key={(group.id + '-' + type.id)}
														isDraggable={placedItem.isDraggable && gameStatus === 'playing'}
														layout={minigameData.id}
														// classes={classes}
														itemData={placedItem}
														handleMoveItem={handleMoveItem}
													/>)
												}
											</MatchDndContainer>
										</div>
									);
								})}
							</div>
						);
					})}
				</div>

				{/* Items */}
				<div className="Match-panel">
					<div 
						className={'Match-scrollLeftBtn' + (canScrollLeft ? ' active' : '')} 
						onClick={() => {handleScroll('left');}} 
					/>
					<div className="Match-itemsWrap">
						<div id="items" className="Match-items">
							{items.filter((i) => {return i.placedInGroupId === null;}).map((i) => {
								return (
									<div key={i.groupId + '-' + i.typeId} className="Match-item">
										<MatchDndItem 
											isDraggable={i.isDraggable && gameStatus === 'playing'}
											layout={minigameData.id}
											// classes={classes}
											itemData={i}
											handleMoveItem={handleMoveItem}
										/>
									</div>
								);
							})}
						</div>
					</div>
					<div 
						className={'Match-scrollRightBtn' + (canScrollRight ? ' active' : '')}
						onClick={() => {handleScroll('right');}} 
					/>
				</div>
				

				{/* Drag'n'drop preview */}
				<MatchDndPreview layout={minigameData.id} items={items} />
			</MatchDndContainer>

			{/* Confirm button */}
			{gameStatus === 'playing' && 
					<div className="Match-okBtn">
						<Button classes={['red']} text="OK" onClick={() => {handleConfirm();}} />
					</div>
			}

			{/* Info popup button */}
			{minigameData.parts[partIndex].infoPopupText && <div className="Match-infoBtn">
				<Button classes={['info']} onClick={() => {setShowInfoPopup(true);}} />
			</div>}

			{/* Info popup */}
			{showInfoPopup && 
				<Popup 
					type="intro-small"
					text={minigameData.parts[partIndex].infoPopupText} 
					buttons={[
						{text: gameUiTexts.ok, action: setShowInfoPopup, parameters: [false]}
					]}
				/>
			}

			{/* Feedback popup */}
			{feedback && 
				<Popup 
					type="intro-smallMedium"
					text={feedback} 
					buttons={[
						{text: gameUiTexts.ok, action: setFeedback, parameters: [null]}
					]}
				/>
			}
		</div>
	);
};

Match.propTypes = {
	partIndex: PropTypes.number.isRequired,
	gameStatus: PropTypes.string.isRequired,
	minigameData: PropTypes.object.isRequired,
	setGameStatus: PropTypes.func.isRequired
};

export default Match;
