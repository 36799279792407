/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import './logos.scss';

const Logo = () => {
	
	return (
		<div className="Logos">
			<div className="Logos-kfs" />
			<div className="Logos-support" />
			<a className="Logos-cgl" href="http://cphgamelab.dk" target="_blank" rel="noreferrer"></a>
		</div>
	);

};




export default Logo;