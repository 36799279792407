import React from 'react';
import PropTypes from 'prop-types';
import './logo.scss';

const Logo = ({page, colorId}) => {
	
	return (
		<div className={'Logo ' + page + (colorId ? ' ' + colorId : '')} />
	);

};




Logo.propTypes = {
	page: PropTypes.string.isRequired,
	colorId: PropTypes.string
};


export default Logo;