import React from 'react';
import PropTypes from 'prop-types';
import './image.scss';

const Image = ({type, imageId}) => {
	return (
		<div className={'Image ' + type + ' ' + imageId} />		
	);
};


Image.propTypes = {
	type: PropTypes.string.isRequired,
	imageId: PropTypes.string.isRequired,
};


export default Image;