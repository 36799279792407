import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import {facilitatorUiTexts, errorUiTexts} from 'data/ui-texts';
import Button from 'components/ui/button/button';
import './delete-user-popup.scss';

const DeleteUserPopup = ({userData, setDeleteUserData, deleteUser}) => {
	/* Deleting status */
	const [isDeletingUser, setIsDeletingUser] = useState(false);

	/* Error */
	const [errMsg, setErrMsg] = useState(null);

	/* Get dynamic popup text */
	const text = (userData 
		? JSON.parse(JSON.stringify(facilitatorUiTexts.deleteUserPopup.text)).replace(/%email%/g, userData.email)
		: ''
	);

	/**
	 * Delete the user, their user data and all their games
	 */
	const handleDeleteUser = () => {
		if (isDeletingUser) return;

		setErrMsg(null);
		setIsDeletingUser(true);
		deleteUser(userData.id, userData.auserId).then((response) => {
			if (response.status === 'success') {
				setDeleteUserData(null);
			} else {
				setIsDeletingUser(false);
				setErrMsg(errorUiTexts.unknownError);
				console.error(response.error);
			}
		});
	};

	return (
		<div className="DeleteUserPopup">
			<div className="DeleteUserPopup-content">
				<div className="DeleteUserPopup-header">
					<div className="DeleteUserPopup-title"><span>{facilitatorUiTexts.deleteUserPopup.title}</span></div>
				</div>
				<div className="DeleteUserPopup-body">
					<div className="DeleteUserPopup-text">{renderMarkdown(text)}</div>
					<div className="DeleteUserPopup-errMsg">
						<span>{errMsg}</span>
					</div>
					<div className="DeleteUserPopup-buttons">
						<Button
							classes={['login']}
							isLoading={isDeletingUser}
							text={facilitatorUiTexts.deleteUserPopup.deleteBtn}
							onClick={() => {handleDeleteUser();}}
						/>
						<Button
							classes={['red-frame']}
							text={facilitatorUiTexts.deleteUserPopup.cancelBtn}
							onClick={() => {setDeleteUserData(null);}}
						/>
					</div>
					
				</div>
			</div>	
		</div>
	);
};

DeleteUserPopup.propTypes = {
	
	userData: PropTypes.object,
	setDeleteUserData: PropTypes.func.isRequired,
	deleteUser: PropTypes.func.isRequired
};

export default DeleteUserPopup;
