import React from 'react';
import PropTypes from 'prop-types';
import './dialogue-examples.scss';


const DialogueExamples = ({slideData}) => {
	return (
		<div className="DialogueExamples">
			<div className="DialogueExamples-title"><span>{slideData.text}</span></div>
			<div className={'DialogueExamples-examples amount-' + slideData.examples.length}>
				{slideData.examples.map((example) => {
					return (
						<div key={example.id} className="DialogueExamples-example">
							<div className="DialogueExamples-exampleTitle"><span>{example.title}:</span></div>
							<div 
								className={'DialogueExamples-exampleDialogue'
									+ (example.dialogue.length > 4 ? ' big' : '') }
							>
								{example.dialogue.map((row, index) => {
									return (
										<div key={index} className="DialogueExamples-exampleDialogueRow">
											<div className="DialogueExamples-exampleDialoguePerson">
												<span>{row.person}</span>
											</div>
											<div className="DialogueExamples-exampleDialogueText">
												<span>{row.text}</span>
											</div>
										</div>
									);
								})}								
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};


DialogueExamples.propTypes = {
	slideData: PropTypes.object.isRequired,
};


export default DialogueExamples;