import React from 'react';
import PropTypes from 'prop-types';
import {usePreview} from 'react-dnd-preview';
import './sort-dnd-preview.scss';

const SortDndPreview = ({itemsData}) => {
	const {display, item, style} = usePreview();
	if (!display) return null;

	/* Item data */
	const itemData = itemsData.find((i) => {return i.id === item.id;});

	return (
		<div 
			className={'SortDndDragLayer'} 
			style={style}
		>
			<div className="SortDndDragLayer-item">
				<span>{itemData ? itemData.title : null}</span>
			</div>
		</div>
	);
};

SortDndPreview.propTypes = {
	itemsData: PropTypes.array.isRequired
};

export default SortDndPreview;