import React, {useState} from 'react';
import Logo from 'components/ui/logo/logo';
import LoginFacilitatorController from './login-facilitator-controller';
import ResetPassword from './reset-password';
import CreateUserController from './create-user-controller';
import LoginGroupController from './login-group-controller';
import Logos from 'components/ui/logos/logos';
import CookieConsentPopup from 'components/ui/cookie-consent-popup/cookie-consent-popup';
import './login.scss';

const Login = () => {
	/* Get active box component for facilitator box */
	let [facilitatorBoxType, setFacilitatorBoxType] = useState('login');
	let FacilitatorBoxComponent = LoginFacilitatorController;
	if (facilitatorBoxType === 'createUser') FacilitatorBoxComponent = CreateUserController;
	if (facilitatorBoxType === 'resetPassword') FacilitatorBoxComponent = ResetPassword;

	return (
		<div className="Login">
			<div className="Login-logo"><Logo page="login" /></div>
			<div className="Login-wrap">
				<div className="Login-facilitator">
					<FacilitatorBoxComponent setFacilitatorBoxType={setFacilitatorBoxType} />
				</div>
				<div className="Login-group">
					<LoginGroupController />
				</div>
			</div>
			<Logos />
			<CookieConsentPopup />
		</div>
	);
};



export default Login;