const modulePages = [
	{
		id: 'm4-intro',
		isFacilitatorOnly: true,
		title: 'Intro til modulet',
		facilitatorInstructions: `Følg guiden. 
			<br /><br />Fortæl eleverne, hvad der skal ske på modul 4.`,
		facilitatorText: null,
		groupText: null,
		icons: [],
		slides: [
			{
			id: 'the-bad-circle',
			type: 'image', 
			text: 'Den svære cirkel'
		},
		{
			id: 'toolbox',
			type: 'image',
			text: 'Værktøjskassen'
		},
	]	
	},
	{
		id: 'm4-problem-solving',
		isFacilitatorOnly: true,
		title: 'Feedback og problemløsning',
		facilitatorInstructions: `Følg guiden trin for trin. <br/><br/>
		Eleverne fortæller, hvordan det er gået med basalplanen, og der gennemføres en fælles problemløsning.<br/><br/>
		Husk, at du selv er rollemodel </br> </br>
		<strong>Gode råd</strong>
		<ul><li>Hvis en elev har gennemført sin basalplan, anerkender du eleven.</li>
		<li>Hvis en elev har mødt forhindringer og har justeret sin basalplan, anerkender du elevens indsats og understreger, at det er en god strategi at justere sin plan.</li>
		<li>Hvis en elev har oplevet udfordringer og gennemført basalplanen delvist, bemærker du det positive i at være kommet i gang. Lav evt. en fælles problemløsning med udgangspunkt i elevens udfordring, medmindre eleven selv har idéer til løsning af udfordringen. </li>
		<li>Hvis en elev har oplevet udfordringer og ikke har gennemført sin basalplan, kan du starte med at spørge om eleven selv har løsningsforslag. Hvis ikke kan du foreslå en fælles problemløsning i klassen.</li></ul></br></br>
		<p>Vær opmærksom på, så vidt muligt at gøre problemet til ”tavlens problem”, så løsninger gives til tavlen og ikke den enkelte elev. Vælg så vidt muligt udfordringer, som kan bredes ud, så flere elever kan spejle sig i dem.</p>`,
		facilitatorText: `<strong>Hvad gik din basalplan ud på?</strong><br/><br/>
		<strong>Hvordan er det gået med din basalplan?</strong><br/><br/>
		<strong>Mulighed for problemløsning</strong>`,
		icons: ['plan-of-action', 'problem-solving'],
		slides: [
			{
				id: 'plan-of-action',
				type: 'image',
				text: 'Basalplansskema'
			},
			{
				id: 'problem-solving',
				type: 'image',
				text: 'Problemløsningsskema'
			},
			{
				id: 'the-bad-circle',
				type: 'image', 
				text: 'Den svære cirkel'
			},
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskassen'
			},
		]	
	},
	{
		id: 'm4-emotion-memory',
		isFacilitatorOnly: true,
		title: 'Følelsesmemory',
		facilitatorInstructions: `Følg guiden.
			<br /><br />Lav øvelsen "Følelsesmemory".`,
		facilitatorText: `Følelsesmemory.`,
		facilitatorDownloads: [{
			title: 'Følelsesmemory',
			file: '0622_Basal_undervisningsvejledning_A4_memory.pdf'
		}],
		groupInstructions: null,
		groupText: null,
		icons: ['thinking-brain'],
		slides: [
			{
				id: 'the-bad-circle',
				type: 'image', 
				text: 'Den svære cirkel'
			},
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskassen'
			},
		]
	},
	{
		id: 'm4-communication',
		isFacilitatorOnly: false,
		title: 'Kommunikation',
		facilitatorInstructions: `Følg guiden.
			<br /><br />Demonstrer opgaven.
			<br /><br />Eleverne arbejder i www.basalsammen.dk.`,
		facilitatorText: `Gå sammen i grupper på 4.
			<br /><br />Gå til <strong>www.basalsammen.dk.</strong>
			<br /><br />Skriv kode i elevlogin.
			<br /><br /><span><%GAMECODE%></span>`,
		groupInstructions: `Skim eksemplerne, og vælg et eksempel på en dialog, som I læser højt for hinanden. Én i gruppen er person 1, en anden er person 2. De andre observerer, hvad de hører i samtalen og giver feedback ud fra følgende spørgsmål:
			<br /><br />Hvad fungerer godt? Hvorfor?
			<br /><br />Hvad fungerer mindre godt? Hvorfor?`,
		groupText: `Eksempler på dialoger:`,
		icons: ['communication', 'online-teaching'],
		slides: [
			{
				id: 'dialogue-example-1',
				type: 'dialogue-examples',
				text: `Eksempel 1`,
				examples: [
					{
						id: 1,
						title: 'du-sprog',
						dialogue: [
							{person: 'Person 1', text: `Kom nu! Du er altid for sent på den.`},
							{person: 'Person 2', text: `Kan du ikke slappe lidt af i stedet for at skælde mig ud?`},
							{person: 'Person 1', text: `Hvis du bare kunne skynde dig lidt og ikke altid havde travlt med noget andet, så skulle vi ikke have den her diskussion hver evig eneste gang.`},
							{person: 'Person 2', text: `Skynde mig? Du aner ikke, hvor meget jeg skal nå i dag.`}
						]
					},
					{
						id: 2,
						title: 'jeg-sprog',
						dialogue: [
							{person: 'Person 1', text: `Det er på tide at gå nu. Jeg er meganervøs for, at vi kommer for sent. Er du ved at være der?`},
							{person: 'Person 2', text: `Jeg har vildt mange ting, jeg skal nå i dag, så jeg føler mig megapresset.`},
							{person: 'Person 1', text: `Okay, det vidste jeg ikke. Er der noget, jeg kan gøre?`},
							{person: 'Person 2', text: `Kunne du ikke ringe til Gustav og sige, at vi er lidt forsinkede?`},
							{person: 'Person 1', text: `God idé, så ved han det. Det gør det lidt lettere for mig, når du fortæller mig, hvordan du har det.`}
						]
					}
				]
			},
			{
				id: 'dialogue-example-2',
				type: 'dialogue-examples',
				text: `Eksempel 2`,
				examples: [
					{
						id: 1,
						title: 'du-sprog',
						dialogue: [
							{person: 'Person 1', text: `Ej, hallo! Du fylder herremeget på dit bord.`},
							{person: 'Person 2', text: `Ro på! Har du lige set dit eget bord? Det ligner, at der er sprunget en bombe?`},
							{person: 'Person 1', text: `What? Lige nu er det dig og dit rod, vi taler om.`},
							{person: 'Person 2', text: `Du er den sidste, jeg gider at sidde ved siden af – jeg bliver vanvittig!`}
						]
					},
					{
						id: 2,
						title: 'jeg-sprog',
						dialogue: [
							{person: 'Person 1', text: `Jeg har lidt svært ved at koncentrere mig, når der ligger så mange ting på bordet.`},
							{person: 'Person 2', text: `Det kan jeg godt forstå. Sådan har jeg det faktisk også. Skal vi hjælpe hinanden med lige at rydde op?`},
							{person: 'Person 1', text: `Ja, cool. Lad os det.`},
							{person: 'Person 2', text: `Du er bare den bedste sidemakker.`},
						]
					}
				]
			},
			{
				id: 'dialogue-example-3',
				type: 'dialogue-examples',
				text: `Eksempel 3`,
				examples: [
					{
						id: 1,
						title: 'Når det IKKE bliver sagt',
						dialogue: [
							{person: 'Person 1', text: `Vil du med i biografen i aften?`},
							{person: 'Person 2', text: `Jeg kan ikke nå det. Jeg har megatravlt med min matematikaflevering.`},
							{person: 'Person 1', text: `Det var ærgerligt. Er der noget, jeg kan gøre for at hjælpe?`},
							{person: 'Person 2', text: `Nej, det er fint. Jeg bliver bare hjemme.`}
						]
					},
					{
						id: 2,
						title: 'Når det bliver sagt',
						dialogue: [
							{person: 'Person 1', text: `Vil du med i biografen i aften?`},
							{person: 'Person 2', text: `Jeg kan ikke nå det. Jeg har megatravlt med min matematikaflevering.`},
							{person: 'Person 1', text: `Det var ærgerligt. Er der noget, jeg kan gøre for at hjælpe?`},
							{person: 'Person 2', text: `Jeg kunne faktisk virkelig godt bruge en pause fra skolearbejdet. Kunne du lokkes herover, så kan vi hygge os, inden du skal i biografen?`},
							{person: 'Person 1', text: `Okay, lad os det – jeg vil jo vildt gerne se dig i aften.`}
						]
					}
				]
			},
			{
				id: 'dialogue-example-4',
				type: 'dialogue-examples',
				text: `Eksempel 4`,
				examples: [
					{
						id: 1,
						title: 'du-sprog',
						dialogue: [
							{person: 'Person 1', text: `Du er altid så nærtagende, når jeg prøver at være sød ved dig.`},
							{person: 'Person 2', text: `Sød? Du er da total for meget og megairriterende.`},
							{person: 'Person 1', text: `Mig? Hallo, jeg gør jo slet ikke noget! Jeg prøver bare at være sjov.`},
							{person: 'Person 2', text: `Sjov? Du er ikke en skid sjov – eller sød.`}
						]
					},
					{
						id: 2,
						title: 'jeg-sprog',
						dialogue: [
							{person: 'Person 1', text: `I forhold til det, der skete lige før, så var det virkelig min hensigt at være sød.`},
							{person: 'Person 2', text: `Men med de ord var det altså ikke til at vide. Jeg troede, du var ironisk.`},
							{person: 'Person 1', text: `Det er godt, du siger det. Så må jeg jo prøve at være mere tydelig.`},
							{person: 'Person 2', text: `Ja, for nogle gange har jeg virkelig svært ved at finde ud af, om du laver sjov med mig eller ej.`},
						]
					}
				]
			},
			{
				id: 'dialogue-example-5',
				type: 'dialogue-examples',
				text: `Eksempel 5`,
				examples: [
					{
						id: 1,
						title: 'du-sprog',
						dialogue: [
							{person: 'Person 1', text: `Flyt dig nu. Du har taget min plads.`},
							{person: 'Person 2', text: `Slap dog af. Jeg sad her først.`},
							{person: 'Person 1', text: `Du tager altid min plads. Kan du ikke fatte, at det ikke er din?`},
							{person: 'Person 2', text: `Hallo, vi har sgu da ikke faste pladser her – fuck af med dig!`}
						]
					},
					{
						id: 2,
						title: 'jeg-sprog',
						dialogue: [
							{person: 'Person 1', text: `Undskyld mig, men jeg tror, at du har taget min plads.`},
							{person: 'Person 2', text: `Det ved jeg nu ikke. Jeg troede ikke, vi havde faste pladser.`},
							{person: 'Person 1', text: `Jo, her efter sommerferien er det besluttet, at vi igen har faste pladser.`},
							{person: 'Person 2', text: `Nå, for den da, det vidste jeg slet ikke. Kan jeg ikke få dig til at fortælle mig, hvor jeg så skal sidde?`},
							{person: 'Person 1', text: `Det kan du tro. Jeg sagde det heller ikke for at skabe ballade.`},
						]
					}
				]
			},
			{
				id: 'the-bad-circle',
				type: 'image', 
				text: 'Den svære cirkel'
			},
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskassen'
			},
		]
	},

	{
		id: 'm4-basal-plan',
		isFacilitatorOnly: true,
		title: 'Basalplan',
		menuTitle: 'Basalplan',
		facilitatorInstructions: `Følg guiden trin for trin. Eleverne udarbejder hver en basalplan. Husk, at du selv er rollemodel og også selv skal lave en basalplan.`,
		facilitatorText: `<strong>Opgave:</strong> <br/>
		Gå sammen to og to, og hjælp hinanden med at lave en basalplan.`,
		groupText: null,
		icons: ['plan-of-action'],
		slides: [
			{
				id: 'plan-of-action',
				type: 'image',
				text: 'Basalplansskema'
			},
			{
				id: 'problem-solving',
				type: 'image',
				text: 'Problemløsningsskema'
			},
			{
				id: 'the-bad-circle',
				type: 'image', 
				text: 'Den svære cirkel'
			},
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskassen'
			}
		],
		minigames: []
	},
	{
		id: 'm4-finish',
		isFacilitatorOnly: true,
		title: 'Afrunding',
		menuTitle: 'Afrunding',
		facilitatorInstructions: `Følg guiden.`,
		facilitatorText: `<strong>Hvad var sjovt eller skørt?</strong> <br/><br/>
		<strong>Hvad har givet dig noget at tænke over?</strong><br/><br/>
		<strong>Hvordan gør du dine klassekammerater glade?</strong>`,
		groupText: null,
		icons: ['toolbox'],
		slides: [
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskasse'
			},
			{
				id: 'the-bad-circle',
				type: 'image', 
				text: 'Den svære cirkel'
			},
		],
	},
];


export {
	modulePages
};