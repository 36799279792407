const modulePages = [
	{
		id: 'm7-intro',
		isFacilitatorOnly: true,
		title: 'Intro til modulet',
		facilitatorInstructions: `Følg guiden. 
			<br /><br />Fortæl eleverne, hvad der skal ske på modul 7.`,
		facilitatorText: null,
		groupText: null,
		icons: [],
		slides: [
			{
				id: 'the-bad-circle',
				type: 'image', 
				text: 'Den svære cirkel'
			},
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskassen'
			}
		]	
	},
	{
		id: 'm7-problem-solving',
		isFacilitatorOnly: true,
		title: 'Feedback og problemløsning',
		facilitatorInstructions: `Følg guiden trin for trin. 
			<br /><br />Eleverne fortæller, hvordan det er gået med basalplanen, og der gennemføres en fælles problemløsning.
			<br /><br />Husk, at du selv er rollemodel.<br /> </br>
			<strong>Gode råd</strong>
			<ul><li>Hvis en elev har gennemført sin basalplan, anerkender du eleven.</li>
			<li>Hvis en elev har mødt forhindringer og har justeret sin basalplan, anerkender du elevens indsats og understreger, at det er en god strategi at justere sin plan. </li>
			<li>Hvis en elev har oplevet udfordringer og gennemført basalplanen delvist, bemærker du det positive i at være kommet i gang. Lav evt. en fælles problemløsning med udgangspunkt i elevens udfordring, medmindre eleven selv har idéer til løsning af udfordringen. </li>
			<li>Hvis en elev har oplevet udfordringer og ikke har gennemført sin basalplan, kan du starte med at spørge om eleven selv har løsningsforslag. Hvis ikke kan du foreslå en fælles problemløsning i klassen.</li></ul>
			<br/>
			<p>Vær opmærksom på, så vidt muligt at gøre problemet til ”tavlens problem”, så løsninger gives til tavlen og ikke den enkelte elev. Vælg så vidt muligt udfordringer, som kan bredes ud, så flere elever kan spejle sig i dem.</p>`,
		facilitatorText: `<strong>Hvad gik din basalplan ud på?</strong><br /><br />
			<strong>Hvordan er det gået med din basalplan?</strong><br/><br/>
			<strong>Mulighed for problemløsning.</strong><br /><br /><br />`,
		icons: ['plan-of-action', 'problem-solving'],
		slides: [
			{
				id: 'plan-of-action',
				type: 'image',
				text: 'Basalplansskema'
			},
			{
				id: 'problem-solving',
				type: 'image',
				text: 'Problemløsningsskema'
			},
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskasse'
			},
			{
				id: 'the-bad-circle',
				type: 'image', 
				text: 'Den svære cirkel'
			},
		]	
	},
	{
		id: 'm7-thinking',
		isFacilitatorOnly: false,
		title: 'Tanker og følelser',
		facilitatorInstructions: `Følg guiden.`,
		facilitatorText: `Gå til www.basalsammen.dk. <br/><br/>
		Skriv koden i elevlogin.<br/><br/>
		<strong>Hvad vil I sige og råde personen til at gøre? </strong><br/><br/>
		<strong>Er det kun personen i teksten, der skal gøre noget eller er det også de andre omkring personen, der skal gøre noget? Hvad skal de gøre?</strong><br/><br/>
		<strong>Hvilke værktøjer fra værktøjskassen ville kunne hjælpe personen i situationen?</strong><br/><br/>
		<strong>Kan I som klasse bruge nogle af værktøjerne til at hjælpe personen eller andre, der kunne stå i en lignende situation?</strong><br/><br/>
		<strong>Hvordan kan vi som klasse undgå, at det samme sker i vores klasse?</strong><br/><br/>`,
		groupInstructions: `Læs den case, I har fået tildelt, og kom med gode råd til casepersonen.`,
		groupText: null,
		icons: ['online-teaching', 'thoughts-helping'],
		slides: [
			{
				id: 'the-bad-circle',
				type: 'image',
				text: 'Den svære cirkel'
			},
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskasse'
			},
			{
				id: 'thought-and-feeling',
				type: 'image',
				text: 'Tanke- og følelsesmodel'
			},
			{
				id: 'case-1',
				type: 'text',
				text: 'Ikke inviteret',
				subtext: `Jeg er overbevist om, at jeg ikke er inviteret ind i den ’rigtige’ klasse-snap-gruppe. Jeg er med i en
				gruppe, men der sker aldrig noget i den. Jeg hører tit, at de andre fra klassen taler om noget, der er
				sket i snapgruppen, men det må være en anden gruppe, for det er ikke i den, som jeg er en del af.
				Jeg har forsøgt at spørge en af mine klassekammerater om det, men så kom jeg bare til at fremstå
				som en komplet idiot, og det fik mig bare til at tænke, at jeg heller ikke er en særlig sjov type. Det er
				nok derfor, de ikke vil have mig med. Og så får jeg en følelse af at være mega kikset, og så føler jeg
				mig faktisk endnu mere usikker – så kører det bare rundt med tanker i hovedet på mig. Da jeg tog
				mod til mig og spurgte min klassekammerat, om jeg måtte være med i gruppen, blev jeg helt tør i
				halsen og kunne næsten ikke fremstamme, det jeg gerne ville sige. Mine håndflader blev helt fugtige
				og mit blik flakkede rundt. Personen jeg spurgte, sagde, at jeg da var med i den rigtige gruppe, og at
				der bare ikke var så meget aktivitet i gruppen. Det ved jeg ikke passer. De snyder mig og holder mig
				udenfor fællesskabet. Jeg endte med bare at sige ok og tænke, at jeg slet ikke skulle have spurgt. Det
				er jo mig, den er gal med og ikke de andre. Tårerne presser sig på, mens jeg sætter mig tilbage på
				min plads.`
			},
			{
				id: 'case-2',
				type: 'text',
				text: 'Brændt af',
				subtext: `Forleden dag skulle jeg mødes med en af mine klassekammerater. Vi plejer tit at mødes og snakke og hygge os, så jeg undrede mig over, at hun ikke dukkede op som aftalt. Men så kom jeg til at tænke, at hun i virkeligheden altid kommer for sent, og at hun i bund og grund nok bare er helt ligeglad med, at jeg sidder og venter. Ja, måske kan hun slet ikke lide mig. Mine tanker får mig til at få en følelse af slet ikke at være god nok og, at der ikke er nogen, der gider at være sammen med mig. Jeg føler mig helt nedprioriteret, min krop sitrer af vrede og tristhed, og jeg ender med at gå hjem.`
			},
			{
				id: 'case-3',
				type: 'text',
				text: 'Mere end mig',
				subtext: `Halvandet år er der imellem mig og min søster. Min søster er lige så høj som mig, så folk tror tit, at det er min søster, der er storesøsteren. Jeg. Hader. Det. Min søster er vildt god til alt. Hun er dygtig i skolen. Hun har mange venner. Drengene er vilde med hende. Hun er sød. Det er hun faktisk. Jeg er bare vildt jaloux. Det er jeg, fordi hun får mig til at føle mig som én, der er alt det modsatte af hende. Jeg tænker om mig selv, at jeg så er dum og doven. Det er jeg måske også? Hun får mig til at føle, at jeg er mindre værd end hende. Jeg bliver helt ked af det ved tanken og får tårer i øjnene og ondt i maven, når mine tanker løber af med mig. Lige der, når jeg har det sådan, så har jeg det allerbedst under min dyne.  `
			},
			{
				id: 'case-4',
				type: 'text',
				text: 'Eliteholdet',
				subtext: `Vi fik besked i torsdags. Jeg blev ikke udtaget. Jeg har ikke misset én eneste træning. Elliot kom med. Han er også god og åbenbart bedre end mig. Hvorfor? Hvorfor blev jeg ikke udtaget? Er det fordi trænerstaben i bund og grund ikke kan lide mig? Det hele kører rundt i hovedet på mig. Hvad kunne jeg have gjort anderledes? Nu bliver mit liv heller ikke, som jeg havde forventet. Jeg føler mig helt tom indeni, som om jeg slet ikke dur til noget. Hverken på banen eller i skolen. Elliot var der, da jeg fik beskeden, han så, hvordan jeg bare fløj op og væltede stolen, der stod foran mig. Når jeg bliver ked af det indeni, så bliver jeg vred udenpå. Så kan jeg ikke styre mine udbrud`
			},
			{
				id: 'case-5',
				type: 'text',
				text: 'Sengetid',
				subtext: `Du er da også bare en kæmpe idiot. Det var hvad, der røg ud af min mund, da min mor i går aftes sagde, at jeg skulle gå i seng. Det var ikke det eneste, jeg fik kaldt hende, og du kan nok godt tænke dig til flere af mine gloser. Da jeg lå træt i sengen kørte tankerne rundt i hovedet på mig. Hvorfor opfører jeg mig også på den måde? Hvorfor kan jeg ikke tale pænt? Har jeg fuldstændig mistet respekten? Tankerne gjorde mig helt ked af det, og jeg fik en følelse af skam indeni. Jeg mærkede det som en knude i maven og fik det helt dårligt indeni. Jeg faldt i søvn med tårerne trillende ned ad mine kinder. `
			},
			{
				id: 'case-6',
				type: 'text',
				text: 'Delt',
				subtext: `For 14 dage siden sendte jeg et billede til min veninde. Et billede af mig selv i bikini på en solseng i vores have. Jeg ville vise min veninde min nye navlepiercing. Hvad jeg ikke vidste var, at billedet samme weekend blev delt flere forskellige steder på de sociale medier. Det var Sif, der gjorde mig opmærksom på det. Hun havde fået tilsendt billedet af Emil, der havde fået det fra Mathias. Tanken om, at hele verden nu har set mig i bikini, gør mig vildt nervøs, og jeg føler mig ulækker. Først var jeg stolt over min piercing, men nu har jeg allermest lyst til bare at hive den ud. Fuck, jeg synes det er klamt, at alle ved, hvordan jeg ser ud uden særlig meget tøj på. Jeg ved slet ikke, hvad jeg skal gøre. Jeg føler mig helt handlingslammet, og jeg nægter at gå i skole i morgen. Jeg kan simpelthen ikke se mine klassekammerater i øjnene. `
			},
			{
				id: 'case-7',
				type: 'text',
				text: 'Bad efter idræt',
				subtext: `På min skole er der en regel om, at man skal gå i bad efter idræt. Faktisk vil jeg gerne gå i bad. Jeg har virkelig brug for det. Efter halvanden times idræt, så lugter jeg virkelig meget. Jeg har bare et problem. Hvis jeg går i bad efter idræt, så vil jeg nærmest være den eneste, der gør det, og det har jeg ikke lyst til at være. Jeg har ikke lyst til, at de andre tænker, at jeg er unormal og kikset. Jeg gjorde det i mandags, og det fik mig til at føle mig super akavet. Det var som om alt krympede på mig, og jeg havde det, som om mit hjerte var lige ved at hoppe ud af halsen på mig. Overfor læreren lader de andre bare, som om de bader. Det laver de faktisk meget sjov med – at lege, at de bader. Jeg synes ikke det er sjovt, men jeg ved ikke, hvad jeg skal gøre? Jeg har lyst til at bade, men hvis jeg siger det højt, så er der sikkert nogle, der også vil lægge noget seksuelt i det og sige, at det er fordi jeg gerne vil kigge på kroppe. `
			},
			
		],
		// minigames: [
		// 	require('data/minigames/m7-thought-training').minigameData,
		// ]
	},
	{
		id: 'm7-active-break',
		isFacilitatorOnly: true,
		title: 'Aktiv pause',
		menuTitle: 'Aktiv pause',
		facilitatorInstructions: `Her kan du lægge en aktiv pause ind. Vælg én fra appendiks bagerst i Guide til facilitering eller find din egen.`,
		facilitatorText: null,
		groupText: null,
		icons: [],
		slides: [
			{
				id: 'the-bad-circle',
				type: 'image', 
				text: 'Den svære cirkel'
			},
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskassen'
			}
		],
		minigames: []
	},
	{
		id: 'm7-basal-plan',
		isFacilitatorOnly: true,
		title: 'Basalplan',
		menuTitle: 'Basalplan',
		facilitatorInstructions: `Følg guiden trin for trin. Eleverne udarbejder hver en basalplan. Husk, at du selv er rollemodel og også selv skal lave en basalplan.`,
		facilitatorText: `<strong>Opgave:</strong> <br/>
		Gå sammen i grupper og hjælp hinanden med at lave en basalplan. Fortæl jeres basalplaner til hinanden`,
		groupText: null,
		icons: ['plan-of-action'],
		slides: [
			{
				id: 'plan-of-action',
				type: 'image',
				text: 'Basalplansskema'
			},
			{
				id: 'problem-solving',
				type: 'image',
				text: 'Problemløsningsskema'
			},
			{
				id: 'the-bad-circle',
				type: 'image', 
				text: 'Den svære cirkel'
			},
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskassen'
			}
		],
		minigames: []
	},
	{
		id: 'm7-finish',
		isFacilitatorOnly: true,
		title: 'Afrunding',
		menuTitle: 'Afrunding',
		facilitatorInstructions: `Følg guiden.`,
		facilitatorText: `<strong>Hvornår gør du noget aktivt? Fx går med hunden, laver mad eller læser en bog?</strong> <br/><br/>
		<strong>Hvornår gør du noget sammen med andre?</strong><br/><br/>
		<strong>Hvordan gør du dine klassekammerater glade?</strong>`,
		groupText: null,
		icons: ['toolbox'],
		slides: [
			{
				id: 'the-bad-circle',
				type: 'image', 
				text: 'Den svære cirkel'
			},
			{
				id: 'toolbox',
				type: 'image',
				text: 'Værktøjskasse'
			}
		],
	},
];


export {
	modulePages
};