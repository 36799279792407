const cookieUiTexts = {
	popupText: 'Vi bruger cookies til at forbedre din spiloplevelse. Ved at klikke på \'OK\' eller logge ind accepterer du vores brug af cookies.',
	popupBtn: 'Ok',
};


const errorUiTexts = {
	unknownError: 'Fejl: Ukendt fejl.',
	/* Create user (facilitator) */
	emptyFields: 'Du skal udfylde alle felter.',
	emailExists: 'Der findes allerede en bruger med denne email.',
	invalidPassword: 'Dit password skal være på mindst 8 tegn.',
	noPasswordMatch: 'Password skal være ens i begge felter.',
	userNotAllowed: 'Denne e-mail er ikke godkendt.',
	/* Login / reset password (facilitator) */
	emailNotFound: 'Der findes ingen bruger med denne email.',
	invalidEmail: 'Din email er ikke gyldig.',
	invalidEmailPasswordFormat: 'Fejl. Check om din email og password er korrekte.',
	/* Login (group) */
	noGameCodeMatch: 'Ugyldig kode.',
	/* Create group */
	invalidCharacters: 'Navnet indeholder et eller flere ugyldige tegn.',
	groupNameTooShort: 'Gruppenavn skal være på mindst 3 tegn.',
	groupNameTooLong: 'Gruppenavn må ikke være længere end 12 tegn.',
	noGroupName: 'Husk at udfylde et gruppenavn.',
	groupNameAlreadyExists: 'Der eksisterer allerede en gruppe med det navn.',

};

const loginUiTexts = {
	facilitatorLogin: 'Facilitator // login',
	facilitatorResetPw: 'Facilitator // nulstil password',
	facilitatorCreateUser: 'Facilitator // opret bruger',
	groupLogin: 'Elev // login',
	gameCode: 'kode',
	login: 'Log ind',
	newGroup: 'Ny gruppe',
	groupName: 'Gruppenavn',
	create: 'Opret',
	cancel: 'Afbryd',
	email: 'E-mail',
	password: 'Password',
	repeatPassword: 'Gentag password',
	logout: 'Log ud',
	createNewUser: 'Opret ny bruger',
	create: 'Opret',
	newUserCreated: 'Din bruger er blevet oprettet, du kan nu logge ind.',
	backToLogin: 'Tilbage til login',
	resetPassword: 'Nulstil password',
	reset: 'Nulstil',
	passwordIsReset: 'Du har fået tilsendt en e-mail med et link til at nulstille dit password.',
	loggedInAs: 'Logget ind som'
};

const generalUiTexts = {
	gameTitle: 'Mentalisering på Spil',
	start: 'Start',
	
};

const gameUiTexts = {
	lookAtTeachersScreen: 'Følg med på lærerens skærm',
	gameCode: 'kode',
	start: 'Start',
	ok: 'Ok',
	continue: 'Videre',
	finish: 'Afslut',
	tryAgain: 'Prøv igen',
	playAgain: 'Spil igen',
	close: 'Luk',
	next: 'Næste',
	playAudio: 'Afspil lyd',
	openMinigame: 'Luk op for digital øvelse',
	minigameNotOpenYet: 'Jeres lærer har ikke åbnet for spillet endnu.',
	minigameClosed: 'Spillet er afsluttet.',
	prevSlideBtn: 'Tilbage',
	nextSlideBtn: 'Næste',
	instructions: 'Vejledning',
	openMinigame: 'Åbn digital øvelse',
	closeMinigame: 'Luk digital øvelse',
	nextStep: 'Næste skridt',
	finishModule: 'Afslut modul',
	dragAnswerHere: 'Træk svar her!',
	save: 'Gem',
	makePdf: 'Lav pdf',
	sortFeedbackPopup: {
		text: `Super! I har placeret alt rigtigt.`,
		text2: `I havde %correctPlacements%. Prøv igen for at matche resten.`,
		correctPlacement: 'rigtig placering',
		correctPlacements: 'rigtige placeringer',
	},
	organizeFeedbackPopup: {
		text: `Super! I har placeret alt rigtigt.`,
		text2: `De forskellige tankefælder kan minde lidt om hinanden. Prøv igen!
			<br /><br />De grønne er placeret rigtigt. Ryk rundt på de orange, og placér dem de rigtige steder.`,
	},
	matchFeedbackPopup: {
		text2: `De er ikke helt rigtigt placeret. Prøv igen!`
	}
};

const facilitatorUiTexts = {
	settings: 'Kontrolpanel',
	info: `Inden du laver forløbet med dine elever, så klik dig igennem de forskellige moduler.
		<br /><br />Hvis du vil se, hvad eleverne ser, så åbn en anden browser og log på som elev.
		<br /><br />Alternativt kan du åbne et incognito-vindue i Chrome (ctrl-shft-n) og bruge det.`,
	createNewGameBtn: 'Opret din klasse',
	resources: 'Resurser',
	loadingPdf: 'Henter pdf ...',
	pdfLoadError: `Pdf'en kunne ikke hentes. Prøv at genindlæse siden.`,
	noPrintInfo: 'Det er ikke tilladt at downloade eller printe denne pdf.',
	adminBtn: 'Admin',
	email: 'E-mail',
	status: 'Status',
	delete: 'Slet',
	addUser: 'Tilføj',
	start: 'Start',
	gameCode: 'kode',
	created: 'Oprettet',
	lobby: 'Lobby',
	home: 'Hjem',
	startModule: 'Start modul',
	createGamePopup: {
		title: 'Opret din klasse',
		gameTitleLabel: 'Hvad vil du kalde klassen?',
		gameTitlePlaceholder: 'klassenavn',
		createBtn: 'Opret',
		cancelBtn: 'Afbryd'
	},
	deleteGamePopup: {
		title: 'Slet spilforløb',
		text: 'Er du sikker på, du vil slette spilforløbet %gameId%?',
		deleteBtn: 'Slet',
		cancelBtn: 'Afbryd'
	},
	deleteUserPopup: {
		title: 'Slet bruger',
		text: `Er du sikker på du vil slette brugeren %email%?
			<br /><br />Dette vil også slette alle deres igangværende spil.`,
		deleteBtn: 'Slet',
		cancelBtn: 'Afbryd'
	}

};



export {
	cookieUiTexts,
	errorUiTexts,
	gameUiTexts,
	generalUiTexts,
	loginUiTexts,
	facilitatorUiTexts,
};