import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { gameUiTexts } from 'data/ui-texts';
import Button from '../button/button';
import Image from '../image/image';
import YouTube from 'react-youtube';
import Audio from '../audio/audio';
import DialogueExamples from '../dialogue-examples/dialogue-examples';
import './popup-slide.scss';

const PopupSlide = ({initialSlideIndex, pageData, togglePopup}) => {
	const [slideIndex, setSlideIndex] = useState(initialSlideIndex);
	const slideData = pageData.slides[slideIndex];
	const showPrevBtn = (slideIndex > 0 ? true : false);
	const showNextBtn = (slideIndex + 1 < pageData.slides.length ? true : false);
	return (
		<div className="PopupSlide">
			<div className="PopupSlide-content">
				{/* Image */}
				{slideData.type === 'image' && <Image type="slide" imageId={slideData.id} />}

				{/* Video */}
				{slideData.type === 'video' && 
					<YouTube 
						className="PopupSlide-video" 
						videoId={slideData.videoId} 
						opts={{height: '80%', width: '73%'}}
					/>
				}

				{/* Audio */}
				
				{slideData.type === 'audio' && 
					<div className="PopupSlide-audio">
						{(slideData.image) && <Image type="slide audio" imageId={slideData.image} />}
						<Audio audioFile={slideData.audioFile} image={slideData.image ? slideData.image : null} />
					</div>
				}

				{/* text */}
				{slideData.type === 'text' && 
					<div className={'PopupSlide-text ' + slideData.id}>
						<span>{slideData.subtext}</span>
					</div>
				}


				{/* Dialogue examples */}
				{slideData.type === 'dialogue-examples' &&
					<DialogueExamples slideData={slideData} />
				} 

				{/* Prev / next buttons */}
				<div className="PopupSlide-buttons">
					{showPrevBtn && <div className="PopupSlide-prevBtn"><Button 
						classes={['red']}
						text={gameUiTexts.prevSlideBtn} 
						onClick={() => {setSlideIndex((slideIndex - 1));}} 
					/></div>} 
					{showNextBtn && <div className="PopupSlide-nextBtn"><Button 
						classes={['red']}
						text={gameUiTexts.nextSlideBtn} 
						onClick={() => {setSlideIndex((slideIndex + 1));}} 
					/></div>} 
				</div>

				{/* Close btn */}
				<div className="PopupSlide-closeBtn">
					<Button classes={['popup-close']} onClick={togglePopup} />
				</div>
			</div>

			{/* Title */}
			<div className="PopupSlide-title">
				<span>{slideData.text}</span>
			</div>
		</div>
	);
};

PopupSlide.propTypes = {
	initialSlideIndex: PropTypes.number.isRequired,
	pageData: PropTypes.object.isRequired,
	togglePopup: PropTypes.func.isRequired
};

export default PopupSlide;
