import React from 'react';
import PropTypes from 'prop-types';
import {useDrop} from 'react-dnd';
import './organize-dnd-container.scss';

const OrganizeDndContainer = ({isEmpty, layout, containerId, handleFindItem, handleMoveItem, children}) => {
	/* Drop functionality */
	const [{isOver, canDrop}, drop] = useDrop({
		accept: 'item',
		drop(item, monitor) {
			if (handleFindItem && handleMoveItem) {
				const fromContainerId = handleFindItem(item.id);

				/* Ignore none-shallow drops */
				// if (containerId === 'all' && !monitor.isOver({shallow: true})) return;
				
				/* Ignore dropping on blank space or itself */
				if (containerId === 'all' || containerId === fromContainerId) return;

				/* Move card */
				handleMoveItem(item.id, item.fromContainerId, containerId);
			}
		},
		collect: (monitor) => {
			return {
				isOver: monitor.isOver(),
				canDrop: monitor.canDrop(),
			};
		}
	});

	return (
		<div 
			id={containerId} 
			ref={drop}
			className={'OrganizeDndContainer ' + layout + ' '  + containerId + ' '
				 + (isOver && canDrop ? ' hover' : '') + (isEmpty ? ' empty' : '')}
		>
			{children}
		</div>
	);
};

OrganizeDndContainer.propTypes = {
	isEmpty: PropTypes.bool.isRequired,
	layout: PropTypes.string.isRequired,
	containerId: PropTypes.string.isRequired,
	handleFindItem: PropTypes.func.isRequired,
	handleMoveItem: PropTypes.func.isRequired,
	children: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.object,
	]),
};

export default OrganizeDndContainer;
