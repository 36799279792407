const minigameData = {
	id: 'm3-thought-traps',
	title: 'Tankefælder',
	playBtnText: 'Start øvelse',
	viewBtnText: 'Prøv øvelse',
	parts: [
		{
			id: 1,
			type: 'organize',
			title: 'Del 1A',
			introPopupText: `Match en tanke med dens type. Klik på "Ok" når I har matchet dem alle.`,
			infoPopupText: `Match en tanke med dens type. Klik på "Ok" når I har matchet dem alle.`,
			items: [
				{id: 1, correctGroupId: 'g1', text: `Nu vil mine venner sikkert aldrig se mig igen, og jeg kommer til at være alene resten af mit liv.`},
				{id: 2, correctGroupId: 'g1', text: `Hvis jeg ikke får en god karakter, så får jeg aldrig et ordentlig liv.`},
				

				{id: 3, correctGroupId: 'g2', text: `Det var min skyld, at vi tabte fodboldkampen.`},
				{id: 4, correctGroupId: 'g2', text: `Min kammerat skrev ikke til mig i går. Det er nok, fordi han synes jeg er for meget.`},
				

				{id: 5, correctGroupId: 'g3', text: `Sådan er det bare. Jeg er alligevel én, de andre ikke gider at være sammen med.`},
				{id: 6, correctGroupId: 'g3', text: `Sidste idrætstime var nederen, så det bliver sikkert også nederen i dag.`},
				


				{id: 7, correctGroupId: 'g4', text: `Enten rykker jeg op på et andet hold i fodbold, eller også stopper jeg.`},
				{id: 8, correctGroupId: 'g4', text: `Min fysiklærer kan ikke lide mig.`},
				
			],
			groups: [
				{id: 'g1', containers: 2, title: 'Katastrofetanker', description: ''},
				{id: 'g2', containers: 2, title: 'Selvbebrejdelser', description: ''}, 
				{id: 'g3', containers: 2, title: 'Forhastede konklusioner', description: ''}, 
				{id: 'g4', containers: 2, title: 'Sort/hvid-tænkning', description: ''}
			]
		},
		{
			id: 2,
			type: 'organize',
			title: 'Del 1B',
			introPopupText: `Match en tanke med dens type. Klik på "Ok" når I har matchet dem alle.`,
			infoPopupText: `Match en tanke med dens type. Klik på "Ok" når I har matchet dem alle.`,
			items: [
				{id: 1, correctGroupId: 'g1', text: `Jeg fik 02 i terminsprøven, så nu går det helt galt til eksamen. Jeg kommer ikke til at bestå.`},
				{id: 2, correctGroupId: 'g1', text: `Når vi skal på lejrskole, kommer jeg helt sikkert til at sidde helt alene, mens de andre bare hygger sig sammen på deres værelser.`},

				{id: 3, correctGroupId: 'g2', text: `Den dårlige stemning er min skyld, det er nok, fordi jeg er kedelig.`},
				{id: 4, correctGroupId: 'g2', text: `Jeg klarede det dårligt, fordi jeg generelt bare ikke kan finde ud af noget.`},

				{id: 5, correctGroupId: 'g3', text: `Han er nok sådan én, der kun kan lide fodbold, og så vil han ikke være venner med mig.`},
				{id: 6, correctGroupId: 'g3', text: `Som feedback på min sidste aflevering skrev min lærer, at jeg skulle arbejde mere med mine kommaer. Det bliver jeg aldrig bedre til.`},

				{id: 7, correctGroupId: 'g4', text: `Min mor er altid sur, når hun kommer hjem fra arbejde.`},
				{id: 8, correctGroupId: 'g4', text: `Jeg skal have nul fejl i den her prøve, ellers kan det være lige meget.`},
			],
			groups: [
				{id: 'g1', containers: 2, title: 'Katastrofetanker', description: ''},
				{id: 'g2', containers: 2, title: 'Selvbebrejdelser', description: ''}, 
				{id: 'g3', containers: 2, title: 'Forhastede konklusioner', description: ''}, 
				{id: 'g4', containers: 2, title: 'Sort/hvid-tænkning', description: ''}
			]
		},
	],
};

export {
	minigameData
}