import React, {useState} from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {facilitatorUiTexts} from 'data/ui-texts';
import Button from 'components/ui/button/button';
import './create-game-popup.scss';

const CreateGamePopup = (props) => {
	let {
		isCreatingGame,
		errMsg,
		toggleCreateGamePopup,
		handleCreateGame,
	} = props;

	/* Title */
	let [gameTitle, setGameTitle] = useState('');
	const updateGameTitle = (event) => {
		setGameTitle(event.target.value);
	};



	/* Verify  */
	const inputIsValid = () => {
		return (
			gameTitle.length > 0 && 
			gameTitle.length <= appConfig.gameTitleMaxLength
		);
	};

	return (
		<div className="CreateGamePopup" onClick={() => {toggleCreateGamePopup();}}>
			<div className="CreateGamePopup-content" onClick={(e) => {e.stopPropagation();}}>
				<div className="CreateGamePopup-header">
					<div className="CreateGamePopup-title">
						{facilitatorUiTexts.createGamePopup.title}
					</div>
				</div>
				<div className="CreateGamePopup-body">
					<div className="CreateGamePopup-form">
						<div className="CreateGamePopup-label">
							{facilitatorUiTexts.createGamePopup.gameTitleLabel}
						</div>
						<input
							disabled={isCreatingGame}
							name="gameTitle"
							type="text"
							maxLength={appConfig.gameTitleMaxLength}
							placeholder={facilitatorUiTexts.createGamePopup.gameTitlePlaceholder}
							value={gameTitle ? gameTitle : ''}
							onChange={(event) => {updateGameTitle(event);}}
						/>
					
						<div className="CreateGamePopup-buttons">
							<Button
								isDisabled={!inputIsValid()}
								classes={['login']}
								isLoading={isCreatingGame}
								text={facilitatorUiTexts.createGamePopup.createBtn}
								onClick={() => {handleCreateGame(gameTitle);}}
							/>
							<Button
								classes={['red-frame']}
								text={facilitatorUiTexts.createGamePopup.cancelBtn}
								onClick={() => {toggleCreateGamePopup();}}
							/>
						</div>
						<div className="CreateGamePopup-errMsg">{errMsg}</div>
					</div>
				</div>
			</div>
		</div>
	);
};

CreateGamePopup.propTypes = {
	isCreatingGame: PropTypes.bool.isRequired,
	errMsg: PropTypes.string,
	toggleCreateGamePopup: PropTypes.func.isRequired,
	handleCreateGame: PropTypes.func.isRequired,
};

export default CreateGamePopup;
